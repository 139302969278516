import { useTheme } from "@mui/material/styles";
import { Box, Button, Card, CircularProgress, Modal } from "@mui/material";
import React, { useState } from "react";
import GetRequest from "../components/apiConnections/getRequest";
import { useSnackbar } from "notistack";
import Markdown from 'marked-react';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "solid #0000",
  border: "2px solid #000",
  boxShadow: 24,
  borderRadius: "15px",
  p: 4,
  height: "80vh",
  overflowY: "auto",
};

export default function DocumentAnalysis({ domain }) {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [modelOpen, setModelOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState(false);

  const getDocumentAnalysis = () => {
    setLoading(true);

    GetRequest(
      `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_DOCUMENT_RISK_ASSESSMENT}?domain=${domain}`,
      {}
    )
      .then((response) => {
        console.log("response", response);
        setModelOpen(true);
        setLoading(false);
        setData(response?.data?.data);
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: "error" });
        setLoading(false);
      });
  };

  return (
    <>
      <Button
        variant="contained"
        className="executive-report-btn"
        disabled={loading}
        onClick={() => getDocumentAnalysis()}
      >
        Genesis AI
        {loading ? (
          <CircularProgress size={12} sx={{ marginLeft: "15px" }} />
        ) : null}
      </Button>

      <div>
        <Modal
          open={modelOpen}
          onClose={() => setModelOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className={
            theme === "Dark"
              ? "dark-attack-modal modal-popup"
              : "light-attack-modal modal-popup"
          }
        >
          <Box sx={style} className="black-bg openai-response">
            {data?.overallRisk ? (
              <Card
                sx={{ p: 2, m: 0, width: "100%" }}
                className="attackpart"
              >
                <Markdown value={data.overallRisk} />
              </Card>
            ) : null}

            {data?.soc2Risk ? (
              <Card
              sx={{ p: 2, m: 0, marginTop: "2rem", width: "100%" }}
              className="attackpart"
            >
              <Markdown value={data.soc2Risk} />
            </Card>
            ) : null}
          </Box>
        </Modal>
      </div>
    </>
  );
}
