import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation, useParams } from "react-router-dom";
import { faker } from "@faker-js/faker";
// @mui
import { useTheme } from "@mui/material/styles";
import {
  Grid,
  Container,
  Typography,
  Card,
  CardHeader,
  CardContent,
  Stack,
  Box,
  TextField,
  Alert,
  Button,
  IconButton,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Label from "../components/label";
import { sentenceCase } from "change-case";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PostRequest from "../components/apiConnections/postRequest";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Collapse from "@mui/material/Collapse";
import { SnackbarProvider, useSnackbar } from "notistack";
import CircularProgress from "@mui/material/CircularProgress";
// components
import Iconify from "../components/iconify";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import DoneIcon from '@mui/icons-material/Done';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';

// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
  AppOverallScore,
  AppRiskScore,
} from "../sections/@dashboard/app";
import Loader from "../Loader";
import "./css/Vendors.css";
import { Route, Link, BrowserRouter } from "react-router-dom";
// ----------------------------------------------------------------------
export default function VendorDetailsPage() {
  const theme = useTheme();
  const data = useParams();
  const [open, setOpen] = React.useState(false);
  const [low, setLow] = useState(0);
  const [domainName, setDomainName] = useState("");
  const [hosting, setHosting] = useState("");
  const [address, setAddress] = useState("");
  const [companyImage, setCompanyImage] = useState("dummy.png");
  const [dns, setDns] = useState("");
  const [breaches, setBreaches] = useState("");
  const [encryption, setEncryption] = useState("");
  const [misconfiguration, setMisconfigration] = useState("");
  const [patching, setPatching] = useState("");
  const [totalScore, setTotalScore] = useState("");
  const [dnsScore, setDnsScore] = useState("");
  const [breachesScore, setBreachesScore] = useState("");
  const [encryptionScore, setEncryptionScore] = useState("");
  const [misconfigrationScore, setMisconfigrationScore] = useState("");
  const [patchingScore, setPatchingScore] = useState("");
  const [riskScore, setRiskScore] = useState("");
  const [alertData, setAlertData] = useState({});
  const [issuesListData, setIssuesListData] = useState({});
  const [patchingRowsData, setPatchingRowsData] = useState({});
  const [roleId, setroleId] = useState(
    parseInt(window.localStorage.getItem("roleId"))
  );
  const [totalLow, setTotalLow] = useState(0);
  const [totalMedium, setTotalMedium] = useState(0);
  const [totalHigh, setTotalHigh] = useState(0);
  const [totalCritical, setTotalCritical] = useState(0);
  const [logo, setLogo] = useState("/assets/images/avatars/user.png");
  const { enqueueSnackbar } = useSnackbar();
  const [userRoleId, setUserRoleId] = useState(localStorage.getItem("roleId"));
  const [showLoader, setshowLoader] = useState(false);

  const [domainIssues, setDomainIssues] = useState("");
  const [attackInfoData, setattackInfoData] = useState("");

  const [currentIssuesName, setCurrentIssuesName] = useState("");
  const [currentIssuesDescription, setCurrentIssuesDescription] = useState("");
  const [currentIssuesEvidence, setCurrentIssuesEvidence] = useState("");
  const [currentIssuesRemediation, setCurrentIssuesRemediation] = useState("");
  const [currentIssuesScore, setCurrentIssuesScore] = useState("");
  const [currentIssuesSeverity, setCurrentIssuesSeverity] = useState("");
  const [currentIssuesReference, setCurrentIssuesReference] = useState("");

  useEffect(() => {
    getVendorDetails();
  }, []);

  const getVendorDetails = async () => {
    setshowLoader(true);
    try {
      var rgx = /^[a-z|0-9]*\.?[a-z|0-9]*$/;
      const vendorPayload = {
        vendor: data.url,
      };
      let issuesRows = [];
      let patchingRows = [];
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_SINGLE_ATTACK_DETAIL}`,
          vendorPayload
        )
          .then((res) => {
            setattackInfoData(res.data.data.attackInfo);
            setDomainName(res.data.data?.attackInfo.domain || "");
            // setDomainIssues(res.data.data?.attackInfo.Issues);
            setshowLoader(false);

            let tempIssues = res.data.data?.attackInfo.Issues;
            let currentIssuesData = [];
            for (let index = 0; index < tempIssues.length; index++) {
              const element = tempIssues[index];
              if (element.Target === data.subdomain) {
                setDomainIssues(element.Issue);
                currentIssuesData = element.Issue;
              }
            }

            for (let index = 0; index < currentIssuesData.length; index++) {
              const element = currentIssuesData[index];
              if (element.Name == data.issueid) {
                setCurrentIssuesName(element.Name);
                setCurrentIssuesDescription(element.Info);
                setCurrentIssuesEvidence(element.Evidence);
                setCurrentIssuesRemediation(element.Remediation);
                setCurrentIssuesScore(element.Score);
                setCurrentIssuesSeverity(element.Severity);
                setCurrentIssuesReference(element.Reference);
              }
            }
          })
          .catch((err) => {
            // no data
            enqueueSnackbar("Requested Url not find", { variant: "error" });
            setshowLoader(false);
            return false;
          });
      }
    } catch (error) {
      // no data
      enqueueSnackbar("Requested Url not find", { variant: "error" });
      setshowLoader(false);
      return false;
    }
  };

  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Helmet>
        <title> Dashboard | Genesis Platform</title>
      </Helmet>
      {/* {alertData.showalert ? (
        <Alert sx={{ mb: 2 }} severity={alertData.alerttype}>
          {alertData.message}
        </Alert>
      ) : null} */}
      <Container maxWidth={false}>
        <Typography variant="h4" sx={{ mb: 5 }}>
          <span className="heading-main flex">
            {domainName} <KeyboardArrowRightIcon fontSize="large"/> {data.subdomain}{" "}
          </span>
        </Typography>
        {showLoader === true ? (
          <Loader show={showLoader} />
        ) : domainName !== "" && domainName ? (
          <Box
            sx={{ width: "100%", typography: "body1" }}
            className="ipad-space"
          >
            <h2 className="heading-main text-lefts">Issues</h2>
            <Grid container spacing={2}>
              <Grid item xs={3} md={3} lg={3} className="affecteds-part">
                <h3>Affected Assets</h3>
                <span>{data.subdomain}</span>
              </Grid>
              <Grid item xs={3} md={3} lg={3} className="affecteds-part">
                <h3>Company</h3>
                <span>{data.url}</span>
                </Grid>
                <Grid item xs={3} md={3} lg={3} className="affecteds-part">
                  <h3></h3>

                  {
                currentIssuesSeverity ==='CRITICAL'
                ? <span className="alert-Critical"> <WarningAmberIcon fontSize="small" />{currentIssuesSeverity}</span>
                :  currentIssuesSeverity ==='HIGH' ?
                <span className="alert-Critical high-color"> <ShowChartIcon fontSize="small" />{currentIssuesSeverity}</span>
                :  currentIssuesSeverity ==='MEDIUM' ?
                <span className="alert-Critical medium-color"> <TrackChangesIcon fontSize="small" />{currentIssuesSeverity}</span>
                : 
                <span className="alert-Critical success-critical"> <ThumbDownOffAltIcon fontSize="small" />{currentIssuesSeverity}</span>

              }
              
                </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ pt: 4 }}>
              <Grid item xs={12} md={12} lg={12}>
                <TabContext value={value}>
                  <TabList
                    onChange={handleChange}
                      aria-label="lab API tabs example"
                      className="tabspart"
                      sx={{ p: 0 }}
                  >
                    <Tab label="Info" value="1" />
                    <Tab label="Evidence" value="2" />
                  </TabList>
                  <TabPanel value="1"  sx={{ p: 0 }}>
                   <div className="Assessment-box-attack subdomain">
                     <div className="box-Assessment-attack">
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Grid item xs={12}>
                              <h4>{currentIssuesName}</h4>
                            </Grid>
                            <Grid item xs={12}>
                              <h4>Description</h4>
                              <p>{currentIssuesDescription}</p>
                            </Grid>
                            <Grid item xs={12}>
                              <h4>Remediation</h4>
                              <p>{currentIssuesRemediation}</p>
                            </Grid>
                            <Grid item xs={12}>
                              <h4>Reference</h4>
                              {currentIssuesReference.map((item, index) => (
                                <p className="dark-change-bg">
                                  <a
                                    href={`${currentIssuesReference[index]}`}
                                    style={{
                                      textDecoration: "none",
                                      color: "#242526",
                                      backgroundColor: "#fff",
                                      padding: "5px 10px",
                                      borderRadius: "5px",
                                      border: "1px solid",
                                      borderColor: " rgb(225 224 224)",
                                      display: "block",
                                      marginBottom: "3px",
                                      width:"fit-content",
                                      wordBreak:"break-all"
                                    }}
                                    target="_blank"
                                  >
                                    {currentIssuesReference[index]}
                                  </a>
                                </p>
                              ))}
                            </Grid>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel value="2"  sx={{ p: 0 }}>
                  <div className="Assessment-box-attack subdomain">
                     <div className="box-Assessment-attack">
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <h4>Evidence</h4>
                                {currentIssuesEvidence.map((key, index)=>(
                               <pre data-lang='scss' className='prettyprint'>
                                <code>
                                  {currentIssuesEvidence[index]}
                                </code>
                              </pre>
                                )
                                )}
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </TabPanel>
                </TabContext>
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Box sx={{ display: "flex" }}>
            <Grid>
              <Card item xs={12} md={6} lg={6} sx={{ p: 5 }}>
                <h4>No Record for this url</h4>
              </Card>
            </Grid>
          </Box>
        )}
      </Container>
    </>
  );
}
