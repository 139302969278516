import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { styled, alpha } from '@mui/material/styles';
import { Box, List, Link, Button, Drawer, Typography, Avatar, Stack } from '@mui/material';
// mock
import account from '../../../_mock/account';
// hooks
import useResponsive from '../../../hooks/useResponsive';
// components
import Logo from '../../../components/logo';
import Scrollbar from '../../../components/scrollbar';
import NavSection from '../../../components/nav-section';
//
import Navigation from './config';
import './sidebar.css';
// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const StyledAccount = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav, toggleTheme }) {
  const { pathname } = useLocation();
  const [theme, setTheme] = useState(localStorage.getItem('theme'));
  const [roleId, setroleId] = useState(parseInt(window.localStorage.getItem('roleId')));
  const { navConfig, navConfigAdmin, navConfigVendor } = Navigation();
  let activeSideNav = navConfig;
  if (parseInt(window.localStorage.getItem('roleId')) == 1) {
    activeSideNav = navConfig;
  } else if (parseInt(window.localStorage.getItem('roleId')) == 2) {
    activeSideNav = navConfigAdmin
  } else if (parseInt(window.localStorage.getItem('roleId')) == 3) {
    activeSideNav = navConfigVendor;
  }
  const [navData, setNavData] = useState(activeSideNav)
  const isDesktop = useResponsive('up', 'lg');
  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    const themeName = localStorage.getItem('theme');
    setTheme(themeName);
  }, [])

  const toggleModeButtonClick = () => {
    setTheme((theme === 'Dark') ? `Light` : `Dark`)
    toggleTheme();
  }
  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Box className="logo-sidebar">
        <Logo />
      </Box>

      <div className={(theme == 'Dark') ? 'dark-mode-sidebar' : 'light-mode-class'}>
        <div className='navBar'>
          <NavSection data={navData} />
          <div className='section-bottom'>
            <Box style={{ width: '100%' }} >
              <Link underline="none" className='username-profile'>
                <StyledAccount>
                  <Avatar src={account.photoURL} alt="photoURL" />
                  <Box sx={{ ml: 2 }}>
                    <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                      {account.displayName}
                    </Typography>
                  </Box>
                </StyledAccount>
              </Link>
              <div className='toggleMode' style={{ marginTop: '5px' }}>
                <FormControlLabel control={<Switch defaultChecked={theme === "Dark"} onChange={() => toggleModeButtonClick()} />} label={`${theme} Mode `} />
              </div>
            </Box>
          </div>
        </div>
      </div>
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
