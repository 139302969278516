import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { filter } from "lodash";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import "./css/Attack.css";
import "./css/Vendors.css";
// @mui
import {
  Box,
  Button,
  Card,
  Container,
  FormControl,
  Grid,
  Modal,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  styled,
  CircularProgress
} from "@mui/material";
// import Box from '@mui/material/Box';
// import Modal from '@mui/material/Modal';

// components
import Iconify from "../components/iconify";
import Label from "../components/label";
import Scrollbar from "../components/scrollbar";
// sections
import { UserListHead } from "../sections/@dashboard/user";
// mock
import { useSnackbar } from "notistack";
import Delete from "../assets/delete.svg";
import PostRequest from "../components/apiConnections/postRequest";
import Loader from "../Loader";
import USERLIST from "../_mock/user";
import ModalDialog from "./ModalDialog.js";
import { Link } from "react-router-dom";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "id", label: "No.", alignRight: true, sx: { width: "5%" } },
  {
    id: "requestAttackUrl",
    label: "Name",
    alignRight: true,
    filter: true,
    sort: true,
    sx: { width: "60%" }
  },
  { id: "fulfilled", label: "Fulfilled", alignRight: false, sx: { width: "15%" } },
  { id: "view", label: "Action", alignRight: false, sx: { width: "15%" } },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        _user.requestAttackUrl.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "#fff",
  // border: '2px solid #000',
  boxShadow: 24,
  textAlign: "center",
  borderRadius: "15px",
  p: 4,
};

export default function UserPage() {
  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));
  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <svg
          width="120"
          height="100"
          viewBox="0 0 184 152"
          aria-hidden
          focusable="false"
        >
          <g fill="none" fillRule="evenodd">
            <g transform="translate(24 31.67)">
              <ellipse
                className="ant-empty-img-5"
                cx="67.797"
                cy="106.89"
                rx="67.797"
                ry="12.668"
              />
              <path
                className="ant-empty-img-1"
                d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
              />
              <path
                className="ant-empty-img-2"
                d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
              />
              <path
                className="ant-empty-img-3"
                d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
              />
            </g>
            <path
              className="ant-empty-img-3"
              d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
            />
            <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
              <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
              <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
            </g>
          </g>
        </svg>
        <Box sx={{ mt: 1 }}>No Attack Details Found</Box>
      </StyledGridOverlay>
    );
  }





  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("requestAttackUrl");

  const [filterName, setFilterName] = useState();

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [vendorsData, setVendorsData] = useState([]);
  const [isNotFound, setisNotFound] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [showLoader, setshowLoader] = useState(false);
  const [theme, setTheme] = useState(localStorage.getItem("theme"));
  const [username, setUserName] = useState(
    window.localStorage.getItem("userEmail")
  );
  const [userRemoveId, setUserRemoveId] = useState(0);
  const [openUser, setOpenUser] = useState(false);

  useEffect(() => {
    setOpenUser(userRemoveId !== 0 ? true : false);
  }, [userRemoveId]);

  useEffect(() => {
    setInterval(() => {
      const themeName =
        localStorage.getItem("theme") !== null
          ? localStorage.getItem("theme")
          : "Dark";
      setTheme(themeName);
    }, 2000);
  }, []);

  const handleOpen = (url) => {
    setUserRemoveId(url);
  };
  const handleClose = () => {
    setOpenUser(false);
    setUserRemoveId(0);
  };

  useEffect(() => {
    getAttackDetails();
  }, []);

  useEffect(() => { }, [vendorsData]);

  const getAttackDetails = async () => {
    // REACT_APP_VENDORS_LIST
    setshowLoader(true);
    const role = parseInt(window.localStorage.getItem("roleId"));
    const endpoint = "get-all-attack-request-by-id";

    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}`
        )
          .then((resVendors) => {
            let arrResponse = resVendors.data.data;
            setisNotFound(!(resVendors.data.data.length > 0));
            setVendorsData(arrResponse);
            setshowLoader(false);
          })
          .catch((errVendor) => {
            enqueueSnackbar(errVendor.message, { variant: "error" });
            setshowLoader(false);
          });
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setshowLoader(false);
    }
  };

  const removeLink = async () => {
    const deleteId = userRemoveId;
    setshowLoader(true);
    const refreshToken = await PostRequest(
      `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
      {},
      {},
      "refreshToken"
    );
    if (refreshToken) {
      const endpoint = "delete-attack-by-user";
      const payload = {
        username,
        requestAttackUrl: deleteId,
      };
      await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}`,
        payload
      )
        .then((resVendors) => {
          enqueueSnackbar(resVendors.data.message, { variant: "success" });
          setshowLoader(false);
          handleClose();
          setTimeout(() => {
            getAttackDetails();
          }, 1000);
        })
        .catch((errVendor) => {
          enqueueSnackbar(errVendor.message, { variant: "error" });
          setshowLoader(false);
        });
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = vendorsData.map((n) => n.requestAttackUrl);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const filteredUsers = applySortFilter(
    vendorsData,
    getComparator(order, orderBy),
    filterName
  );

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const [modelOpen, setModelOpen] = useState(false);
  const handleModelOpen = () => setModelOpen(true);
  const handleModelClose = () => setModelOpen(false);
  const [newDomain, setNewDomain] = useState("");
  const inputChange = (e) => {
    const { name, value } = e.target;
    if (name === "newDomain") {
      setNewDomain(value);
    }
  };

  const formSubmit = async (e) => {
    e.preventDefault();
    setshowLoader(true);
    let regex = new RegExp(
      /^(?!-)[A-Za-z0-9-]+([\-\.]{1}[a-z0-9]+)*\.[A-Za-z]{2,6}$/
    );
    if (!regex.test(newDomain)) {
      enqueueSnackbar("Error in domain name.", { variant: "error" });
      setshowLoader(false);
      return false;
    }
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );

      if (refreshToken) {
        let endPoint = process.env.REACT_APP_REQUEST_ATTACK;
        const payload = {
          requestUrl: newDomain,
        };

        const uploadJson = await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endPoint}`,
          payload
        );
        if (uploadJson && uploadJson.status === 200) {
          if (uploadJson.data.code === 200) {
            enqueueSnackbar("Successfully Requested.", {
              variant: "success",
            });
            setshowLoader(false);
          } else {
            enqueueSnackbar("Error in request.", { variant: "error" });
            setshowLoader(false);
          }
        } else {
          enqueueSnackbar("Error in request.", { variant: "error" });
          setshowLoader(false);
        }
        getAttackDetails();
        setModelOpen(false);
      }
    } catch (e) {
      enqueueSnackbar("Error in request.", { variant: "error" });
      setshowLoader(false);
      setModelOpen(false);
    }
  };

  const ModalContent = () => {
    return (
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        <img src={Delete} alt="icon" />
        <h3 className="send-assessments">
          Are you sure you want to remove assets ?
        </h3>
      </Typography>
    );
  };

  return (
    <>
      {/* <Loader show={showLoader} /> */}

      <Helmet>
        <title> Attacks | Genesis Platform</title>
      </Helmet>

      <Container maxWidth={false} className="top-assets">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
          className="mbtop-manage"
        >
          <Typography variant="h4" gutterBottom>
            <span className="heading-main">Assets</span>
          </Typography>
          {/* <button>Add New</button> */}
          <Button
            variant="contained"
            className="addnew"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={handleModelOpen}
          >
            Add New
          </Button>
        </Stack>

        <div>
          <Modal
            open={modelOpen}
            onClose={handleModelClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className={
              theme == "Dark"
                ? "dark-attack-modal modal-popup"
                : "light-attack-modal modal-popup"
            }
          >
            <Box sx={style} className="black-bg">
              <Card sx={{ p: 2, m: 0, width: "100%" }} className="attackpart">
                <Box>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12} lg={12}>
                      <ClearIcon
                        className="cross-icon"
                        onClick={handleModelClose}
                      ></ClearIcon>

                      <FormControl sx={{ width: "100%" }}>
                        {/* <InputLabel htmlFor="my-input">Import File</InputLabel> */}
                        <form onSubmit={(e) => formSubmit(e)}>
                          <Box>
                            <Grid xs={12} md={12} lg={12} container>
                              <TextField
                                name="newDomain"
                                value={newDomain || ""}
                                placeholder="Enter domain name"
                                onChange={(e) => inputChange(e)}
                                required={true}
                                margin="normal"
                                helperText="Enter domain name!"
                                id="full-width-text-field"
                                // label="Domain"
                                style={{ width: "100%" }}
                              />
                            </Grid>
                          </Box>
                          <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            component="button"
                            type="submit"
                            className="save-file-btn"
                          >
                            Submit
                          </Button>
                        </form>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>
              </Card>
            </Box>
          </Modal>
        </div>

        <Card className="full-coverdesign">
          {/* <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} /> */}

          <div className="cover-table">
            <h3 className="vendor-head">Assets</h3>

            <>
              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table checkboxSelection={false} className="table-attacks">
                    <UserListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={vendorsData.length}
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                    />
                    {showLoader ?
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "200px" }}>
                              <CircularProgress />
                            </Box>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                      :
                      <>
                        <TableBody>
                          {filteredUsers
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((row, i) => {
                              const { _id, requestAttackUrl, userFileStatus } = row;
                              const selectedUser =
                                selected.indexOf(requestAttackUrl) !== -1;

                              return (
                                <TableRow
                                  hover
                                  key={_id}
                                  tabIndex={-1}
                                  selected={selectedUser}
                                >
                                  <TableCell align="left" maxWidth="50px">{i + 1}</TableCell>
                                  <TableCell align="left" >
                                    {requestAttackUrl}
                                  </TableCell>
                                  <TableCell align="center">
                                    <Label
                                      color={
                                        userFileStatus === 0 ? "error" : "success"
                                      }
                                    >
                                      {userFileStatus === 0
                                        ? "Awaiting"
                                        : "Fulfilled"}
                                    </Label>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Box className="add-questionnaire-action mui-icons-questionnaire">
                                      {userFileStatus === 1 ? (
                                        <Link to={`/dashboard/attack-detail/${requestAttackUrl}`}>
                                          <Button>
                                            <VisibilityIcon />
                                          </Button>
                                        </Link>
                                      ) : null}
                                      <Button
                                        onClick={() => handleOpen(requestAttackUrl)}
                                      >
                                        <DeleteIcon />
                                      </Button>
                                    </Box>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                              <TableCell colSpan={6} />
                            </TableRow>
                          )}
                        </TableBody>
                        {isNotFound && (
                          <TableBody>
                            <TableRow>
                              <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                {CustomNoRowsOverlay()}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        )}
                      </>
                    }
                  </Table>
                </TableContainer>
              </Scrollbar>

              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={vendorsData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          </div>
        </Card>
        <ModalDialog
          handleClose={handleClose}
          open={openUser}
          onSubmit={removeLink}
          contentData={ModalContent()}
        />
      </Container>

      {/* <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            "& .MuiMenuItem-root": {
              px: 1,
              typography: "body2",
              borderRadius: 0.75,
            },
          },
        }}
      >
       </Popover> */}
      {/* <ModalDialog
          handleClose={handleClose}
          open={open}
          contentData={ModalContent}
        />  */}
    </>
  );
}
