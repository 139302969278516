import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, Divider, Stack, Button,Box,Alert,Card,Grid } from '@mui/material';
import { useNavigate, useParams } from "react-router-dom";

import { useState, useEffect } from 'react';
import PostRequest from "../components/apiConnections/postRequest";


// hooks
// import useResponsive from '../hooks/useResponsive';
// // components
// import Logo from '../components/logo';
// import Iconify from '../components/iconify';
// sections

export default function ConfirmAccountPage() {
 const params = useParams();  
 const navigate = useNavigate();
 const [token, setToken] = useState(params.token);
 const [alertData, setAlertData] = useState({});

 useEffect(() => {
    if (alertData.showalert) {
      setTimeout(() => {
        setAlertData({});
      }, 3000);
    }
  }, [alertData]);

  useEffect(() => {

    const payload = {
      token       
      };
      const userResult = PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_CONFIRM_ACCOUNT_ENDPOINT}`,
        payload
      );
      userResult.then((res)=>{
        if(res.data.code === 200)
        {
            setAlertData({
                alerttype: "success",
                showalert: true,
                message: "Account activate successfully.",
              });
              setTimeout(() => {
                navigate("/", { replace: true });
              }, 2000);
        }
        else
        {        
            setAlertData({
                alerttype: "error",
                showalert: true,
                message: res.data.message,
                });
                setTimeout(() => {
                navigate("/", { replace: true });
                }, 2000);            
        }       

      })
      .catch((err)=>{
        setAlertData({
            alerttype: "error",
            showalert: true,
            message: "Account not activated.",
          });
          setTimeout(() => {
            navigate("/", { replace: true });
          }, 2000);

      })
   
  }, []);


  return (
    <>
      <Helmet>
        <title> Account Confirm | Genesis Platform  </title>
      </Helmet>
      <Box sx={{mt:40, ml:50, width:"500px"}}>  
      {alertData.showalert ? (
        <Alert sx={{ mb: 2 }} severity={alertData.alerttype}>
          {alertData.message}
        </Alert>
      ) : null}   
      </Box>
      
    </>
  );
}
