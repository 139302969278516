import { Card, CardHeader,Box,CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";
const ReportChart = ({ title, chartData, loading }) => {
  const theme = useSelector((state) => state.theme.theme);
  const [themeColor, setThemeColor] = useState(theme); // State to store the theme color

  useEffect(() => {
    // Retrieve theme properties from local storage
    const savedTheme = localStorage.getItem("theme");
    // Use the saved theme color or set a default color
    setThemeColor(savedTheme ? savedTheme.toLowerCase() : "light");
  }, []);

  // Extract vendor names and scores from chartData
  const categories = chartData?.map((item) => item.vendor);
  const scores = chartData?.map((item) => item.score);

  const colors = [
    "#d32f2f",
    "#ff7300",
    "#FEB019",
    "#6AA84F",
  ];

  const options = {
    series: [
      {
        name: "Score",
        data: scores,
      },
    ],
    chart: {
      type: "bar",
      events: {
        click: function (chart, w, e) {
          // console.log(chart, w, e)
        },
      },
      toolbar: {
        show: false,
      },
    },
    colors: colors,
    plotOptions: {
      bar: {
        columnWidth: "45%", // Adjust the columnWidth as needed
        distributed: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    tooltip: {
      enabled: true,
      theme: theme,
    },
    xaxis: {
      categories: categories,
      labels: {
        style: {
          colors: colors,
          fontSize: "12px",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: theme === "dark" ? "#fff" : "#000",
          fontSize: "12px",
        },
      },
    },
  };

  return (
    <>
      <Card>
        <div className="card-headerpart">
          <CardHeader
            title={title}
            sx={{ marginLeft: "0px", fontSize: "1.0rem" }}
          />
        </div>
        {loading ? <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "200px" }}>
          <CircularProgress />
        </Box> :
          <ReactApexChart
            options={options}
            series={options.series}
            type="bar"
            width="100%"
          />
        }
      </Card>
    </>
  );
};

export default ReportChart;
