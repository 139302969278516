import PropTypes from "prop-types";
import ReactApexChart from "react-apexcharts";
// @mui
import { Card, CardHeader,Box,CircularProgress } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
// utils
// components

// ----------------------------------------------------------------------

const CHART_HEIGHT = 372;
const LEGEND_HEIGHT = 100;

const StyledChartWrapper = styled("div")(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(3),
  "& .apexcharts-canvas svg": { height: CHART_HEIGHT },
  "& .apexcharts-canvas svg,.apexcharts-canvas foreignObject": {
    overflow: "visible",
  },
  "& .apexcharts-legend": {
    // height: LEGEND_HEIGHT,
    alignContent: "center",
    justifyContent: "center",
    position: "relative !important",
    // borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
  },
}));

// ----------------------------------------------------------------------

AppCurrentVisits.propTypes = {
  loading: PropTypes.bool,
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartColors: PropTypes.arrayOf(PropTypes.string),
  chartData: PropTypes.array,
};

export default function AppCurrentVisits({
  loading,
  title,
  subheader,
  chartColors,
  chartData,
  ...other
}) {

  const chartLabels = chartData.map((i) => i.label);

  const chartSeries = chartData.map((i) => i.value);

  const pieoptions = {
    chart: {
      width: "80%",
      type: "pie",
    },
    labels: chartLabels,
    colors: chartColors,
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val.toFixed(2) + "%";
      },
    },
    tooltip: { enabled: true }
  };

  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />
      <div id="chart">
        {loading ? <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "200px" }}>
          <CircularProgress />
        </Box> :
          <ReactApexChart options={pieoptions} series={chartSeries} type="pie" />
        }
      </div>
    </Card>
  );
}
