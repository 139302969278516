import { Key } from "@mui/icons-material";
import jwt_decode from "jwt-decode";
import { getLocalStoarge } from "../components/common/allFunctions";
let userDetails = window.localStorage.getItem('userDetails');
let updateValue = JSON.parse(userDetails);
let userEmail = window.localStorage.getItem('userEmail');
let userData = '';
let name = '';
let email = '';
let profileImg = '';
let roleId = parseInt(window.localStorage.getItem('roleId'));
let token;
let tokenData;
if (userDetails && updateValue) {
  name = updateValue.firstName + " " + updateValue.lastName;
  profileImg = updateValue.profilePic;
  email = userEmail;
}
else {
  if (roleId === 1) {
    token = localStorage.getItem("R1");
    tokenData = JSON.parse(token)?.accessToken;
    userData = jwt_decode(tokenData);
    name = userData.firstName + " " + userData.lastName;
    email = userData.username;
    profileImg = userData.profilePic;

  } else if (roleId === 2) {
    token = localStorage.getItem("R2");
    tokenData = JSON.parse(token)?.accessToken;
    userData = jwt_decode(tokenData);
    name = userData.firstName + " " + userData.lastName;
    email = userData.username;
    profileImg = userData.profilePic;

  }
  else if (roleId === 3) {
    token = localStorage.getItem("R3");
    tokenData = JSON.parse(token)?.accessToken;
    userData = jwt_decode(tokenData);
    name = userData.firstName + " " + userData.lastName;
    email = userData.username;
    profileImg = userData.profilePic;

  }
}

const account = {
  displayName: userData !== null ? name : '',
  email: userData !== null ? email : '',
  photoURL: (!profileImg) ? '/assets/images/avatars/user.png' : process.env.REACT_APP_BACKEND_BASE_URL +
    process.env.REACT_APP_STATIC_PATH +
    profileImg,
};


export default account;
