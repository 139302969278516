import React from 'react'
import {
  Button, Typography,
  Box,
  Modal,
  Grid,
  TextField
} from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import './css/questionnaire.css';
import CloseIcon from '@mui/icons-material/Close';
import { Checkbox, Radio } from '@mui/material';
import QuestionCategoryInfo from './QuestionCategoryInfo';


// interface TabPanelProps {
//   children?: React.ReactNode;
//   index: number;
//   value: number;
// }

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

function QuestionnairePreview({ onClose, sections, description, submit, showSubmit, open, handleClose }) {
  console.log(sections)
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (

    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="tabs-bg preview-popup" sx={{ bgcolor: 'background.paper' }} >
        <div className='relative-popup'>
          {description ? <Box className="preview-pop-heading">{description}</Box> : null}
          <div className='header-sticky'>
            <Button onClick={onClose} className='cross-right-side'>
              <CloseIcon />
            </Button>
          </div>
          <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}>
            <Tabs
              orientation="vertical"
              // variant="scrollable"
              value={value}
              onChange={handleChange}
              aria-label="Vertical tabs example"
              sx={{ borderRight: 1, borderColor: 'divider' }}
            >
              {sections ? sections.map((data, i) => {
                return <Tab key={i} label={data.title} {...a11yProps(i)} />
              }) : null}
            </Tabs>
            <div className='tabpanel-full-size'>
              {sections ? sections.map((data2, i) => {
                return (
                  <TabPanel key={i} value={value} index={i} className="tabpanel-full">
                    <div className="question-part-preview">
                      <div className="inline-parts">
                        <h3>Questions</h3>
                      </div>
                      {data2.questions ? data2.questions?.map((data3, j) => {
                        return (
                          <div key={j} className='question-card'>
                            <div className='flex-preview-tab'>
                              <p className='preview-question-heading'>Q {j + 1}  </p>
                              <span className='data-text-preview'>{data3.text}</span>
                            </div>
                           <QuestionCategoryInfo 
                           data={data3}
                           marginLeftTrue2={true}
                            />
                            {/* <div className='flex-preview-tab'>
                                <p className='preview-question-heading'>Type </p>
                                <span className='data-text-preview'>{(data3.type || data3?.optionType) == "MCQ" ? "Multiple Choice Questions" : "Single Choice Questions"}</span>
                              </div> */}
                            {data3.options ? data3.options?.map((data4, k) => {
                              return (
                                <div key={k} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div style={{marginLeft: '48px'}}>
                                    <span className='preview-question-heading'> {(data3.type || data3?.optionType) === 'MCQ' ?
                                      <Checkbox checked={false} /> : <Radio checked={false} />
                                    } </span>
                                    <span className='data-text-preview'>{data4.title}</span>
                                  </div>
                                  {/* <div className='flex-preview-tab'>
                                      <p className='preview-question-heading '>Score </p>
                                      <span className='data-text-preview score-selected'>{data4.score}</span>
                                    </div> */}
                                </div>
                              )
                            }) : null}
                          </div>
                        )
                      }) : null}
                    </div>
                  </TabPanel>
                )
              }) : null}
              {showSubmit ? <Button variant="contained" className="addnew export-btn" onClick={submit}>Submit</Button> : null}
            </div>
          </Box>
          {/*<TabPanel value={value} index={1} className="tabpanel-full">
            Item Two
          </TabPanel>
          <TabPanel value={value} index={2} className="tabpanel-full">
            Item Three
          </TabPanel> */}
        </div>
      </Box>

    </Modal>

  )
}

export default QuestionnairePreview