import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  ecosystem: '1',
};

const activeTabSlice = createSlice({
  name: "activeTab",
  initialState,
  reducers: {
    changeEcosystemTab: (state, action) => {
      state.ecosystem = action.payload;
    },
  },
});

export const { changeEcosystemTab } = activeTabSlice.actions;

export default activeTabSlice.reducer;