import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { useState } from "react";
import { Grid, Tab, Tabs, TextField, Typography } from "@mui/material";
import { CustomTabPanel, a11yProps } from "./add-client/addVendor";
import { Link } from "react-router-dom";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import { useSnackbar } from "notistack";

export default function VendorUploadModal({
  handleClose,
  open,
  onSubmit,
  loader,
}) {
  const [formData, setFormData] = useState({
    vendorDomainName: "",
    vendorDomain: [],
    selectedTab: 0,
    filename: "",
  });
  const { enqueueSnackbar } = useSnackbar();

  const handleTabChange = (event, newValue) => {
    setFormData((prev) => ({
      ...prev,
      vendorDomainName: "",
      vendorDomain: [],
      selectedTab: newValue,
      filename: "",
    }));
  };

  const handleInputChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      vendorDomainName: e.target.value,
      vendorDomain: [e.target.value],
    }));
  };

  const handleFileUpload = (event) => {
    const files = event.target.files;
    if (files.length === 0) {
      return;
    }

    const file = files[0];
    const fileName = file.name;
    const fileType = file.type;

    if (
      fileType !== "text/csv" &&
      fileType !==
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      enqueueSnackbar("Only CSV and XLSX files are supported.", {
        variant: "warning",
      });
      return;
    }

    if (fileType === "text/csv") {
      handleCSVFile(file, fileName);
    } else if (
      fileType ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      handleXLSXFile(file, fileName);
    }
  };

  const isValidDomain = (domain) => {
    const domainRegex =
      /^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/;
    return domainRegex.test(domain);
  };

  const handleCSVFile = (file, fileName) => {
    Papa.parse(file, {
      download: true,
      header: false,
      dynamicTyping: true,
      skipEmptyLines: true,
      complete: (results) => {
        const validRows = results.data.filter((row) => row.length === 1);
        const flattenedRows = validRows.map((row) => row[0]);

        const invalidDomains = flattenedRows
          .map((domain, index) => {
            return isValidDomain(domain) ? null : index;
          })
          .filter((index) => index !== null);

        if (invalidDomains.length > 0) {
          const firstInvalidIndex = invalidDomains[0];
          enqueueSnackbar(
            `Invalid domain at column: ${firstInvalidIndex + 1}`,
            { variant: "error" }
          );
          setFormData((prev) => ({
            ...prev,
            vendorDomain: [],
            filename: "",
          }));
        } else {
          setFormData((prev) => ({
            ...prev,
            vendorDomain: flattenedRows,
            filename: fileName,
          }));
        }
      },
    });
  };

  const handleXLSXFile = (file, fileName) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const rows = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      const validRows = rows.filter((row) => row.length === 1);
      const flattenedRows = validRows.map((row) => row[0]);

      const invalidDomains = flattenedRows
        .map((domain, index) => {
          return isValidDomain(domain) ? null : index;
        })
        .filter((index) => index !== null);

      if (invalidDomains.length > 0) {
        const firstInvalidIndex = invalidDomains[0];
        enqueueSnackbar(`Invalid domain at column: ${firstInvalidIndex + 1}`, {
          variant: "error",
        });
        setFormData((prev) => ({
            ...prev,
            vendorDomain: [],
            filename: "",
          }));
      } else {
        setFormData((prev) => ({
          ...prev,
          vendorDomain: flattenedRows,
          filename: fileName,
        }));
      }
    };
    reader.readAsArrayBuffer(file);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    boxShadow: 24,
    textAlign: "center",
    borderRadius: "15px",
    p: 4,
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
              <Box className="add-vendor-tabs">
                <Tabs
                  value={formData.selectedTab}
                  onChange={handleTabChange}
                  aria-label="basic tabs example"
                >
                  <Tab label="Individual" {...a11yProps(0)} />
                  <Tab label="Bulk" {...a11yProps(1)} />
                </Tabs>
              </Box>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <CustomTabPanel value={formData.selectedTab} index={0}>
                <TextField
                  size="large"
                  placeholder="Enter Domain Here..."
                  name="vendorDomainName"
                  value={formData.vendorDomainName}
                  onChange={handleInputChange}
                />
                <div>
                  <h4>
                    Please Enter the valid domain of an individual vendor to
                    connect with the Client. If you want to connect with
                    multiple vendors, click on "Bulk" to upload a CSV file.
                  </h4>
                </div>
              </CustomTabPanel>

              <CustomTabPanel value={formData.selectedTab} index={1}>
                <Box
                  sx={{
                    border: "2px dashed #ccc",
                    borderRadius: "8px",
                    padding: "20px",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                >
                  <CloudUploadIcon fontSize="large" />
                  <Typography variant="h6">
                    Please choose or drop a CSV file containing the list of
                    domains
                  </Typography>
                  <input
                    type="file"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    onChange={handleFileUpload}
                    style={{ display: "none" }}
                    id="file-upload"
                  />
                  <label htmlFor="file-upload">
                    <Button variant="contained" component="span" sx={{ mt: 2 }}>
                      Choose File
                    </Button>
                  </label>
                  {formData?.filename && (
                    <Typography variant="body2" sx={{ mt: 2 }}>
                      {formData?.filename}
                    </Typography>
                  )}
                  <br />
                  <br />
                  <Link
                    href={`${process.env.REACT_APP_BACKEND_BASE_URL}/assets/demo-domains.xlsx`}
                    download="demo-domains.xlsx"
                    underline="none"
                  >
                    Download demo file
                  </Link>
                </Box>
              </CustomTabPanel>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12} lg={12} mt={2}>
            <Button
              variant="contained"
              className="click-to-begin m-l model-btns cancel-btn"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              disabled={loader}
              variant="contained"
              className="click-to-begin model-btns"
              onClick={() => console.log(formData)}
            >
              Confirm
            </Button>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
