import React from "react";
import CircularProgressBar from "./CircularProgressBar";

export default function CircularProgress({ percent, title, attackTittle  }) {
  return (
    <>
      {(attackTittle) ? (
           <div className="attack-box">
             <h2 className="attack-tittle">{attackTittle}</h2>
              <CircularProgressBar percent={percent} />
           </div>
       ): (
        <div className="box-Security-control">
          <div className="progress-part">
           <CircularProgressBar percent={percent} />
            </div>
           <div className="fit-bottom">
           <h2>{title}</h2>
          </div>
        </div>
      )}

    </>
  );
}
