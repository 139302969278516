import React, { Component, useEffect, useState } from "react";
import Loader from "../Loader";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import "./css/questionnaire.css";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { TextareaAutosize } from "@mui/base";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MailSend from "../assets/mailsend.svg";
import { SnackbarProvider, useSnackbar } from 'notistack';
import { Link, useLocation, useParams } from "react-router-dom";
import ModalDialog from "./ModalDialog.js";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import PostRequest from "../components/apiConnections/postRequest";
import { getLocalStoarge } from "../components/common/allFunctions";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { changeEcosystemTab } from "../redux/activeTabSlice";
import {
  Grid,
  Tabs,
  Tab,
  Container,
  Typography,
  Card,
  CardHeader,
  Stack,
  Box,
  TextField,
  Alert,
  Button,
  IconButton,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import QuestionCategoryInfo from "./QuestionCategoryInfo.jsx";

function TabPanel(props) {

  const { children, value, index, ...other } = props;

  return (
    <>
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    </>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function Questionnaire({ theme }) {
  const [value, setValue] = React.useState(0);
  const data = useParams();
  const dispatch = useDispatch();
  const [vendorUrl, setVendorUrl] = useState(data.url);
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = React.useState(false);
  const [aiFill, setAiFill] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [showLoader, setshowLoader] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [assessmentData, setAssessmentData] = useState(window.localStorage.getItem("assessmentData"));
  const [questions, setQuestions] = useState([]);
  const [queData, setQueData] = useState(JSON.parse(window.localStorage.getItem("assessmentData")));
  const [deadline, setDob] = useState('');
  const [dataClassification, setDataClassification] = useState([]);
  const [accessLevel, setAccessLevel] = useState([]);
  const [regulatory, setRegulatory] = useState([]);
  const [selectSet, setSelectSet] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [ques, setQues] = useState({});


  useEffect(() => {
    getQuestionnaire();
  }, [])

  const aiFillAndSend = ()=> {
    setAiFill(true);
    setOpen(true);
  }

  const do_saved = async () => {
    setshowLoader(true);
    let payload = {
      firstName: queData.firstName,
      lastName: queData.lastName,
      email: queData.email,
      data_classification: queData.data_classification,
      assess_level: queData.assess_level,
      regulatory_compliance: queData.regulatory_compliance,
      set: queData.set,
      setId: queData.setId,
      setType: queData.setType,
      deadline: queData.deadline,
      reassessmentDates: queData.reassessmentDates,
      createdBy: queData.createdBy,
      url: vendorUrl,
      aiFill
    };

    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ADD_ASSESSMENT_BY_USER}`,
          payload,
        )
        .then((resultData) => {
          enqueueSnackbar("Assessment Sent successfully.", { variant: 'success' })
          setshowLoader(false);
          window.localStorage.removeItem('assessmentData');
          
          const data = resultData.data.data;
          if(data.aiFill){
            const assessmentId = data._id;
            const email = data.email;
            navigate(`/dashboard/view-answer-page/${assessmentId}`, { state: {email, prevUrl:'/dashboard/assessment' } });
          }else{
            dispatch(changeEcosystemTab("4"))
            navigate(`../${location?.state}`, { state: { tab: 4 } });
          }
        })
        .catch((errChart) => {
          enqueueSnackbar("Assessment not saved.", { variant: 'error' })
          setshowLoader(false);
        })
      }
      else {
        setshowLoader(false);
        window.location.href = "/";
      }
    }
    catch (error) {
      enqueueSnackbar("Assessment not saved.", { variant: 'error' })
      setshowLoader(false);
    }
  }

  const getQuestionnaire = async () => {
    setAssessmentData(JSON.parse(assessmentData));
    const checkSet = JSON.parse(assessmentData);
    let questionnaireSet = '';
    Object.entries(checkSet).map((key, val) => {
      if (key[0] == "set") {
        questionnaireSet = key[1];
      }
    })
    setshowLoader(true);
    const payload = {
      set: questionnaireSet,
      setId: checkSet?.setId,
      setType: checkSet?.setType
    }
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        const endpointIssue = process.env.REACT_APP_GET_QUESTIONNAIRE_BY_USER;
        await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpointIssue}`,
          payload
        )
          .then((res) => {
            let response = res.data.data;
            setQuestions(response.data)
            setQues(response);
            setTimeout(() => {
              setshowLoader(false);
            }, 1000)
          })
          .catch((err) => {
            enqueueSnackbar("Questionnaire details not find.", { variant: 'error' });
            setshowLoader(false);
          })
      } else {
        window.location.href = "/";
      }
    }
    catch (error) {
      enqueueSnackbar("Questionnaire details not find.", { variant: 'error' });
      setshowLoader(false);
    }
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const ModalContent = () => {
    return (
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        <img src={MailSend} alt="icon" />
        <h2 className="send-assessments">Send Assessment to the vendor?</h2>
      </Typography>
    );

  }

  return (
    <>
      <Loader show={showLoader} />
      <Container maxWidth={false}>
        <Typography variant="h4">
          <span className='heading-main'>Questionnaire</span>
        </Typography>
        {
          (questions) ? (
            <div className="tabs-bg">
              <Box sx={{ flexGrow: 1, display: "flex" }}>
                {questions.map((element, index, array) => {
                  <Tab label={'test'} key={index}  {...a11yProps(`tab11`)} />
                })}
                <Tabs
                  orientation="vertical"
                  variant="scrollable"
                  value={value}
                  onChange={handleChange}
                  aria-label="Vertical tabs example"
                  sx={{ borderRight: 1, borderColor: "divider" }}
                >
                  {
                    questions.map((tab, index) => (
                      <Tab
                        key={tab.category.toString() + index}
                        label={tab.category}
                        {...a11yProps(index)}
                      />
                    ))
                  }
                </Tabs>
                {questions.map((tab, index) => (
                  <TabPanel key={index} value={value} index={index} className="tabpanel-full-size">
                    <div className="question-part">
                      <div className="inline-parts">
                        <h3>{ques.set}</h3>
                        <Grid item xs={12} md={12} lg={12} className="btn-right">
                          <Button variant="contained" className="click-to-begin m-l cancel-btn" onClick={(e) => navigate(`../vendor-information/${vendorUrl}`, { state: location?.state })}>Back </Button>
                          <Button variant="contained" className="click-to-begin mr-1" onClick={handleOpen}>
                            Send
                          </Button>
                          <Button variant="contained" className="click-to-begin" onClick={aiFillAndSend}>
                            AI Fill & Send
                          </Button>
                        </Grid>
                      </div>
                      {assessmentData?.setType == 'default' ? <div>
                        {tab.question.map((key, val) => (
                          <div key={val}>
                            <p>{key}</p>
                            <QuestionCategoryInfo data={key} marginLeftTrue={true}/>
                            <FormControl>
                              <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                              >
                                <FormControlLabel
                                  value="#"
                                  control={<Radio />}
                                  label="Yes"
                                  disabled
                                />
                                <FormControlLabel
                                  value="#"
                                  control={<Radio />}
                                  label="No"
                                  disabled

                                />
                                <FormControlLabel
                                  value="#"
                                  control={<Radio />}
                                  label="N/A"
                                  disabled
                                />
                              </RadioGroup>
                            </FormControl>
                            <p>Write here</p>
                            <TextareaAutosize
                              aria-label="Write here"
                              minRows={3}
                              className="w100-textarea"
                              disabled
                            />
                          </div>
                        ))}
                      </div> : (assessmentData?.setType == 'custom' || assessmentData?.setType == "customAdmin") ? <div>
                        {tab.question.map((key, val) => (
                          <div key={val}>
                            <div class="flex-preview-tab">
                              <p class="preview-question-heading">Q. {val + 1}</p>
                              <span className="data-text-preview">{key.text}</span>
                            </div>
                            <QuestionCategoryInfo data={key} marginLeftTrue={true}/>
                            {key.options?.map((option, i4) => (
                              <div>
                                <div class="flex-preview-tab" style={{marginLeft:"88px"}}>
                                  <p >
                                    <FormControlLabel
                                      value="#"
                                      control={key.optionType == 'MCQ' ? <Checkbox /> : <Radio />}
                                      label=""
                                      disabled
                                    />
                                  </p>
                                  <span className="data-text-preview">{option.title}</span>
                                </div>
                                {/* <div class="flex-preview-tab">
                                 <p class="preview-question-heading ">Score</p>
                                 <span className="data-text-preview score-selected">{option.score}</span>
                               </div> */}
                              </div>
                            ))}
                            <div class="flex-preview-tab">
                              <p className="preview-question-heading">Write here</p>
                              <TextareaAutosize
                                aria-label="Write here"
                                minRows={3}
                                className="w100-textarea"
                                disabled
                              />

                              <AttachFileIcon className="space-file" />
                            </div>
                          </div>
                        ))}
                      </div> : null
                      }
                    </div>
                  </TabPanel>
                ))}
              </Box>
            </div>
          ) :
            (
              <div>
                {/* <Box sx={{ flexGrow: 1, display: "flex" }}> */}
                <Card sx={{ padding: "10px", "margin": "10px" }}>
                  <p>Questionnaire Data Not Found !</p>
                  {/* </Box>                       */}
                  <Grid item xs={12} md={12} lg={12}>
                    <Link to={`../vendor-information/${vendorUrl}`}>
                      <Button variant="contained" className="click-to-begin m-l cancel-btn">Previous </Button>
                    </Link>
                  </Grid>
                </Card>

              </div>
            )
        }

        <ModalDialog handleClose={handleClose} open={open} onSubmit={do_saved} contentData={ModalContent} />
      </Container>
    </>
  );
}
