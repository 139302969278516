import React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

export const AddEditClientScreenTypes = Object.freeze({
  Details: 1,
  Subscriptions: 2,
  AddVendors: 3,
});

const steps = Object.keys(AddEditClientScreenTypes).map(key => key.replace(/([a-z])([A-Z])/g, '$1 $2'));

const StepperComponent = ({ activeStep }) => (
  <Box sx={{ width: "100%" }}>
    <Stepper activeStep={activeStep} alternativeLabel>
      {steps.map((label, index) => (
        <Step key={index}>
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  </Box>
);

export default StepperComponent;
