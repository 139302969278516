import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { useEffect, useState } from "react";

import { Grid, CircularProgress, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

export default function ExtendDeadlineModel({
    handleClose,
    open,
    contentData,
    onSubmit,
    deadline,
    loader,
    handleExtendDeadlineChange,
    getCurrentDate
}) {
    const [theme, setTheme] = useState(localStorage.getItem("theme"));
    const [content, setContent] = useState(contentData);
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 600,
        bgcolor: "background.paper",
        boxShadow: 24,
        textAlign: "center",
        borderRadius: "15px",
        p: 4,
    };
    useEffect(() => {
        setInterval(() => {
            const themeName =
                localStorage.getItem("theme") !== null
                    ? localStorage.getItem("theme")
                    : "Dark";
            setTheme(themeName);
        }, 2000);
    }, []);

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className={
                    theme === "Dark" ? "dark-modal modal-popup" : "light-modal modal-popup"
                }
            >
                <Box sx={style}>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            <p>Extend Deadline</p>
                        <form onSubmit={onSubmit}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6} lg={6}>
                                    <TextField
                                        name="deadline"
                                        type="date"
                                        label="Deadline"
                                        value={deadline}
                                        InputProps={{
                                            readOnly: true,
                                            style: { color: 'white !important', marginBottom: "10px" }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} lg={6}>
                                    <TextField
                                        name="extendDeadline"
                                        InputLabelProps={{ shrink: true, required: true }}
                                        type="date"
                                        label="Extend Deadline"
                                        onChange={handleExtendDeadlineChange}
                                        inputProps={{ min: getCurrentDate(new Date()), onKeyDown: (e) => e.preventDefault() }}
                                        sx={{ color: "white", marginBottom: "10px" }}
                                    />
                                </Grid>
                            </Grid>
                        </form>
                    </Typography>
                    <Grid item xs={12} md={12} lg={12} className="center-vendor">
                        <Button
                            variant="contained"
                            className="click-to-begin m-l model-btns cancel-btn"
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                        <LoadingButton loading={loader}
                            loadingIndicator={<CircularProgress size={12} sx={{ marginRight: "85px", color: "white" }} />}
                            size="large"
                            type="submit"
                            onClick={() => onSubmit()}
                            variant="contained" >
                            <span>Confirm</span>
                        </LoadingButton>
                    </Grid>
                </Box>
            </Modal>
        </div>
    );
}
