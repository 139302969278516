import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
// @mui
import { Card, CardHeader, Container, Grid, Typography, Box, CircularProgress } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useSnackbar } from "notistack";
import "./css/Dashboard.css";
import ReportChart from "./ReportChart";
import PostRequest from "../components/apiConnections/postRequest";
import GetRequest from '../components/apiConnections/getRequest';
import {
  AppCurrentVisits,
  AppOverallScore,
  AppWebsiteVisits,
  AppWidgetSummary,
} from "../sections/@dashboard/app";
import { useTheme } from "@mui/material/styles";

export default function DashboardAppPage() {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [totalApps, setTotalApps] = useState(0);
  const [totalIssues, setTotalIssues] = useState(0);
  const [completedAssessment, setCompletedAssessment] = useState(0);
  const [delayedAssessment, setDelayedAssessment] = useState(0);
  const [ongoingAssessment, setOngoingAssessment] = useState(0);
  const [severityIssue, setSeverityIssue] = useState({});
  const [thirdPartyRisk, setThirdPartyRisk] = useState([]);
  const [graphDates, setGraphDates] = useState([]);
  const [graphValues, setGraphValues] = useState([]);
  const [option, setOption] = useState(365);
  const [issueSeverityLoader, setIssueSeverityLoader] = useState(false);
  const [thirdPartyRiskLoader, setThirdPartyRiskLoader] = useState(false);
  const [chartDataLoader, setChartDataLoader] = useState(false);
  const [dashboardDataLoader, setDashboardDataLoader] = useState(false);
  const [thirdPartyRiskConfig, setThirdPartyRiskConfig] = useState([]);

  useEffect(() => {
    getChartData();
  }, [option]);
  useEffect(() => {
    getDashboardCardData();
    getIssueSeverityData();
    getThirdPartyAtRiskData();
  }, [])
  const totalIssuesChange = async (event) => {
    // 👇 Get input value from "event"
    setOption(event.target.value);
  };

  const getChartData = async () => {
    setChartDataLoader(true);
    const payload = {
      selectedDays: option,
    };
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        const endpointIssue = process.env.REACT_APP_LAST_N_DAYS_ISSUES;
        await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpointIssue}`,
          payload
        )
          .then((resChart) => {
            let response = resChart.data.data;
            let allDates = [];
            let allIssues = [];
            if (response?.totalIssue) {
              response?.totalIssue.map((datas) => {
                allDates.push(datas.date)
                allIssues.push(datas.calculateIssue)
              });
              setGraphDates(allDates);
              setGraphValues(allIssues);
              setChartDataLoader(false);
            } else {
              enqueueSnackbar("No data found for monthly chart.", {
                variant: "error",
              });
              setChartDataLoader(false);
            }
          })
          .catch((errChart) => {
            enqueueSnackbar(errChart.message, { variant: "error" });
            setChartDataLoader(false);
          });
      } else {
        window.location.href = "/";
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setChartDataLoader(false);
    }
  };

  const getDashboardCardData = async () => {
    setDashboardDataLoader(true);
    try {
      const endpoint = process.env.REACT_APP_GET_DASHBOARD_CARD_DATA;
      await GetRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}`
      )
        .then((resDashboard) => {
          const response = resDashboard.data.data;
          setTotalIssues(response.totalIssues);
          setTotalApps(response.totalApps);
          setCompletedAssessment(response.completedAssessment);
          setDelayedAssessment(response.delayedAssessment);
          setOngoingAssessment(response.ongoingAssessment);
          setDashboardDataLoader(false);
        })
        .catch((errDashboard) => {
          enqueueSnackbar(errDashboard.message, { variant: "error" });
          setDashboardDataLoader(false);
        });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setDashboardDataLoader(false);
    }
  };

  const getIssueSeverityData = async () => {
    setIssueSeverityLoader(true);
    try {
      const endpoint = process.env.REACT_APP_GET_ISSUE_SEVERITY_DATA;
      await GetRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}`
      )
        .then((resDashboard) => {
          const response = resDashboard.data.data;
          setSeverityIssue(response);
          setIssueSeverityLoader(false);
        })
        .catch((errDashboard) => {
          enqueueSnackbar(errDashboard.message, { variant: "error" });
          setIssueSeverityLoader(false);
        });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setIssueSeverityLoader(false);
    }
  };

  const getThirdPartyAtRiskData = async () => {
    setThirdPartyRiskLoader(true);
    try {
      const endpoint = process.env.REACT_APP_GET_THIRD_PARTY_AT_RISK;
      await GetRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}`
      )
        .then((resDashboard) => {
          const response = resDashboard.data.data;
          setThirdPartyRisk(response);
          let arr = [
            { vendor: "Critical", score: 0 },
            { vendor: "High", score: 0 },
            { vendor: "Medium", score: 0 },
            { vendor: "Low", score: 0 },
          ];
          for (let item of response) {
            switch (true) {
              case item.score <= 25: arr[0].score++;
                break;
              case item.score <= 50: arr[1].score++;
                break;
              case item.score <= 75: arr[2].score++;
                break;
              case item.score <= 100: arr[3].score++;
                break;
              default:
                break;
            }
          }
          setThirdPartyRiskConfig([...arr]);
          setThirdPartyRiskLoader(false);
        })
        .catch((errDashboard) => {
          enqueueSnackbar(errDashboard.message, { variant: "error" });
          setThirdPartyRiskLoader(false);
        });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setThirdPartyRiskLoader(false);
    }
  };
  return (
    <>
      <Helmet>
        <title> Dashboard | Genesis Platform</title>
      </Helmet>
      <Container maxWidth={false} className="max-w-left">
        <Typography variant="h4" sx={{ mt: 4, mb: 5 }}>
          <span className="heading-main ml25">Dashboard</span>
        </Typography>

        <Grid container spacing={3} className="ipad-space">
          <Grid item xs={12} md={2.4} className="left-fit-contain">
            <Card sx={{ mb: 1 }}>
              <AppWidgetSummary
                loading={dashboardDataLoader}
                className="line-dark"
                title="Total"
                subtitle="Third Party Apps"
                total={totalApps?.toString()}
              />
            </Card>
          </Grid>
          <Grid item xs={12} md={2.4} className="left-fit-contain">
            <Card sx={{ mb: 1 }} style={{}}>
              <AppWidgetSummary
                loading={dashboardDataLoader}
                className="line-dark"
                title="Total"
                subtitle="Issues"
                total={totalIssues?.toString()}
              />
            </Card>
          </Grid>
          <Grid item xs={12} md={2.4} className="left-fit-contain">
            <Card sx={{ mb: 1 }} style={{}}>
              <AppWidgetSummary
                loading={dashboardDataLoader}
                className="line-dark"
                title="Total"
                subtitle="Ongoing Assessment"
                total={ongoingAssessment?.toString()}
              />
            </Card>
          </Grid>
          <Grid item xs={12} md={2.4} className="left-fit-contain">
            <Card sx={{ mb: 1 }} style={{}}>
              <AppWidgetSummary
                loading={dashboardDataLoader}
                className="line-dark"
                title="Total"
                subtitle="Completed Assessment"
                total={completedAssessment?.toString()}
              />
            </Card>
          </Grid>

          <Grid item xs={12} md={2.4} className="left-fit-contain">
            <Card sx={{ mb: 1 }} style={{}}>
              <AppWidgetSummary
                loading={dashboardDataLoader}
                className="line-dark"
                title="Total"
                subtitle="Delayed Assessment"
                total={delayedAssessment?.toString()}
              />
            </Card>
          </Grid>
          <Grid item xs={12} md={4} lg={4} className="fit-height">
            <AppOverallScore title="Overall score" riskScore="0" />
          </Grid>
          <Grid item xs={12} md={4} lg={4} className="fit-height">
            <AppCurrentVisits
              loading={issueSeverityLoader}
              title="Third-Party Issue Severity"
              chartData={[
                { label: "Low", value: severityIssue?.low || 0 },
                { label: "Medium", value: severityIssue?.medium || 0 },
                { label: "High", value: severityIssue?.high || 0 },
                { label: "Critical", value: severityIssue?.critical || 0 },
              ]}
              chartColors={[
                "#6AA84F",
                "#FEB019",
                "#ff7300",
                "#d32f2f",
              ]}
              style={{}}
            />
          </Grid>

          <Grid item xs={12} md={4} lg={4} className="fit-height">
            <ReportChart
              loading={thirdPartyRiskLoader}
              title="Third Party Classification By Risk"
              style={{}}
              chartData={thirdPartyRiskConfig}
            />
          </Grid>

          <Grid
            item
            xs={12}
            md={5}
            lg={5}
            className="mtop fit-height "
            sx={{ flexDirection: 'column' }}
          >
            <Box component={Paper} sx={{ display: "flex", flexDirection: "column", height: "95%" }}
            >
              <CardHeader title={"Third Party at Risk"} subheader={""} />
              <TableContainer className={theme.palette?.mode === "light" ? " third-party-risk-table-light" : ` third-party-risk-table`} >
                {thirdPartyRiskLoader ? (
                  <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "295px", width: "100%" }}>
                    <CircularProgress />
                  </Box>) : <>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow className="head-th">
                        <TableCell className="min-width">S.No.</TableCell>
                        <TableCell>Vendor Name</TableCell>
                        <TableCell className="min-width">Score</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody sx={{ maxHeight: "100px" }}>

                      {thirdPartyRisk && thirdPartyRisk.length > 0 &&  thirdPartyRisk?.map((row, index) => (  
                        <TableRow hover key={row.vendor} tabIndex={-1}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell style={{ textTransform: "capitalize" }}>
                            {row.vendor}
                          </TableCell>
                          <TableCell>{row.score}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  {!thirdPartyRisk?.length ? <Box sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "10px"
                  }}>No Data Available</Box> : null}
                </>
                }
              </TableContainer>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            md={7}
            lg={7}
            className="mtop mbottom  flex-property w100"
          >
            {/* <div class="total_issues_select_wrap">
              <select
                className="total_issues_select"
                onChange={totalIssuesChange}
              >
                <option value={30}>Last Month</option>
                <option value={90}>Last 3 Months</option>
                <option value={180}>Last 6 Months</option>
                <option value={365} selected="true">
                  Last Year
                </option>
              </select>
            </div> */}


            <AppWebsiteVisits
              title="Total Issues"
              // subheader="Last 30 Days"
              chartLabels={graphDates}
              loading={chartDataLoader}
              chartData={[
                {
                  name: "Total Issues",
                  type: "area",
                  fill: "gradient",
                  data: graphValues,
                },
              ]}
              totalIssuesChange={totalIssuesChange}
              option={option}
            />

          </Grid>
          {/* <Link to="/dashboard/chat-app" className='chat-circle-right'><ChatIcon /></Link> */}
        </Grid>
      </Container >
    </>
  );
}
