const requestNotificationPermission = async () => {
  try {
    const permission = await Notification.requestPermission();
    return permission === "granted";
  } catch (error) {
    console.error("Error requesting notification permission:", error);
    return false;
  }
};

export const ShowDesktopNotification = (title, message, url) => {
  if (!("Notification" in window)) {
    console.error("This browser does not support desktop notifications");
    return;
  }

  if (Notification.permission !== "granted") {
    requestNotificationPermission()
      .then((permissionGranted) => {
        if (permissionGranted) {
          const notification = new Notification(title, { body: message });
          notification.onclick = (e) => {
            e.preventDefault();
            window.location.href = url;
            // window.open(url, "_self");
            notification.close();
          };
        }
      })
      .catch((error) => {
        console.error(
          "Error checking/requesting notification permission:",
          error
        );
      });
    return;
  }

  const notification = new Notification(title, { body: message });
  notification.onclick = (e) => {
    e.preventDefault();
    window.location.href = url;
    // window.open(url, "_self");
    notification.close();
  };
};
