import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { useEffect, useState } from "react";

import { Grid, CircularProgress, TextField, Typography, MenuItem, Select, FormControl, InputLabel } from "@mui/material";
import { LoadingButton } from "@mui/lab";

function ApproveDeclineAssesmentModel({
    handleClose,
    open,
    onSubmit,
    loader,
    handleAssesmentStatusChange,
    assessmentApprovedDeclinedStatus
}) {
    const [theme, setTheme] = useState(localStorage.getItem("theme"));
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 600,
        bgcolor: "background.paper",
        boxShadow: 24,
        textAlign: "center",
        borderRadius: "15px",
        p: 4,
    };
    useEffect(() => {
        setInterval(() => {
            const themeName =
                localStorage.getItem("theme") !== null
                    ? localStorage.getItem("theme")
                    : "Dark";
            setTheme(themeName);
        }, 2000);
    }, []);

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className={theme === "Dark" ? "dark-modal modal-popup" : "light-modal modal-popup"}
            >
                <Box sx={style}>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <p style={{ marginBottom: '30px' }}>Accept/Decline Assessment</p> {/* Added marginBottom here */}
                        <form onSubmit={onSubmit}>
                            <Grid container spacing={3} justifyContent="center">
                                <Grid item xs={12} md={6} lg={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <FormControl sx={{ width: '200px', marginTop: 0 }}>
                                        <InputLabel id="demo-simple-select-autowidth-label">Status</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-autowidth-label"
                                            id="demo-simple-select-autowidth"
                                            required
                                            value={assessmentApprovedDeclinedStatus}
                                            onChange={handleAssesmentStatusChange}
                                            sx={{ height: '40px', padding: '4px', marginTop: '8px', marginBottom: '20px'}} // Reduced margin here
                                            label="Status"
                                        >
                                            <MenuItem key="Approved" value="Approved">Approve</MenuItem>
                                            <MenuItem key="Declined" value="Declined">Decline</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </form>
                    </Typography>
                    <Grid item xs={12} md={12} lg={12} className="center-vendor">
                        <Button
                            style={{ marginTop: '30px' }}
                            variant="contained"
                            className="click-to-begin m-l model-btns cancel-btn"
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                        <LoadingButton
                            loading={loader}
                            loadingIndicator={<CircularProgress size={12} sx={{ marginRight: "85px", color: "white" }} />}
                            size="large"
                            type="submit"
                            onClick={() => onSubmit()}
                            variant="contained"
                        >
                            <span>Confirm</span>
                        </LoadingButton>
                    </Grid>
                </Box>
            </Modal>
        </div>
    );
}

export default ApproveDeclineAssesmentModel;