import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSnackbar } from "notistack";

const whitelist = [
    '/',
    '/hexsiejsms',
    '/twofa',
    '/forget-password',
    "/confirm-account/:token",
    "/reset-password/:token"
];

export function IdleTimer() {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const location = useLocation();
    let timeout = null;
    const goBackToHome = () => {
        enqueueSnackbar("Session has been expired, please login again", { variant: "error" });
        clearTimeout(timeout);
        window.localStorage.removeItem('roleId');
        window.localStorage.removeItem('R1');
        window.localStorage.removeItem('R2');
        window.localStorage.removeItem('usertime');
        window.localStorage.removeItem('userDetails');
        window.localStorage.removeItem('deleteAssessment');
        window.localStorage.removeItem('deleteRemediation');
        window.localStorage.removeItem('deleteFunction');
        navigate('/');
    };

    const restartAutoReset = () => {
        if (timeout) {
            clearTimeout(timeout);
        }
        timeout = setTimeout(() => {
            goBackToHome();
        }, 1000 * 60 * process.env.REACT_APP_IDLE_TIMEOUT);
    };

    useEffect(() => {
        if (whitelist.some(path=> path === location.pathname)) {
            return;
        }
        restartAutoReset();
        const handleMousemove = () => {
            restartAutoReset();
        };
        window.addEventListener('mousemove', handleMousemove);
    
        const handleKeypress = () => {
            restartAutoReset();
        };
        window.addEventListener('keypress', handleKeypress);
    
        // Cleanup
        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
            window.removeEventListener('mousemove', handleMousemove);
            window.removeEventListener('keypress', handleKeypress);
        };
    }, [location.pathname]);
    return <div />;
};