import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
  Checkbox
} from "@mui/material";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import UserProfile from "../assets/user-profile.svg";
import Upload from "../assets/Vectorplus.svg";
import MuiPhoneNumber from "material-ui-phone-number";

const AddEditUserPage = () => {
  const { id } = useParams();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    websites: [],
    email: "test@gmail.com",
    mobile: "",
    twoFAMethod: ""
  });
  const [phoneNumber, setPhoneNumber] = useState(formData.mobile || "");
  const [emailDomain, setEmailDomain] = useState("");
  const dashboardPermissions = [
    { name: "dashboard", label: "Dashboard" },
    { name: "totalThirdParty", label: "Total Third Party" },
    { name: "totalIssues", label: "Total Issues" },
    { name: "totalOngoingAssessment", label: "Total Ongoing Assessment" },
    { name: "totalCompletedAssessment", label: "Total Completed Assessment" },
    { name: "totalDelayedAssessment", label: "Total Delayed Assessment" },
    { name: "overallScore", label: "Overall Score" },
    { name: "thirdPartyIssueSeverity", label: "Third Party Issue Severity" },
    { name: "thirdPartyClassificationByRisk", label: "Third Party Classification By Risk" },
    { name: "thirdPartyAtRisk", label: "Third Party at Risk" },
    { name: "totalIssues", label: "Total Issues" },
  ]
  const ecosystemPermissions = [
    { name: "ecosystems", label: "Ecosystems" },
    { name: "allEcosystemDetail", label: "All Ecosystem Detail" },
    { name: "viewVendorDetails", label: "View Vendor Details" },
    { name: "deleteVendor", label: "Delete Vendor" },
  ]
  const attackSurfacePermissions = [
    { name: "attackSurface", label: "Attack Surface" },
    { name: "addNewDomain", label: "Add New Domain" },
    { name: "ecosystems", label: "View All Attacks" },
    { name: "ecosystems", label: "View Attack Details" },
    { name: "ecosystems", label: "Third Party Classification By Risk" },
    { name: "remove", label: "Remove an Attack" },
  ]
  useEffect(() => {
    if (id) {
      setFormData((prev) => ({
        ...prev,
        firstName: "Anshul",
        lastName: "Paroha",
        websites: ["gmail.com", "5exceptions.com"],
        email: "anshulparoha00@gmailcom",
        twoFAMethod: "email"
      }));
      setPhoneNumber("+919470499763");
    }
  }, [id]);
  return (
    <>
      <Grid container spacing={0.5} sx={{ mt: 2, mb: 2 }}>
        <Grid item xs={12} sm={12} md={6} lg={12}>
          <div className="d-flex-company-info">
            <div className="d-flex-company-logo">
              <img
                // src={
                //   typeof formData.logo === "string"
                //     ? `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_STATIC_PATH}${formData?.logo}`
                //     : formData.logo
                //     ? URL.createObjectURL(formData.logo)
                //     : UserProfile
                // }
                src={UserProfile}
                alt="login"
                style={{
                  boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.05)",
                }}
              />
            </div>
            <div className="align-peragraph-p">
              {/* <h3>{formData.company}</h3> */}
              <h3>Aditya Birla</h3>
              <p>
                <PermIdentityIcon />
                {/* {`${formData.firstName} ${formData.lastName}`} */}
                Anshul Paroha
              </p>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={12} sx={{ mt: 2 }}>
          <Typography variant="h4">
            <span className="heading-main">
              {id ? "Edit User" : "Add User"}
            </span>
          </Typography>
        </Grid>
      </Grid>

      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={2} lg={2} className="center-upload">
            <div>
              <Box
                component="img"
                alt="Profile Image"
                src={
                  typeof formData.logo === "string"
                    ? `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_STATIC_PATH}${formData?.logo}`
                    : formData.logo
                      ? URL.createObjectURL(formData.logo)
                      : UserProfile
                }
              />
            </div>
            <Button
              htmlFor="file-upload"
              size="small"
              variant="contained"
              color="primary"
              component="label"
              className="upload-new-plus"
              title="Select Image"
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <img src={Upload} alt="icon" />
                <span>Upload Logo</span>
              </div>
            </Button>
            <input
              id="file-upload"
              type="file"
              accept="image/jpg, image/png, image/PNG, image/jpeg, image/JPG, image/JPEG"
              // onChange={handleImageChange}
              style={{ display: "none" }}
            />
          </Grid>

          <Grid item xs={12} md={10} lg={10}>
            <Stack spacing={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={6} pb={1}>
                  <TextField
                    name="firstName"
                    value={formData.firstName || ""}
                    label="First Name"
                    required
                    inputProps={{ maxLength: 15 }}
                    InputLabelProps={{ shrink: true }}
                  // onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6} pb={1}>
                  <TextField
                    name="lastName"
                    label="Last Name"
                    required
                    value={formData.lastName || ""}
                    inputProps={{ maxLength: 15 }}
                    InputLabelProps={{ shrink: true }}
                  // onChange={handleInputChange}
                  />
                </Grid>
                
                <Grid item xs={12} md={6} lg={6} pb={1}>
                  <FormControl fullWidth>
                    <TextField
                      name="email"
                      value={formData.email?.split("@")[0]}
                      label="Email"
                      required
                      // onChange={handleInputChange}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {formData.websites.length > 0 && (
                              <Select
                                value={emailDomain}
                                // onChange={handleEmailDomainChange}
                                displayEmpty
                                inputProps={{
                                  "aria-label": "Email Domain",
                                }}
                                className="companyInfo-input-adornment"
                              >
                                <MenuItem value="">Select Domain</MenuItem>
                                {formData.websites.map((website, index) => (
                                  <MenuItem key={index} value={website}>
                                    {`@${website}`}
                                  </MenuItem>
                                ))}
                              </Select>
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={6} pb={1}>
                  <MuiPhoneNumber
                    InputComponent={(params) => <TextField {...params} />}
                    variant="outlined"
                    label="Phone Number"
                    required
                    defaultCountry={"us"}
                    value={phoneNumber}
                    onChange={(value) => {
                      // handlePhoneChange(value);
                    }}
                    disableAreaCodes={true}
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={6} pb={1} className="align-justifys">
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      className="gender"
                      sx={{
                        // color: "#242526",
                        fontFamily: "Poppins",
                      }}
                    >
                      2FA Method :

                    </Box>
                    <div className="switch-flex">
                      <span>Email</span>
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider"></span>
                      </label>
                      <span>SMS</span>
                      {/* <div class="toggle-button-cover">
                      <div class="button r" id="button-3">
                        <input type="checkbox" class="checkbox"/>
                        <div class="knobs"></div>
                        <div class="layer"></div>
                      </div>
                    </div> */}
                    </div>
                    {/* <FormControl>
                      <RadioGroup
                        row
                        name="2fa-method"
                        value={formData.twoFAMethod}
                        // onChange={handleChangeTwoFa}
                      >
                        <FormControlLabel
                          value="email"
                          control={<Radio />}
                          label="Email"
                        />
                        <FormControlLabel
                          value="sms"
                          control={<Radio />}
                          label="SMS"
                        />
                      </RadioGroup>
                    </FormControl> */}
                  </Box>
                </Grid>
              </Grid>
            </Stack>
            {/* <br />
                <div style={{ display: "flex" , justifyContent: "end", gap: "10%"}}>
                <Button
                  variant="contained"
                  className="add-user"
                  onClick={() => navigate("../clients-management")}
                >
                  Cancel
                </Button>
                {formData.clientId ? (
                  <LoadingButton
                    size="medium"
                    type="submit"
                    variant="contained"
                    color="success"
                    className="add-user"
                  >
                    Submit
                  </LoadingButton>
                ) : (
                  <LoadingButton
                    type="submit"
                    size="medium"
                    variant="contained"
                    color="success"
                    className="add-user"
                  >
                    Next
                  </LoadingButton>
                )}
                </div> */}
          </Grid>
        </Grid>
      </Box>

      <Grid container spacing={0.5} sx={{ mt: 2, mb: 2 }}>
        <Grid item xs={12} sm={12} md={6} lg={12} sx={{ mt: 2 }} >
          <Typography variant="h4">
            <span className="heading-main">
              Permissions
            </span>
          </Typography>
          <p>
            Define the permission for this user.
          </p>
          <Grid container spacing={2} >
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Box>
                {dashboardPermissions.map((item) => (
                  <div className="d-flex-checkbox">
                    <Checkbox
                      // checked={!!formData.isAttackRequestEnabled}
                      // onChange={handleCheckboxChange}
                      name={item.name}
                      color="primary"
                      sx={item.name === "dashboard" ? {
                        '& .MuiSvgIcon-root': {
                          fontSize: 26,
                        },
                      } : {}}
                    />
                    <Box sx={item.name === "dashboard" ? { fontWeight: "bold" } : {}}>
                      {item.label}
                    </Box>
                  </div>
                ))}
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Box>
                {ecosystemPermissions.map((item) => (
                  <div className="d-flex-checkbox">
                    <Checkbox
                      // checked={!!formData.isAttackRequestEnabled}
                      // onChange={handleCheckboxChange}
                      name={item.name}
                      color="primary"
                      sx={item.name === "ecosystems" ? {
                        '& .MuiSvgIcon-root': {
                          fontSize: 26, // larger checkmark icon                        
                        },
                      } : {}}
                    />
                    <Box sx={item.name === "ecosystems" ? { fontWeight: "bold" } : {}}>
                      {item.label}
                    </Box>
                  </div>
                ))}
                <Box sx={{ marginBottom: "45px" }}></Box>
                {attackSurfacePermissions.map((item) => (
                  <div className="d-flex-checkbox">
                    <Checkbox
                      // checked={!!formData.isAttackRequestEnabled}
                      // onChange={handleCheckboxChange}
                      name={item.name}
                      color="primary"
                      sx={item.name === "attackSurface" ? {
                        '& .MuiSvgIcon-root': {
                          fontSize: 26, // larger checkmark icon
                          fontWeight: "bold"
                        },
                      } : {}}
                    />
                    <Box sx={item.name === "attackSurface" ? { fontWeight: "bold" } : {}}>
                      {item.label}
                    </Box>
                  </div>
                ))}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default AddEditUserPage;
