import { Stack, Typography, Box, Modal, Button, Grid, Link } from '@mui/material';
import React, { useState } from 'react'
import '../../pages/css/AddFile.css';
import CloseIcon from '@mui/icons-material/Close';
function ShowCompliance({ open, handleClose, contentData }) {
    const [theme, setTheme] = useState(localStorage.getItem('theme'));
    return (
        <>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    className={(theme == 'Dark') ? 'dark-modal modal-popup' : 'light-modal modal-popup'}
                >
                    <Box className="tabs-bg preview-popup show-compliance" sx={{ bgcolor: 'background.paper' }} >
                        <Button onClick={handleClose} className='cross-right-side'>
                            <CloseIcon />
                        </Button>
                        <Stack sx={{ margin: "10px 20px", fontFamily: 'Poppins' }}>
                            <Grid container spacing={2} >
                                <Grid xs={6} sx={{ margin: "auto" }}>

                                    <img className='compliance-logo'
                                        src={`${process.env.REACT_APP_BACKEND_BASE_URL}${contentData.complianceType == 'Others' ? process.env.REACT_APP_STATIC_PATH_COMPLIANCE_CARD_LOGO : process.env.REACT_APP_STATIC_PATH_ASSETS}/${contentData?.logo}`
                                        } alt='icon' />
                                </Grid>
                                <Grid xs={6} sx={{ display: "flex", alignItems: "center" }}>
                                    <Link target='_blank' href={`${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_STATIC_PATH_COMPLIANCE_CARD_DOCUMENT}/${contentData?.document}`}>
                                        <Button
                                            size="small"
                                            variant="contained"
                                            color="primary"
                                            component="label"
                                            className="show-document-button"
                                        >
                                            Show Attached Document
                                        </Button>
                                    </Link>
                                </Grid>
                            </Grid>
                            <Box sx={{ marginLeft: "-15px" }}>
                                <Typography> {contentData?.name}</Typography>
                            </Box>
                            <Box sx={{ marginLeft: "-15px" }}>
                                <Box>{contentData?.description}</Box>
                            </Box>
                            <Grid container spacing={2} sx={{ marginTop: "10px" }}>
                                <Grid xs={6}>
                                    <Typography>Compliance Type</Typography>
                                    <Box>{contentData?.complianceType}</Box>
                                </Grid>
                                <Grid xs={6}>
                                    <Typography>Issuer Name</Typography>
                                    <Box>{contentData?.issuerName}</Box>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{ marginTop: "10px" }}>
                                <Grid xs={6}>
                                    <Typography>Issue Date</Typography>
                                    <Box>{contentData?.issueDate}</Box>
                                </Grid>
                                <Grid xs={6}>
                                    <Typography>Expiry Date</Typography>
                                    <Box>{contentData?.expiryDate}</Box>
                                </Grid>
                            </Grid>
                        </Stack>
                    </Box>
                </Modal>
        </>
    )
}

export default ShowCompliance;