import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import {
  Grid,
  Container,
  Typography,
  Card,
  CardHeader,
  Stack,
  Box,
  TextField,
  Button,
  Divider,
} from "@mui/material";
import "./css/questionnaire.css";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { Link } from 'react-router-dom';


function QuestionnaireInformation() {
  const [questions, setQuestions] = useState([""]); // State to manage the list of questions

  const addQuestion = () => {
    setQuestions([...questions, ""]); // Add a new empty question to the list
  };

  const removeQuestion = (index) => {
    const updatedQuestions = [...questions];
    updatedQuestions.splice(index, 1);
    setQuestions(updatedQuestions);
  };
  return (
    <>
      <Helmet>
        <title> Dashboard | Genesis Platform</title>
      </Helmet>
      <Typography variant="h4" sx={{ mb: 5 }}>
        <span className="heading-main">Questionnaire Information</span>
      </Typography>
      <form className='profile-form'>
      <Card className="padding-square">
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField name="title" placeholder="Title" sx={{ pb: 2 }} />
            <TextareaAutosize
                        fullWidth
                        aria-label="empty textarea"
                        placeholder="Discription"
                        minRows={4}
                        className="fullWidthTextarea"
              />
          </Grid>

           <Grid item xs={12} md={12}>
            <Divider sx={{ mb: 4, mt: 2 }}></Divider>
            {/* <TextField
            name="title"
            placeholder="Section name"
            sx={{ pb: 2 }}
              />
            <TextField
            name="title"
            placeholder="Question"
            sx={{ pb: 2 }}
            /> */}

            <div className="questionnaire-crud">
              <CardHeader title={"Add Section"} />
              <Divider sx={{ mb: 3, mt: 0 }}></Divider>
              <TextField name="title" placeholder="Section" sx={{ pb: 2 }} />
              <CardHeader sx={{ mb: 0, mt: 3 }} title={"Add Questions"} />
              <Divider sx={{ mb: 3, mt: 0 }}></Divider>
              {questions.map((question, index) => (
                <div key={index}>
                  <TextField
                    name="question"
                    placeholder="Question"
                    sx={{ pb: 2 }}
                    value={question}
                    onChange={(e) => {
                      const updatedQuestions = [...questions];
                      updatedQuestions[index] = e.target.value;
                      setQuestions(updatedQuestions);
                    }}
                  />
                <Button
                variant="contained"
                className="add-section"
                onClick={addQuestion}
                sx={{ mb: 3, mt: 3 }}
                >
                <AddIcon /> Add 
              </Button>
                  {questions.length > 1 && ( // Conditionally render remove button if there's more than one question
                    <Button
                      variant="contained"
                      className="add-section"
                      onClick={() => removeQuestion(index)}
                      sx={{ mb: 3, mt: 3 }}
                    >
                      <CloseIcon /> Remove
                    </Button>
                  )}
                </div>
               ))}
              </div>
              <Link to ="/dashboard/questionnaire-preview">
               <Button variant="contained" className="addnew export-btn">
                Next
              </Button>
            </Link>
          </Grid>
         </Grid>
       </Card>
      </form>
    </>
  );
}

export default QuestionnaireInformation;
