import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Grid,
  Link,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import UserProfile from "./../../assets/user-profile.svg";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import { LoadingButton } from "@mui/lab";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Papa from "papaparse";
import * as XLSX from "xlsx";

export function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const AddVendor = ({ props, stepper }) => {
  const addClientDetails = props.addClientDetails;
  const changeActiveComponent = props.changeActiveComponent;
  const submitData = props.submitData;
  const [formData, setFormData] = useState({
    ...props.client,
    vendorDomain: props.client?.vendorDomain || [],
  });
  const [dataSaved, setDataSaved] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [csvData, setCsvData] = useState([]);

  // useEffect(() => {
  //   const tab =
  //     formData?.vendorDomainName && formData?.vendorDomain?.length > 1 ? 1 : 0;
  //   setFormData((prev) => ({
  //     ...prev,
  //     selectedTab: tab,
  //   }));
  // }, [formData.vendorDomain]);

  const handleTabChange = (event, newValue) => {
    setDataSaved(false);
    setFormData((prev) => ({
      ...prev,
      vendorDomainName: "",
      vendorDomain: [],
      selectedTab: newValue,
      filename: "",
    }));
  };

  const handleInputChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      vendorDomainName: e.target.value,
    }));
  };

  const handleFileUpload = (event) => {
    const files = event.target.files;
    if (files.length === 0) {
      return;
    }

    const file = files[0];
    const fileName = file.name;
    const fileExtension = fileName.split(".").pop().toLowerCase();

    if (fileExtension !== "csv" && fileExtension !== "xlsx") {
      enqueueSnackbar("Only CSV and XLSX files are supported.", {
        variant: "warning",
      });
      return;
    }
    setFormData((prev) => ({
      ...prev,
      filename: fileName,
    }));
    if (fileExtension === "csv") {
      handleCSVFile(file);
    } else if (fileExtension === "xlsx") {
      handleXLSXFile(file);
    }
  };

  const handleCSVFile = (file) => {
    Papa.parse(file, {
      download: true,
      header: false,
      dynamicTyping: true,
      skipEmptyLines: true,
      complete: (results) => {
        const validRows = results.data.filter((row) => row.length === 1);
        const flattenedRows = validRows.map((row) => row[0]);

        const invalidDomains = flattenedRows
          .map((domain, index) => {
            return isValidDomain(domain) ? null : index;
          })
          .filter((index) => index !== null);

        if (invalidDomains.length > 0) {
          const firstInvalidIndex = invalidDomains[0];
          enqueueSnackbar(
            `Invalid domain at column: ${firstInvalidIndex + 1}`,
            { variant: "error" }
          );
        } else {
          setCsvData(flattenedRows);
          console.log(flattenedRows);
        }
      },
    });
  };

  const handleXLSXFile = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const rows = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      const validRows = rows.filter((row) => row.length === 1);
      const flattenedRows = validRows.map((row) => row[0]);

      const invalidDomains = flattenedRows
        .map((domain, index) => {
          return isValidDomain(domain) ? null : index;
        })
        .filter((index) => index !== null);

      if (invalidDomains.length > 0) {
        const firstInvalidIndex = invalidDomains[0];
        enqueueSnackbar(`Invalid domain at column: ${firstInvalidIndex + 1}`, {
          variant: "error",
        });
      } else {
        setCsvData(flattenedRows);
        console.log(flattenedRows);
      }
    };
    reader.readAsArrayBuffer(file);
  };

  const isValidDomain = (domain) => {
    const domainRegex =
      /^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/;
    return domainRegex.test(domain);
  };

  const checkValidation = () => {
    // if (formData?.vendorDomain?.length === 0) {
    //   enqueueSnackbar("Vendor Domain is required.", { variant: "warning" });
    //   setDataSaved(false);
    //   return false;
    // } else if (formData.vendorDomain.length > formData.numberOfVendors) {
    //   enqueueSnackbar(
    //     `Only ${formData.numberOfVendors} Vendors can be added.`,
    //     { variant: "warning" }
    //   );
    //   return false;
    // }
    return true;
  };

  const onSubmit = (e) => {
    if (checkValidation()) {
      addClientDetails(formData);
      submitData(formData);
      setFormData({
        selectedTab: 0,
      });
    }
  };

  const addVendorDomain = () => {
    const trimmedDomainName = formData?.vendorDomainName?.trim();
    if (trimmedDomainName !== "") {
      setFormData((prev) => ({
        ...prev,
        vendorDomain: [...new Set([...prev.vendorDomain, trimmedDomainName])],
      }));
    } else {
      enqueueSnackbar("Invalid input: Domain name cannot be empty.", {
        variant: "warning",
      });
    }
  };

  const onSave = () => {
    setDataSaved(true);
    if (
      formData?.selectedTab === 0 &&
      formData?.vendorDomainName.trim() !== ""
    ) {
      addVendorDomain();
    } else if (formData?.selectedTab === 1 && csvData.length > 0) {
      setFormData((prev) => ({
        ...prev,
        vendorDomain: csvData,
      }));
    }
  };

  return (
    <>
      <Grid container spacing={0.5} sx={{ mt: 2 }}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <div className="d-flex-company-info">
            <div className="d-flex-company-logo">
              <img
                src={
                  typeof formData.logo === "string"
                    ? `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_STATIC_PATH}${formData?.logo}`
                    : formData.logo
                    ? URL.createObjectURL(formData.logo)
                    : UserProfile
                }
                alt="login"
                style={{
                  boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.05)",
                }}
              />
            </div>
            <div className="align-peragraph-p">
              <h3>{formData.company}</h3>
              <p>
                <PermIdentityIcon />{" "}
                {`${formData.firstName} ${formData.lastName}`}
              </p>
            </div>
          </div>
        </Grid>
      </Grid>

      <Box className="mbtop-manage" sx={{ mt: 4 }}>
        <Typography variant="h4">
          <span className="heading-main">Add Vendors</span>
        </Typography>
      </Box>
      <div className="subscription-form">
        <Card sx={{ mt: 1, mb: 5, width: "600px" }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            {!formData?.clientId && stepper}
          </Stack>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
              <Box className="add-vendor-tabs">
                <Tabs
                  value={formData?.selectedTab}
                  onChange={handleTabChange}
                  aria-label="basic tabs example"
                >
                  <Tab label="Individual" {...a11yProps(0)} />
                  <Tab label="Bulk" {...a11yProps(1)} />
                </Tabs>
              </Box>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
                <CustomTabPanel value={formData?.selectedTab} index={0}>
                  <TextField
                    size="large"
                    placeholder="Enter Domain Here..."
                    name="vendorDomainName"
                    value={formData?.vendorDomainName}
                    onChange={handleInputChange}
                  />
                  <div>
                    <h4>
                      Please Enter the valid domain of an individual vendor to
                      connect with the Client. If you want to connect with
                      multiple vendors, click on "Bulk" to upload a CSV file.
                    </h4>
                  </div>
                </CustomTabPanel>

              <CustomTabPanel value={formData?.selectedTab} index={1}>
                <Box
                  // onDrop={handleDrop}
                  // onDragOver={handleDragOver}
                  sx={{
                    border: "2px dashed #ccc",
                    borderRadius: "8px",
                    padding: "20px",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                >
                  <CloudUploadIcon fontSize="large" />
                  <Typography variant="h6">
                    Please choose or drop a CSV file containing the list of
                    domains
                  </Typography>
                  <input
                    type="file"
                    accept=".csv, .xlsx"
                    onChange={handleFileUpload}
                    style={{ display: "none" }}
                    id="file-upload"
                  />
                  <label htmlFor="file-upload">
                    <Button variant="contained" component="span" sx={{ mt: 2 }}>
                      Choose File
                    </Button>
                  </label>
                  {formData?.filename && (
                    <Typography variant="body2" sx={{ mt: 2 }}>
                      {formData?.filename}
                    </Typography>
                  )}
                  <br />
                  <br />
                  <Link
                    href={`${process.env.REACT_APP_BACKEND_BASE_URL}/assets/demo-domains.xlsx`}
                    download="demo-domains.xlsx"
                    underline="none"
                  >
                    Download demo file
                  </Link>
                </Box>
              </CustomTabPanel>
            </Grid>
          </Grid>

          <div className="d-flex-next-btn">
            <LoadingButton
              size="medium"
              variant="contained"
              color="success"
              className="upload-new rightside"
              onClick={() => {
                addClientDetails(formData);
                changeActiveComponent(2);
              }}
            >
              Back
            </LoadingButton>
            {!dataSaved ? (
              <LoadingButton
                size="medium"
                variant="contained"
                color="success"
                className="upload-new rightside"
                onClick={onSave}
              >
                Save
              </LoadingButton>
            ) : (
              <LoadingButton
                size="medium"
                variant="contained"
                color="success"
                className="upload-new rightside"
                onClick={onSubmit}
                // onClick={()=>console.log(formData)}
              >
                Submit
              </LoadingButton>
            )}
          </div>
        </Card>
      </div>
    </>
  );
};

export default AddVendor;
