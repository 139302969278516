import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";


// @mui
import { LoadingButton } from "@mui/lab";
import {
  Alert, Box, Grid, IconButton,
  InputAdornment, Link,
  Stack, TextField
} from "@mui/material";
// components
import PostRequest from "../../../components/apiConnections/postRequest";
import Iconify from "../../../components/iconify";
import Loader from "../../../Loader";

// ----------------------------------------------------------------------

export default function RegistrationForm({ toggleTheme }) {
  const { enqueueSnackbar } = useSnackbar();
  const [theme, setTheme] = useState(localStorage.getItem('theme'));
  const navigate = useNavigate();
  const [showLoader, setshowLoader] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [inputValues, setInputValues] = useState({
    firstName: "",
    lastName: "",
    company: "",
    emailId: "",
    password: "",
    confirmPassword: ""

  });
  const [alertData, setAlertData] = useState({});

  const toggleModeButtonClick = () => {
    setTheme((theme === 'Dark') ? `Light` : `Dark`)
    toggleTheme();
  }

  useEffect(() => {
    if (alertData.showalert) {
      setTimeout(() => {
        setAlertData({});
      }, 2000);
    }
  }, [alertData]);



  // const checkValidation = () => {
  //   // firstName, lastName, emailId, password, confirmPassword
  //   //name validation
  //   let Nameregex = new RegExp(/^[A-Za-z]+$/);
  //   let Emailregex = new RegExp(
  //     /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
  //   );
  //   let Passregex = new RegExp(
  //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,32}$/
  //   );
  //   const name = inputValues.firstName;
  //   const email = inputValues.email;
  //   const password = inputValues.password;
  //   if (!name || !name.match(Nameregex) || parseInt(name.length) < 3 || isNaN(name) !== true) {
  //     setAlertData({
  //       alerttype: "error",
  //       showalert: true,
  //       message: "Please enter minimum 3 letter.",
  //     });
  //     return false;
  //   } 
  //   // email validation
  //   if(!email.match(Emailregex)) {
  //     setAlertData({
  //       alerttype: "error",
  //       showalert: true,
  //       message: "Email Id not valid.",
  //     });
  //     return false;
  //   } 
  //   //password validation
  //   if (password.length < 8) {
  //     setAlertData({
  //       alerttype: "error",
  //       showalert: true,
  //       message: "Password should be minimum 8 character.",
  //     });
  //     return false;  
  //   } else if (password.length > 32) {
  //     setAlertData({
  //       alerttype: "error",
  //       showalert: true,
  //       message: "Password should be maximum 32 character.",
  //     });
  //     return false;  
  //   } else if (!password.match(Passregex)) {
  //     setAlertData({
  //       alerttype: "error",
  //       showalert: true,
  //       message: "Password should be letter, words abd special character combinations..",
  //     });
  //     return false;
  //   }  
  // };


  const do_Register = async (e) => {
    e.preventDefault();
    setshowLoader(true);

    try {
      const { firstName, lastName, company, emailId, password, confirmPassword } = inputValues;
      if (firstName?.trim() === '' || firstName === undefined || lastName?.trim() === '' || lastName === undefined || company?.trim() === '') {
        enqueueSnackbar('Please fill all field.', { variant: 'error' });
        setshowLoader(false);
        return false;
      }
      // checkValidation();
      if (password === confirmPassword) {
        const payload = {
          firstName,
          lastName,
          company,
          emailId,
          password,
          confirmPassword,
        };
        const registerResult = await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REGISTRATION_ENDPOINT}`,
          payload
        );
        if (registerResult && registerResult.status === 200) {
          if (registerResult.data.code === 200) {
            enqueueSnackbar('Registration done successfully, for activation of account please check email inbox.', { variant: 'success' });
            setshowLoader(false);
            setTimeout(() => {
              navigate("/", { replace: true });
            }, 2000);

          }
          else {
            enqueueSnackbar(registerResult.data.message, { variant: 'error' });
            setshowLoader(false);
            return false;
          }
        } else {
          enqueueSnackbar(registerResult.data.message, { variant: 'error' });
          setshowLoader(false);
          return false;
        }
      } else {
        enqueueSnackbar('Password and confirm password not matched.', { variant: 'error' });
        setshowLoader(false);
        return false;
      }
    } catch (error) {
      enqueueSnackbar('Error in user Registration.', { variant: 'error' });
      setshowLoader(false);
      return false;
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  return (
    <>
      <Loader show={showLoader} />
      {alertData.showalert ? (
        <Alert sx={{ mb: 2 }} severity={alertData.alerttype}>
          {alertData.message}
        </Alert>
      ) : null}

      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch', },
        }}
        noValidate
        autoComplete="off"
        
      >
        <div>
          <form onSubmit={(e) => do_Register(e)} className="relative">
            {/* <Stack spacing={3}> */}
            <div className="inputfield">
              <TextField
                name="firstName"
                // label="First Name"
                onChange={handleChange}
                placeholder="First Name"
                defaultValue={inputValues.firstName}

              />
              <img src="/assets/user.svg" alt='user' />
            </div>
            <div className="inputfield">
              <TextField
                name="lastName"
                // label="Last Name"
                placeholder="Last Name"
                onChange={handleChange}
                defaultValue={inputValues.lastName}
              />
              <img src="/assets/user.svg" alt='user' />
            </div>
            <div className="inputfield">
              <TextField
                name="company"
                placeholder="Company Name"
                onChange={handleChange}
                defaultValue={inputValues.company}
              />
              <img src="/assets/company.svg" alt='user' />
            </div>
            <div className="inputfield">
              <TextField
                name="emailId"
                // label="Email address"
                placeholder="Email address"
                onChange={handleChange}
                defaultValue={inputValues.emailId}

              />
              <img src="/assets/email-icon.svg" alt='email' />
            </div>

            <div className="inputfield">
              <TextField
                name="password"
                // label="Password"
                placeholder="Password"
                type={showPassword ? "text" : "password"}
                onChange={handleChange}
                defaultValue={inputValues.password}

                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" className="eye-icon">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        <Iconify
                          icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <img src="/assets/password-check.svg" alt='password' />
            </div>

            <div className="inputfield">
              <TextField
                name="confirmPassword"
                // label="Confirm Password"
                placeholder="Confirm Password"
                type={showConfirmPassword ? "text" : "password"}
                onChange={handleChange}
                defaultValue={inputValues.confirmPassword}

                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" className="eye-icon">
                      <IconButton
                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                        edge="end"
                      >
                        <Iconify
                          icon={
                            showConfirmPassword
                              ? "eva:eye-fill"
                              : "eva:eye-off-fill"
                          }
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <img src="/assets/password-check.svg" alt='password' />
            </div>
            {/* </Stack> */}
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              sx={{ my: 2 }}
            >
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                onClick={(e) => do_Register(e)}
              >
                Sign Up
              </LoadingButton>
            </Stack>
            <Box>
              <Grid container sx={{ my: 2 }}>
                <Grid item xs className="already-have">
                  Already have an account?  <Link href="/" variant="body2"> Login here </Link>
                </Grid>
              </Grid>
            </Box>
            <FormControlLabel className="dark-toggle" control={<Switch checked={theme === "Dark"} onChange={() => toggleModeButtonClick()} />} label={`${theme} Mode `} style={{marginBottom: '25px'}} />
          </form>
        </div>
      </Box>
    </>
  );
}
