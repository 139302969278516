import React, { useState, useEffect } from 'react';
import "./css/addQuestionnaire.css";
import {
    Typography,
    FormControl,
    Box,
    TextField,
    Button,
    IconButton,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Select,
    MenuItem,
    Grid,
    Card,
    Modal,
    CardHeader
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import { Close as CloseIcon, ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import QuestionnairePreview from './QuestionnairePreview';
import Loader from '../Loader';
import { useSnackbar } from 'notistack';
import PostRequest from "../components/apiConnections/postRequest";
import GetRequest from '../components/apiConnections/getRequest';
import { Link, useNavigate } from 'react-router-dom';

const AddQuestionnaire = () => {
    const { enqueueSnackbar } = useSnackbar();
    let navigate = useNavigate();
    const [formData, setFormData] = useState({
        title: '',
        description: '',
        logo: null,
        sections: [{ title: "", questions: [] }],
    });
    const [showPreviewModal, setShowPreviewModal] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [isDragging, setIsDragging] = useState(false)
    const [mappingData, setMappingData] = useState({
        riskCategories: [],
        issueName: [],
        vulnerabilityType: [],
        iso27001Control: [],
        gdpr: [],
        nist: [],
        riskMapping: [],
        impactOnVendor: [],
        impactOnClient: []
    })
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [categoryLen, setCategoryLen] = useState(0);
    useEffect(() => {
        getMappingDataAPI()
    }, [])

    const getMappingDataAPI = async () => {
        setShowLoader(true);
        try {
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                {},
                "refreshToken"
            );
            if (refreshToken) {
                await GetRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_QUESTIONNAIRE_MAPPING_DATA}`,
                    {}
                )
                    .then((res) => {
                        let data = res.data.data;
                        setShowLoader(false);
                        setMappingData(data)
                    })
                    .catch((err) => {
                        enqueueSnackbar(err.message, { variant: 'error' });
                        setShowLoader(false);
                    })
            } else {
                setShowLoader(false);
            }
        }
        catch (error) {
            enqueueSnackbar(error.message, { variant: 'error' });
            setShowLoader(false);
        }
    }
    const handleInputChange = (field, value) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [field]: value,
        }));
    };

    const handleSectionTitleChange = (event, sectionIndex) => {
        const { value } = event.target;
        setSelectedCategory((prevSelectedItems) => {
            // Remove the previous selection for this section, if any
            const previousValue = formData.sections[sectionIndex].title;
            const updatedItems = prevSelectedItems.filter(item => item !== previousValue);
            return [...updatedItems, value];
        });
        // Update the form data with the selected title
        const updatedSections = [...formData.sections];
        updatedSections[sectionIndex].title = value;
        setFormData({ ...formData, sections: updatedSections });
    };

    const handleQuestionTextChange = (event, sectionIndex, questionIndex) => {
        const { value } = event.target;
        const updatedSections = [...formData.sections];
        updatedSections[sectionIndex].questions[questionIndex].text = value;
        setFormData({ ...formData, sections: updatedSections });
    };

    const addSection = () => {
        setFormData({
            ...formData,
            sections: [...formData.sections, { title: "", questions: [] }],
        });
        setCategoryLen(prevLen => prevLen + 1);
    };

    const removeSection = (sectionIndex) => {
        const removedTitle = formData.sections[sectionIndex].title;
        setSelectedCategory((prevSelectedItems) =>
            prevSelectedItems.filter((item) => item !== removedTitle)
        );
        const updatedSections = formData.sections.filter((_, index) => index !== sectionIndex);
        setFormData({ ...formData, sections: updatedSections });
        setCategoryLen(prevLen => prevLen - 1);
    };

    const addQuestion = (sectionIndex) => {
        const updatedSections = [...formData.sections];
        updatedSections[sectionIndex].questions.push({ text: "", options: [] });
        setFormData({ ...formData, sections: updatedSections });
    };

    const removeQuestion = (sectionIndex, questionIndex) => {
        const updatedSections = [...formData.sections];
        updatedSections[sectionIndex].questions.splice(questionIndex, 1);
        setFormData({ ...formData, sections: updatedSections });
    };

    const addOption = (sectionIndex, questionIndex) => {
        const updatedSections = [...formData.sections];
        updatedSections[sectionIndex].questions[questionIndex].options.push("");
        setFormData({ ...formData, sections: updatedSections });
    };

    const removeOption = (sectionIndex, questionIndex, optionIndex) => {
        const updatedSections = [...formData.sections];
        updatedSections[sectionIndex].questions[questionIndex].options.splice(optionIndex, 1);
        setFormData({ ...formData, sections: updatedSections });
    };

    const validateForm = () => {
        // Basic validation, you may want to enhance this based on your requirements
        if (!formData.title || formData.title.trim() === '') {
            enqueueSnackbar('Please fill title field', { variant: 'warning' });
            return false;
        } else if (!formData.description || formData.description.trim() === '') {
            enqueueSnackbar('Please fill description field', { variant: 'warning' });
            return false;
        } else if (formData.sections.length === 0) {
            enqueueSnackbar('Please add section', { variant: 'warning' });
            return false;
        }
        // else if (!formData.logo) {
        //     enqueueSnackbar('Please upload logo', { variant: 'warning' });
        //     return false;
        // }

        // Check if any category, question, option type, or option score is empty or contains only spaces
        for (const [index, section] of formData.sections?.entries()) {
            if (!section.title || section.title.trim() === '') {
                enqueueSnackbar(`Please select Category Name in section ${index + 1}.`, { variant: 'warning' });
                return false;
            }
            if (!section.questions?.length) {
                enqueueSnackbar(`Please add questions in section ${index + 1}`, { variant: 'warning' });
                return false;
            }
            for (const [i, question] of section.questions.entries()) {
                if (!question.text || question.text.trim() === '') {
                    enqueueSnackbar(`Please fill question text for question ${i+1} in section ${index + 1}`, { variant: 'warning' });
                    return false;
                }

                if (!question.type) {
                    enqueueSnackbar(`Please select question type for question ${i+1} in section ${index + 1}`, { variant: 'warning' });
                    return false;
                }
                if (!question.issueName) {
                    enqueueSnackbar(`Please select issue name for question ${i+1} in section ${index + 1}`, { variant: 'warning' });
                    return false;
                }
                if (!question.vulnerabilityType) {
                    enqueueSnackbar(`Please select vulnerability type for question ${i+1} in section ${index + 1}`, { variant: 'warning' });
                    return false;
                }
                if (!question.iso27001Control && !question.gdpr && !question.nist) {
                    enqueueSnackbar(`Please select at least one compliance for question ${i+1} in section ${index + 1}`, { variant: 'warning' });
                    return false;
                }
                if (!question.riskMapping) {
                    enqueueSnackbar(`Please select risk mapping for question ${i+1} in section ${index + 1}`, { variant: 'warning' });
                    return false;
                }
                if (!question.impactOnVendor) {
                    enqueueSnackbar(`Please select impact on vendor for question ${i+1} in section ${index + 1}`, { variant: 'warning' });
                    return false;
                }
                if (!question.impactOnClient) {
                    enqueueSnackbar(`Please select impact on client for question ${i+1} in section ${index + 1}`, { variant: 'warning' });
                    return false;
                }
                if (!question.options?.length) {
                    enqueueSnackbar(`Please add options for question ${i+1} in section ${index + 1}`, { variant: 'warning' });
                    return false;
                }
                if (question.options.some((option) => !option.title || option.title.trim() === '' || option.score < -1 || option.score == undefined)) {
                    enqueueSnackbar(`Please fill all option field with score for question ${i+1} in section ${index + 1}`, { variant: 'warning' });
                    return false;
                }
            }
        }
        return true;
    };


    const handleQuestionTypeChange = (event, sectionIndex, questionIndex) => {
        const { value } = event.target;
        const updatedSections = [...formData.sections];
        const defaultValue = 'MCQ'; // Set your desired default value here

        updatedSections[sectionIndex].questions[questionIndex].type = value !== '' ? value : defaultValue;
        setFormData({ ...formData, sections: updatedSections });
    };
    const handleQuestionPropertyChange = (event, sectionIndex, questionIndex,) => {
        const { value, name } = event.target;
        const updatedSections = [...formData.sections];
        updatedSections[sectionIndex].questions[questionIndex][name] = value || "";
        setFormData({ ...formData, sections: updatedSections });
    }
    const handleSelectChange = (event, sectionIndex, questionIndex, optionIndex) => {
        const { value } = event.target;
        const updatedSections = [...formData.sections];

        // Ensure that options is an array before trying to access its elements
        if (!Array.isArray(updatedSections[sectionIndex].questions[questionIndex].options)) {
            updatedSections[sectionIndex].questions[questionIndex].options = [];
        }
        let obj = updatedSections[sectionIndex].questions[questionIndex].options[optionIndex]
        updatedSections[sectionIndex].questions[questionIndex].options[optionIndex] = { ...obj, title: value };
        setFormData({ ...formData, sections: updatedSections });
    };

    const handleScoreChange = (event, sectionIndex, questionIndex, optionIndex) => {
        const { value } = event.target;
        const updatedSections = [...formData.sections];

        if (!Array.isArray(updatedSections[sectionIndex].questions[questionIndex].options)) {
            updatedSections[sectionIndex].questions[questionIndex].options = [];
        }

        if (typeof updatedSections[sectionIndex].questions[questionIndex].options[optionIndex] !== 'object') {
            updatedSections[sectionIndex].questions[questionIndex].options[optionIndex] = {};
        }

        const defaultValue = 0; // Set your desired default value here
        updatedSections[sectionIndex].questions[questionIndex].options[optionIndex].score = value !== '' ? value : defaultValue;
        setFormData({ ...formData, sections: updatedSections });
    };

    const handleNextClick = () => {
        if (validateForm()) {
            setShowPreviewModal(true);
        } else {
            // alert('Please fill in all required fields.');
        }
    };

    const handleCloseModal = () => {
        setShowPreviewModal(false);
    };

    const handleEditClick = () => {
        setShowPreviewModal(false);
    };

    const handleSubmit = async () => {
        if (validateForm()) {
            // Send data to the API in the specified format
            const categories = formData.sections.map((section) => ({
                category: section.title,
                questions: section.questions.map((question) => ({
                    text: question.text,
                    optionType: question.type,
                    issueName: question.issueName,
                    vulnerabilityType: question.vulnerabilityType,
                    iso27001Control: question.iso27001Control,
                    gdpr: question.gdpr,
                    nist: question.nist,
                    riskMapping: question.riskMapping,
                    impactOnVendor: question.impactOnVendor,
                    impactOnClient: question.impactOnClient,
                    options: question.options.map((option) => ({
                        title: option.title,
                        score: option.score,
                    })),
                })),
            }))

            let payload = new FormData();
            payload.append("set", formData.title)
            payload.append("description", formData.description)
            payload.append("categories", JSON.stringify(categories))
            payload.append("logo", formData.logo)
            payload.append("email", localStorage.getItem("userEmail"))

            // Call your API here with 'apiData'
            console.table('Data to be sent to API:', payload);

            setShowLoader(true);
            try {
                const refreshToken = await PostRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                    {},
                    {},
                    "refreshToken"
                );
                if (refreshToken) {
                    await PostRequest(
                        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ADD_USER_CUSTOM_QUESTIONNAIRE_SET}`,
                        payload
                    )
                        .then((resChart) => {
                            if (resChart.data.code === 201) {
                                enqueueSnackbar('Questionnaire set added successfully', { variant: 'success' });
                                setShowLoader(false);
                                setShowPreviewModal(false)
                                setFormData({
                                    title: '',
                                    description: '',
                                    sections: [{ title: "", questions: [] }],
                                });
                                setTimeout(() => {
                                    navigate("/dashboard/questionnaire-library")
                                }, 2000)
                            } else {
                                enqueueSnackbar(resChart.data?.message, { variant: 'success' });
                                setShowLoader(false);
                                setShowPreviewModal(false)
                            }

                        })
                        .catch((errChart) => {
                            enqueueSnackbar(errChart.message, { variant: 'error' });
                            setShowLoader(false);
                        })
                } else {
                    setShowLoader(false);
                }
            }
            catch (error) {
                enqueueSnackbar(error.message, { variant: 'error' });
                setShowLoader(false);
            }
        } else {
            alert('Please fill in all required fields.');
        }
    };

    const handleFileChange = (files) => {
        // setShowLoader(true);
        let file = files.files[0];
        if (!file) return null;
        const checkImage = file;
        const fileExtension = checkImage.name.replace(/^.*\./, "");
        if (
            // ["jpg", "png", "jpeg"].includes(fileExtension)
            checkImage.type?.includes("image")
        ) {
            setFormData({ ...formData, logo: checkImage });
            // setShowLoader(false);
        } else {
            enqueueSnackbar("Please upload image file only", { variant: "error" });
            // setShowLoader(false);
        }
    };



    const handleDrop = (event) => {
        event.preventDefault();
        setIsDragging(false);
        const droppedFile = event.dataTransfer;
        if (droppedFile.length > 0) {
            handleFileChange(droppedFile)
        } else {
            enqueueSnackbar("Please upload only one file", { variant: "error" });
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = () => {
        setIsDragging(false);
    };

    return (
        <>
            <Loader show={showLoader} />
            <Helmet>
                <title> Questionnaire | Genesis Platform</title>
            </Helmet>

            <Typography variant="h4" sx={{ mb: 5 }}>
                <span className='heading-main'>Questionnaire Library</span>
            </Typography>
            <Grid container spacing={2} pb={2} >
                <Grid item xs={12} md={12} lg={12}>
                    <Card className="padding-square">
                        <Link to={`../questionnaire-library`}>
                            <Button variant="contained" className="click-to-begin m-l-0 cancel-btn">Back</Button>
                        </Link>
                        <form action="" className='full-form'>
                            <Typography variant="h6" sx={{ mb: 2 }} className='title-label'>
                                Upload Logo
                            </Typography>
                            <Grid
                                item
                                xs={12}
                                md={12}
                                lg={12}
                                className="center-upload logo-preview"
                                onDrop={(e) => handleDrop(e)}
                                onDragOver={handleDragOver}
                                onDragLeave={handleDragLeave}
                            >

                                <Button
                                    htmlFor="logo-upload"
                                    size="small"
                                    variant="contained"
                                    color="primary"
                                    component="label"
                                    className={`upload-new-plus w-100 ${isDragging ? "drag-over" : ""
                                        }`}
                                    style={{ with: "100%" }}
                                    title="Select Image"
                                >
                                    {formData.logo ? (
                                        <div>
                                            <img
                                                className="preview-image"
                                                src={
                                                    typeof formData.logo == "string"
                                                        ? `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_THIRD_PARTY_QUESTIONNAIRE_LOGO}/${formData.logo}`
                                                        : URL.createObjectURL(formData.logo)
                                                }
                                                alt="icon"
                                            />
                                        </div>
                                    ) : (
                                        <>
                                            <span style={{ color: "#007bff" }}>Upload Logo</span>&nbsp;
                                            or Just Drag and Drop
                                        </>
                                    )}
                                </Button>
                                <input
                                    name="logo"
                                    id="logo-upload"
                                    type="file"
                                    accept="image/jpg, image/png, image/PNG, image/jpeg, image/JPG, image/JPEG"
                                    style={{ display: "none", with: "100%" }}
                                    onChange={(e) => { e.preventDefault(); handleFileChange(e.target) }}
                                />
                            </Grid>
                            <FormControl fullWidth sx={{ mb: 2 }}>
                                <Typography variant="h6" sx={{ mb: 2 }} className='title-label'>
                                    Title
                                </Typography>
                                <TextField
                                    variant="outlined"
                                    value={formData.title}
                                    onChange={(e) => handleInputChange('title', e.target.value)}
                                />
                            </FormControl>
                            <FormControl fullWidth sx={{ mb: 2 }}>
                                <Typography variant="h6" sx={{ mb: 2 }} className='title-label'>
                                    Description
                                </Typography>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    rows={4}
                                    value={formData.description}
                                    onChange={(e) => handleInputChange('description', e.target.value)}
                                    className='textarea-space'
                                />
                            </FormControl>
                            <div className='d-justifybetween'>
                            {categoryLen < mappingData.riskCategories.length-1 && <Button variant="contained" className='addsection' onClick={addSection} sx={{ mb: 2 }}>
                                    Add Section/Category
                                </Button>
                            }
                            </div>
                            {formData.sections.map((section, sectionIndex) => (
                                <div key={sectionIndex} className='accordion-part'>
                                    <Accordion sx={{ mb: 2 }}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls={`section${sectionIndex}-content`}
                                            id={`section${sectionIndex}-header`}
                                        >
                                            <Typography variant="h6" className='expand-section'>Section {sectionIndex + 1}</Typography>
                                            <IconButton onClick={() => removeSection(sectionIndex)} sx={{ ml: 'auto' }}>
                                                <CloseIcon />
                                            </IconButton>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <FormControl fullWidth sx={{ mb: 2 }}>
                                                <h4 variant="h6" sx={{ mb: 2 }} className='sub-categoryname'>
                                                    Section/Category Name
                                                </h4>
                                                <Select
                                                    value={section.title || ''}
                                                    onChange={(event) => handleSectionTitleChange(event, sectionIndex)}
                                                    displayEmpty  // This allows you to display an empty value
                                                    inputProps={{ 'aria-label': 'Select Category Type' }}
                                                >
                                                    <MenuItem value="" disabled>
                                                        Select Category Type
                                                    </MenuItem>
                                                    {/* {Array.isArray(mappingData.riskCategories) ? mappingData.riskCategories?.map((item) => {
                                                        return <MenuItem value={item}>{item}</MenuItem>
                                                    }) : null} */}
                                                    {Array.isArray(mappingData.riskCategories) ? mappingData.riskCategories
                                                        .filter(item => !selectedCategory.includes(item) || item === section.title).map((item) => (
                                                            <MenuItem key={item} value={item}>
                                                                {item}
                                                            </MenuItem>
                                                        )) : null}
                                                </Select>
                                            </FormControl>
                                            <Button variant="contained" className='addsection' sx={{ mb: 2 }} onClick={() => addQuestion(sectionIndex)}>
                                                Add Question
                                            </Button>
                                            {section.questions.map((question, questionIndex) => (
                                                <div key={questionIndex}>
                                                    <FormControl key={questionIndex} fullWidth sx={{ mb: 2 }}>
                                                        <Typography variant="h6" sx={{ mb: 2 }} >
                                                            <span className='section-headingpart'>{`Q ${questionIndex + 1}`}</span>
                                                        </Typography>
                                                        <Box display="flex" alignItems="center">
                                                            <TextField
                                                                variant="outlined"
                                                                fullWidth
                                                                value={question.text}
                                                                onChange={(e) => handleQuestionTextChange(e, sectionIndex, questionIndex)}
                                                            />
                                                            <Select
                                                                value={question.type || ''}
                                                                onChange={(event) => handleQuestionTypeChange(event, sectionIndex, questionIndex)}
                                                                sx={{ width: 100, ml: 2 }}
                                                                displayEmpty  // This allows you to display an empty value
                                                                inputProps={{ 'aria-label': 'Select Question Type' }}
                                                            >
                                                                <MenuItem value="" disabled>
                                                                    Select Question Type
                                                                </MenuItem>
                                                                <MenuItem value="MCQ">Multiple Choice Question</MenuItem>
                                                                <MenuItem value="SCQ">Single Choice Question</MenuItem>
                                                            </Select>
                                                            <IconButton onClick={() => removeQuestion(sectionIndex, questionIndex)}>
                                                                <CloseIcon />
                                                            </IconButton>
                                                        </Box>
                                                        <Grid container spacing={2} sx={{ marginTop: 0 }}>
                                                            <Grid item xs={4} md={4}>
                                                                <TextField
                                                                    select
                                                                    fullWidth
                                                                    name='issueName'
                                                                    label="Issue Name"
                                                                    value={question.issueName || ''}
                                                                    onChange={(event) => handleQuestionPropertyChange(event, sectionIndex, questionIndex)}
                                                                    inputProps={{ 'aria-label': 'Select Issue Name' }}
                                                                    InputLabelProps={{
                                                                        shrink: Boolean(question.issueName),
                                                                      }}
                                                                >
                                                                    <MenuItem value="" disabled>
                                                                        Select Issue Name
                                                                    </MenuItem>
                                                                    {Array.isArray(mappingData.issueName) ? mappingData.issueName.map((item, index) => (
                                                                        <MenuItem key={index} value={item}>
                                                                            {item}
                                                                        </MenuItem>
                                                                    )) : null}
                                                                </TextField>
                                                            </Grid>
                                                            <Grid item xs={4} md={4}>
                                                                <TextField
                                                                    select
                                                                    fullWidth
                                                                    name='vulnerabilityType'
                                                                    label="Vulnerability Type"
                                                                    value={question.vulnerabilityType || ''}
                                                                    onChange={(event) => handleQuestionPropertyChange(event, sectionIndex, questionIndex)}
                                                                    inputProps={{ 'aria-label': 'Select Vulnerability Type' }}
                                                                    InputLabelProps={{
                                                                        shrink: Boolean(question.vulnerabilityType),
                                                                      }}
                                                                >
                                                                    <MenuItem value="" disabled>
                                                                        Select Vulnerability Type
                                                                    </MenuItem>
                                                                    {Array.isArray(mappingData.vulnerabilityType) ? mappingData.vulnerabilityType.map((item, index) => (
                                                                        <MenuItem key={index} value={item}>
                                                                            {item}
                                                                        </MenuItem>
                                                                    )) : null}
                                                                </TextField>
                                                            </Grid>
                                                            <Grid item xs={4} md={4}>
                                                                <TextField
                                                                    select
                                                                    fullWidth
                                                                    name='iso27001Control'
                                                                    label="ISO 27001 Control"
                                                                    value={question.iso27001Control || ''}
                                                                    onChange={(event) => handleQuestionPropertyChange(event, sectionIndex, questionIndex)}
                                                                    inputProps={{ 'aria-label': 'Select ISO 27001 Control' }}
                                                                    InputLabelProps={{
                                                                        shrink: Boolean(question.iso27001Control),
                                                                      }}
                                                                >
                                                                    <MenuItem value="" >
                                                                        Select ISO 27001 Control
                                                                    </MenuItem>
                                                                    {Array.isArray(mappingData.iso27001Control) ? mappingData.iso27001Control.map((item, index) => (
                                                                        <MenuItem key={index} value={item}>
                                                                            {item}
                                                                        </MenuItem>
                                                                    )) : null}
                                                                </TextField>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container spacing={2} sx={{ marginTop: 0 }}>
                                                            <Grid item xs={4} md={4}>
                                                                <TextField
                                                                    select
                                                                    fullWidth
                                                                    name='gdpr'
                                                                    label="GDPR"
                                                                    value={question.gdpr || ''}
                                                                    onChange={(event) => handleQuestionPropertyChange(event, sectionIndex, questionIndex)}
                                                                    inputProps={{ 'aria-label': 'Select GDPR' }}
                                                                    InputLabelProps={{
                                                                        shrink: Boolean(question.gdpr),
                                                                      }} 
                                                                >
                                                                    <MenuItem value="" >
                                                                        Select GDPR
                                                                    </MenuItem>
                                                                    {Array.isArray(mappingData.gdpr) ? mappingData.gdpr.map((item, index) => (
                                                                        <MenuItem key={index} value={item}>
                                                                            {item}
                                                                        </MenuItem>
                                                                    )) : null}
                                                                </TextField>
                                                            </Grid>
                                                            <Grid item xs={4} md={4}>
                                                                <TextField
                                                                    select
                                                                    fullWidth
                                                                    name='nist'
                                                                    label="NIST"
                                                                    value={question.nist || ''}
                                                                    onChange={(event) => handleQuestionPropertyChange(event, sectionIndex, questionIndex)}
                                                                    inputProps={{ 'aria-label': 'Select NIST' }}
                                                                    InputLabelProps={{
                                                                        shrink: Boolean(question.nist),
                                                                      }}
                                                                >
                                                                    <MenuItem value="" >
                                                                        Select NIST
                                                                    </MenuItem>
                                                                    {Array.isArray(mappingData.nist) ? mappingData.nist.map((item, index) => (
                                                                        <MenuItem key={index} value={item}>
                                                                            {item}
                                                                        </MenuItem>
                                                                    )) : null}
                                                                </TextField>
                                                            </Grid>
                                                            <Grid item xs={4} md={4}>
                                                                <TextField
                                                                    select
                                                                    fullWidth
                                                                    name='riskMapping'
                                                                    label="Risk Mapping"
                                                                    value={question.riskMapping || ''}
                                                                    onChange={(event) => handleQuestionPropertyChange(event, sectionIndex, questionIndex)}
                                                                    inputProps={{ 'aria-label': 'Select Risk Mapping' }}
                                                                    InputLabelProps={{
                                                                        shrink: Boolean(question.riskMapping),
                                                                      }}
                                                                >
                                                                    <MenuItem value="" disabled>
                                                                        Select Risk Mapping
                                                                    </MenuItem>
                                                                    {Array.isArray(mappingData.riskMapping) ? mappingData.riskMapping.map((item, index) => (
                                                                        <MenuItem key={index} value={item}>
                                                                            {item}
                                                                        </MenuItem>
                                                                    )) : null}
                                                                </TextField>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container spacing={2} sx={{ marginTop: 0 }}>
                                                            <Grid item xs={6} md={6}>
                                                                <TextField
                                                                    select
                                                                    fullWidth
                                                                    multiple
                                                                    name='impactOnVendor'
                                                                    label="Impact on Vendor"
                                                                    value={question.impactOnVendor || []}
                                                                    onChange={(event) => handleQuestionPropertyChange(event, sectionIndex, questionIndex)}
                                                                    inputProps={{ 'aria-label': 'Select Impact on Vendor' }}
                                                                    SelectProps={{
                                                                        multiple: true,
                                                                        renderValue: (selected) => selected.join(', '), // This controls how the selected values are displayed
                                                                    }}
                                                                    InputLabelProps={{
                                                                        shrink: Boolean(question.impactOnVendor),
                                                                      }}
                                                                >
                                                                    <MenuItem value="" disabled>
                                                                        Select Impact on Vendor
                                                                    </MenuItem>
                                                                    {Array.isArray(mappingData.impactOnVendor) ? mappingData.impactOnVendor.map((item, index) => (
                                                                        <MenuItem key={index} value={item}>
                                                                            {item}
                                                                        </MenuItem>
                                                                    )) : null}
                                                                </TextField>
                                                            </Grid>
                                                            <Grid item xs={6} md={6}>
                                                                <TextField
                                                                    select
                                                                    fullWidth
                                                                    multiple
                                                                    name='impactOnClient'
                                                                    label="Impact on Client"
                                                                    value={question.impactOnClient || []}
                                                                    onChange={(event) => handleQuestionPropertyChange(event, sectionIndex, questionIndex)}
                                                                    inputProps={{ 'aria-label': 'Select Impact on Client' }}
                                                                    SelectProps={{
                                                                        multiple: true,
                                                                        renderValue: (selected) => selected.join(', '), // Customizes how the selected values are displayed
                                                                    }}
                                                                    InputLabelProps={{
                                                                        shrink: Boolean(question.impactOnClient),
                                                                      }}
                                                                >
                                                                    <MenuItem value="" disabled>
                                                                        Select Impact on Client
                                                                    </MenuItem>
                                                                    {Array.isArray(mappingData.impactOnClient) ? mappingData.impactOnClient.map((item, index) => (
                                                                        <MenuItem key={index} value={item}>
                                                                            {item}
                                                                        </MenuItem>
                                                                    )) : null}
                                                                </TextField>
                                                            </Grid>
                                                        </Grid>

                                                        {question.options.map((option, optionIndex) => (
                                                            <FormControl key={optionIndex} fullWidth sx={{ mb: 2 }}>
                                                                <Typography variant="h6" sx={{ mb: 2 }}>
                                                                    <span className='section-headingpart'>{`Option ${optionIndex + 1}`}</span>
                                                                </Typography>
                                                                <Box display="flex" alignItems="center">
                                                                    <TextField
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        value={option.title}
                                                                        required
                                                                        onChange={(e) => handleSelectChange(e, sectionIndex, questionIndex, optionIndex)}
                                                                    />
                                                                    <Select
                                                                        value={option.score > -1 ? option.score : ''}
                                                                        onChange={(event) => handleScoreChange(event, sectionIndex, questionIndex, optionIndex)}
                                                                        sx={{ width: 100, ml: 2 }}
                                                                        displayEmpty  // This allows you to display an empty value
                                                                        inputProps={{ 'aria-label': 'Select Score' }}
                                                                    >
                                                                        <MenuItem value="" disabled>
                                                                            Select Score
                                                                        </MenuItem>
                                                                        {Array.from({ length: 6 }, (_, i) => (
                                                                            <MenuItem key={i} value={i}>
                                                                                {i}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Select>

                                                                    <IconButton onClick={() => removeOption(sectionIndex, questionIndex, optionIndex)}>
                                                                        <CloseIcon />
                                                                    </IconButton>
                                                                </Box>
                                                            </FormControl>
                                                        ))}
                                                    </FormControl>
                                                    <Button variant="contained" className='addsection' onClick={() => addOption(sectionIndex, questionIndex)}>
                                                        Add Option
                                                    </Button>
                                                </div>
                                            ))}
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            ))}
                            <Button variant="contained" onClick={handleNextClick} sx={{ mt: 2 }} className="next-button min-h40">
                                Next
                            </Button>
                            <Modal
                                open={showPreviewModal}
                                onClose={handleCloseModal}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <QuestionnairePreview
                                    open={showPreviewModal}
                                    handleClose={() => setShowPreviewModal(false)}
                                    onClose={handleEditClick}
                                    submit={handleSubmit}
                                    description={formData.description}
                                    sections={formData.sections}
                                    showSubmit={true}
                                />
                            </Modal>
                        </form>
                    </Card>
                </Grid>
            </Grid>
        </>
    );
}

export default AddQuestionnaire;
