import React from 'react';
import { Grid, Tooltip } from "@mui/material";

export default function QuestionCategoryInfo({
    data,
    marginLeftTrue,
    marginLeftTrue2
}) {
    return (
        <Grid className='flex-preview-tab' container spacing={1} sx={{ padding: 2, marginTop: -2, marginBottom: -2, ...(marginLeftTrue && { marginLeft: 9 }), ...(marginLeftTrue2 && { marginLeft: 5 }) }}>
            <div style={{ display: "flex", width: "87%", textAlign: "center" }}>
                {data?.gdpr &&
                    <Tooltip title='Compliance/GDPR' placement="top" followCursor>
                        <div className='question-info'>
                            {data?.gdpr || "--"}
                        </div>
                    </Tooltip>
                }
                {data?.iso27001Control &&
                    <Tooltip title='Compliance/ISO 27001 Control' placement="top" followCursor>
                        <div className='question-info'>
                            {data?.iso27001Control || "--"}
                        </div>
                    </Tooltip>
                }
                {data?.issueName &&
                    <Tooltip title='Issue Name' placement="top" followCursor>
                        <div className='question-info' >
                            {data?.issueName || "--"}
                        </div>
                    </Tooltip>
                }
                {data?.nist &&
                 <Tooltip title='Compliance/NIST' placement="top" followCursor>
                    <div className='question-info'>
                        {data?.nist || "--"}
                    </div>
                    </Tooltip>
                }
                {data?.riskMapping &&
                 <Tooltip title='Risk Mapping' placement="top" followCursor>
                    <div className='question-info'>
                        {data?.riskMapping || "--"}
                    </div>
                    </Tooltip>
                }
                {data?.vulnerabilityType &&
                 <Tooltip title='Vulnerability Type' placement="top" followCursor>
                    <div className='question-info'>
                        {data?.vulnerabilityType || "--"}
                    </div>
                    </Tooltip>
                }

            </div>

            <div style={{ display: "flex", width: "87%", textAlign: "center" }}>
                {data?.impactOnClient && data?.impactOnClient.length > 0 &&
                 <Tooltip title='Impact on Client' followCursor>
                    <div className='question-info-2' style={{ display: "flex", flexWrap: "wrap" }}>
                        {data.impactOnClient.map((item, i) => (
                            <div key={i} style={{ paddingRight: '1%' }}>
                                {item || "--"}
                                {i < data.impactOnClient.length - 1 && ',  '}
                            </div>
                        ))}
                    </div>
                    </Tooltip>
                }
                {data?.impactOnVendor && data?.impactOnVendor.length > 0 &&
                 <Tooltip title='Impact on Vendor' followCursor>
                        <div className='question-info-2' style={{ display: "flex", flexWrap: "wrap" }}>
                        {data.impactOnVendor.map((item, i) => (
                            <div key={i} style={{ paddingRight: '1%' }}>
                                {item || "--"}
                                {i < data.impactOnVendor.length - 1 && ',  '}
                            </div>
                        ))}
                    </div>
                    </Tooltip>

                }
            </div>
        </Grid >
    );
}
