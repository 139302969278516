import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { useSnackbar } from 'notistack';
// @mui
import { LoadingButton } from "@mui/lab";
import {
  Box, CircularProgress, Grid, IconButton,
  InputAdornment, Link,
  Stack, TextField
} from "@mui/material";
// components
import PostRequest from "../../../components/apiConnections/postRequest";
import Iconify from "../../../components/iconify";
import Loader from "../../../Loader";
// ----------------------------------------------------------------------
import { setLocalStoarge } from "../../../components/common/allFunctions";
import { useState } from 'react';


export default function LoginForm({ toggleTheme }) {
  const { enqueueSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const [inputValues, setInputValues] = useState({});
  const [theme, setTheme] = useState(localStorage.getItem('theme') || "Dark");
  const [showLoader, setshowLoader] = useState(false);

  const toggleModeButtonClick = () => {
    setTheme((theme === 'Dark') ? `Light` : `Dark`)
    toggleTheme();
  }
  const do_Login = async (e) => {
    e.preventDefault();
    setshowLoader(true);

    try {
      const { email, password } = inputValues;
      let Emailregex = new RegExp(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      );
      if (
        email === "" ||
        password === "" ||
        email === undefined ||
        password === undefined
      ) {
        enqueueSnackbar('Email Id or Password required.', { variant: 'error' });
        setshowLoader(false);
        return false;
      }

      if (!email.match(Emailregex)) {
        enqueueSnackbar('Email Id not valid.', { variant: 'error' });
        setshowLoader(false);
        return false;
      }
      const payload = {
        username: email,
        password: password,
      };

      const loginResult = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_LOGIN_ENDPOINT}`,
        payload
      );

      if (loginResult && loginResult.status === 200) {
        if (loginResult.data.code === 200) {
          console.log(loginResult.data.data,"loginResult.data.data")
          if (loginResult.data.data.twofa == 0) {
            // twofa not enable
            const { accessToken, userTime, refreshToken, twofa, roleId, profilePic, userStatus,userId } = loginResult.data.data;
            const logged_user = {
              accessToken,
              refreshToken,
              twofa,
              profilePic,
            };
            setLocalStoarge("usertime", userTime);
            setLocalStoarge("roleId", roleId);
            setLocalStoarge("userEmail", email);
            setLocalStoarge("userId", userId);

            if (roleId === 1) {
              setLocalStoarge("R1", JSON.stringify(logged_user));
            } else if (roleId === 2) {
              setLocalStoarge("R2", JSON.stringify(logged_user));
            } else if (roleId === 3) {
              setLocalStoarge("R3", JSON.stringify(logged_user));
            } else if (roleId === 4) {
              setLocalStoarge("R4", JSON.stringify(logged_user));
            }
            enqueueSnackbar('Logged in successfully.', { variant: 'success' });
            setshowLoader(false);
            if (roleId === 1) {
              window.location.href = "/dashboard/app";
            } else if (roleId === 2) {
              window.location.href = "/admin/addfile";
            } else if (roleId === 3) {
              if (userStatus === 1) {
                window.location.href = "/vendor/vendorAssessment";
              }
              else {
                window.location.href = "vendor/change-password";
              }
            } else if (roleId === 4) {
              window.location.href = "third-party/dashboard";
            }

          } else {
            //two fa enabled,
            setLocalStoarge("userEmail", email);
            enqueueSnackbar('Please verify with OTP.', { variant: 'success' });
            setshowLoader(false);
            // window.location.href = "/twoFa";
          }
        }
      }
      else {
        enqueueSnackbar('Unauthorized username or password.', { variant: 'error' });
        setshowLoader(false);
      }
    }
    catch (error) {
      enqueueSnackbar('Unauthorized username or password.', { variant: 'error' });
      setshowLoader(false);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  return (
    <>
      {/* <Loader show={showLoader} /> */}

      <form onSubmit={(e) => do_Login(e)} className="relative">
        <Stack spacing={3}>
          <div className="inputfield">
            <TextField
              name="email"
              placeholder="Email address"
              onChange={handleChange}
            />
            <img src="/assets/email-icon.svg" alt='logo' />
          </div>
          <div className="inputfield">
            <TextField
              name="password"
              // label="Password"
              placeholder="Password"
              className="inputfield"
              type={showPassword ? "text" : "password"}
              onChange={handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" className="eye-icon">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      <Iconify
                        icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <img src="/assets/password-check.svg" alt='logo' />
          </div>

        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ my: 2 }}
        >

        </Stack>
        <LoadingButton loading={showLoader} loadingIndicator={<CircularProgress size={12} sx={{ marginRight: "70px",color: "white" }} />} fullWidth size="large" type="submit" variant="contained" >
          <span>Login</span>
        </LoadingButton>
        <Box>
          <Grid className="flex-direction" container sx={{ my: 2 }} >
            <Grid item xs>
              <Link href="/forget-password" className="forgot-text" variant="body2">
                Forgot password?
              </Link>
            </Grid>
          </Grid>
        </Box>
        <FormControlLabel className="dark-toggle" control={<Switch checked={theme === "Dark"} onChange={() => toggleModeButtonClick()} />} label={`${theme} Mode `} />
      </form>
    </>
  );
}
