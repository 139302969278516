import React from "react";
import CircularProgressBarSimple from "./CircularProgressBarSimple";

export default function CircularProgressSimple({ percent, title }) {
  return (
    <>
      <div className="box-Security-control">
        <div className="progress-part">
          <CircularProgressBarSimple percent={percent} title={title} />
        </div>
      </div>
    </>
  );
}
