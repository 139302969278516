import React, { useEffect, useState } from "react";
// @mui
import { LoadingButton } from "@mui/lab";
import { Stack, Typography } from "@mui/material";
import OtpInput from "react-otp-input";
// components
import { useSnackbar } from 'notistack';
import fullLight from '../../../assets/full-light.png';
import PostRequest from "../../../components/apiConnections/postRequest";
import {
  getLocalStoarge,
  setLocalStoarge
} from "../../../components/common/allFunctions";
import Loader from "../../../Loader";


export default function TwoFaForm() {
  const { enqueueSnackbar } = useSnackbar();
  const [otp, setOtp] = useState();
  // const [alertData, setAlertData] = useState({});
  const [showLoader, setshowLoader] = useState(false);

  useEffect(() => {
    // if (alertData.showalert) {
    //   setTimeout(() => {
    //     setAlertData({});
    //   }, 2000);
    // }
  }, []);

  const handleError = () => {
    enqueueSnackbar('Invalid OTP.', { variant: 'error' });
    return false;
  };
  const catchError = (error) => {
    enqueueSnackbar('Something went wrong.', { variant: 'error' });
    return false;
  };
  const do_verify = async (e) => {
    e.preventDefault();
    setshowLoader(true);
    try {
      const username = getLocalStoarge("userEmail");
      const payload = {
        username,
        otp,
      };

      const twofaResult = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_TWOFA_ENDPOINT}`,
        payload
      );
      if (twofaResult && twofaResult.status === 200) {
        if (twofaResult.data.code === 200) {
          const { accessToken, userTime, refreshToken, twofa, roleId, profilePic, userStatus,userId } = twofaResult.data.data;
          const logged_user = {
            accessToken,
            refreshToken,
            twofa,
            profilePic
          };
          setLocalStoarge("usertime", userTime);
          setLocalStoarge("roleId", roleId);
          setLocalStoarge("userId",userId);
          // setLocalStoarge("userEmail", email);    

          if (roleId === 1) {
            setLocalStoarge("R1", JSON.stringify(logged_user));
          } else if (roleId === 2) {
            setLocalStoarge("R2", JSON.stringify(logged_user));
          } else if (roleId === 3) {
            setLocalStoarge("R3", JSON.stringify(logged_user));
          }
          enqueueSnackbar('Logged in successfully.', { variant: 'success' });
          setshowLoader(false);

          if (roleId === 1) {
            window.location.href = "/dashboard/app";
          } else if (roleId === 2) {
            window.location.href = "/admin/addfile";
          } else if (roleId === 3) {
            if (userStatus === 1) {
              window.location.href = "/vendor/vendorAssessment";
            }
            else {
              window.location.href = "vendor/change-password";
            }
          }
        } else {
          handleError();
        }
      } else {
        handleError();
      }


    } catch (error) {
      catchError(error);
    }
  };

  const handleChange = (otp) => setOtp(otp);
  return (
    <>
      <Loader show={showLoader} />
      <form onSubmit={(e) => do_verify(e)} className="fa-center">
        <Stack spacing={5}>
          <img src={fullLight} className="logo-size" alt="icon" />
          <Typography variant="h4" gutterBottom>
            <h2>2FA Verification</h2>
            <p>Enter verification code</p>
          </Typography>
          <OtpInput
            value={otp}
            onChange={handleChange}
            numInputs={6}
            separator={<span> - </span>}
            inputStyle={{ width: "60px", height: "60px" }}
            className="input-style"
          />
          <div className="center-alignbtn">
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              className="confirm-btn"
            >
              Confirm
            </LoadingButton>
          </div>
        </Stack>
      </form>
    </>
  );
}
