import CancelIcon from "@mui/icons-material/Cancel";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { Button, CircularProgress, Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import Linkify from "react-linkify";
import Attachment from "../../src/assets/attachment-icon.svg";
import SendPlane from "../../src/assets/send-plane-line.svg";
import GetRequest from "../components/apiConnections/getRequest";
import PostRequest from "../components/apiConnections/postRequest";
import Loader from "../Loader";
import { initializePusher } from "../utils/pusherService";
import "./css/chat.css";

function VendorChatBoard({ theme }) {
  const [auth, setAuth] = useState();
  const [imageErr, setImageErr] = useState(false);
  const [receiverId, setReceiverId] = useState(0);
  const [messages, setMessages] = useState([]);
  const [isSending, setIsSending] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isAttachment, setIsAttachment] = useState(false);
  const [attachmentType, setAttachmentType] = useState("");
  const [showLoader, setshowLoader] = useState(false);
  const [client, setClient] = useState([]);
  const [inpMessage, setInpMessage] = useState("");
  const [profilePicURL, setProfilePicURL] = useState("default.png");
  const { enqueueSnackbar } = useSnackbar();
  const [clientLogin, setClientLogin] = useState(false);
  const [attachmentCount, setAttachmentCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [selectedAttachmentPreview, setSelectedAttachmentPreview] =
    useState(null);
  const isClientEmpty = Object.keys(client).length === 0;
  const imgUrl =
    process.env.REACT_APP_BACKEND_BASE_URL + process.env.REACT_APP_STATIC_PATH;
  const lastMessageRef = useRef(null);
  const [filename, setFileName] = useState(null);

  // useEffect(() => {
  //   if (!isClientEmpty) {
  //     const firstChatItem = document.querySelector(".recentchat");
  //     if (firstChatItem) {
  //       firstChatItem.click();
  //     }
  //   }
  // }, [client]);

  useEffect(() => {
    lastMessageRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages, isSending, selectedAttachmentPreview]);


  useEffect(() => {
    const pusher = initializePusher();
    const channelName = "chat";
    const channel = pusher.subscribe(channelName);
    const handleNewMessage = (data) => {
      if (data.sender == receiverId && data.receiver === auth) {
        setMessages((prevMessages) => [...prevMessages, data]);
      }
    };

    channel.bind("new-message", handleNewMessage);

    return () => {
      channel.unbind("new-message", handleNewMessage);
    };
  }, [receiverId, auth]);

  // useEffect(() => {
  //   lastMessageRef.current?.scrollIntoView({ behavior: "smooth" });
  // }, [messages, isSending, selectedAttachmentPreview]);

  useEffect(() => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView(
        {
          behavior: 'smooth',
          block: 'end',
          inline: 'nearest'
        })
    }
  },[messages, isSending, selectedAttachmentPreview])

  useEffect(() => {
    setAuth(localStorage.getItem("userEmail"));
    getClient();
  }, []);

  const setReceiverIdBtn = (receiver) => {
    setReceiverId(receiver);
    getMessage(receiver);
    // lastMessageRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleInputMessage = (event) => {
    setInpMessage(event.target.value);
  };

  const getClient = async () => {
    setLoading(true);
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        GetRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_CLIENT_BY_VENDORS}`
        )
          .then((res) => {
            setClient(res.data?.data);
          })
          .catch((err) => {
            //  enqueueSnackbar(err.message, { variant : 'error' });
          })
          .finally(() => {
            setLoading(false);
          });
      }
    } catch (e) {
      // enqueueSnackbar("User profile not found", { variant : 'error' });
      setLoading(false);
    }
  };

  const getMessage = async (id) => {
    setshowLoader(true);
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        GetRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_MESSAGES}?receiver=${id}`
        )
          .then((res) => {
            setMessages(res.data?.data);
            setshowLoader(false);
          })
          .catch((err) => {
            setshowLoader(false);
          });
      }
    } catch (e) {
      setshowLoader(false);
    }
  };

  const sendMessageAPI = async (sender_id, inpMessage) => {
    setIsSending(true);
    const payload = {
      receiver: receiverId,
      message: inpMessage,
      time:
        new Date(Date.now()).getHours() +
        ":" +
        new Date(Date.now()).getMinutes(),
    };
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SEND_MESSAGES}`,
          payload
        )
          .then((res) => {
            setAttachmentCount(1);
            setIsDisabled(false);
            getMessage(receiverId);
          })
          .catch((err) => {
            console.error("Error in Post API call", err);
          })
          .finally(() => {
            setIsSending(false);
            setSelectedAttachmentPreview(null);
          });
      } else {
        console.log("Refresh token is not available");
        setSelectedAttachmentPreview(null);
        window.location.href = "/";
      }
    } catch (error) {
      console.error("General error", error);
      setIsSending(false);
      setSelectedAttachmentPreview(null);
    }
  };

  const sendMessageBtn = (e) => {
    e.preventDefault();
    if (attachmentCount > 0 && profilePicURL !== "default.png") {
      sendMessageAPI(auth, profilePicURL);
      setAttachmentType("");
    } else if (inpMessage.trim() !== "") {
      sendMessageAPI(auth, inpMessage);
      setAttachmentType("");
    }
    setInpMessage("");
    setProfilePicURL("default.png");
  };

  const handleAttachmentUpload = async (e) => {
    e.preventDefault();
    setFileName(null);
    setSelectedAttachmentPreview(null);
    setAttachmentCount(0);
    setIsDisabled(false);
    setAttachmentType(null);
    let files = e.target.files[0];
    if (!files || files.length === 0) {
      return;
    }
    setFileName(files.name);
    let dataImage = new FormData();
    dataImage.append("attachment", files);
    const fileExtension = files.name.replace(/^.*\./, "");
    const fileSize = Math.round(files.size / 1024);
    setAttachmentType(files.type);
    if (
      fileExtension === "png" ||
      fileExtension === "PNG" ||
      fileExtension === "jpg" ||
      fileExtension === "JPG" ||
      fileExtension === "JPEG" ||
      fileExtension === "jpeg" ||
      fileExtension === "pdf" ||
      fileExtension === "PDF"
    ) {
      if (fileSize <= 2048) {
        try {
          const refreshToken = await PostRequest(
            `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
            {},
            {},
            "refreshToken"
          );

          if (refreshToken) {
            const uploadPicture = await PostRequest(
              `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_CHAT_FILE_UPLOAD_ENDPOINT}`,
              dataImage,
              "ImageUpload"
            );
            if (uploadPicture && uploadPicture.status === 200) {
              if (uploadPicture.data.code === 200) {
                setSelectedAttachmentPreview(URL.createObjectURL(files));
                setAttachmentCount(1);
                setIsDisabled(true);
                setProfilePicURL(uploadPicture.data.data.filename);
              } else {
                console.log("Error in upload image.");
              }
            } else {
              console.log("Error in upload image.");
            }
          }
        } catch (e) {
          console.log("Error in upload image.");
        }
      } else {
        enqueueSnackbar("Please upload a file less than 2 MB.", {
          variant: "error",
        });
      }
    } else {
      enqueueSnackbar("Unsupported file format.", { variant: "error" });
    }
  };

  const getInitials = (first_name, last_name) => {
    const firstInitial = (first_name && first_name.charAt(0)) || "";
    const lastInitial = (last_name && last_name.charAt(0)) || "";
    return firstInitial.toUpperCase() + lastInitial.toUpperCase();
  };

  const getFormattedDateTime = (timeStamp) => {
    const dateTime = new Date(timeStamp).toLocaleString();
    return dateTime;
  };

  const handleImageError = (event) => {
    setImageErr(true);
    event.target.style.display = "none";
  };

  return (
    <>
      <Loader show={loading} />
      <Grid container spacing={0} className="heightfull-chat">
        <Grid item xs={12} md={3} lg={3}>
          <div className="left-profilepart">
            <div className="bottom-chat">
              <div className="scroll-chats">
                {isClientEmpty ? (
                  <div className="recentchat bg-recent-chat">
                    <p>No client Available Right Now</p>
                  </div>
                ) : (
                  <div
                    className={`recentchat bg-recent-chat  ${
                      receiverId === client.emailId ? "active" : ""
                    }`}
                    onClick={() => setReceiverIdBtn(client.emailId)}
                  >
                    <div className="flex-property-chat">
                      <span className="normal-text">
                        {!client.profilePic || imageErr ? (
                          <div className="user-badge-container">
                            <p className="chat-box-circle">
                              {" "}
                              {getInitials(
                                client.firstName,
                                client.lastName
                              )}{" "}
                            </p>
                          </div>
                        ) : (
                          <div className="user-badge-container">
                            <img
                              src={imgUrl + client.profilePic}
                              alt="profile"
                              onError={handleImageError}
                            />
                          </div>
                        )}
                      </span>
                      <p className="name-leftpart">
                        {client.firstName} {client.lastName}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Grid>
        {receiverId !== 0 ? (
          <Grid item xs={12} md={9} lg={9}>
            <div className="full-chat-screen">
              <div className="recentchat border-bottom topbar-user scroll-bar-chat">
                <div className="flex-property-chat">
                  {!client.profilePic || imageErr ? (
                    <p className="short-name mr-space">
                      {" "}
                      {getInitials(client.firstName, client.lastName)}{" "}
                    </p>
                  ) : (
                    <img
                      src={imgUrl + client.profilePic}
                      alt="profile"
                      onError={handleImageError}
                    />
                  )}
                  <strong className="self-name">
                    {client.firstName} {client.lastName} ( {client.emailId} ){" "}
                  </strong>
                </div>
              </div>
              <div className="scroll-chats scroll-chats-right">
                {messages && !showLoader ? (
                  messages.map((item, index) => {
                    const isLatestMessage = index === messages.length - 1;
                    const linkDecorator = (href, text, key) => (
                      <a
                        href={href}
                        key={key}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {text}
                      </a>
                    );
                    return (
                      <>
                        {item.sender !== auth ? (
                          <div className="chats-box ">
                            <img
                              src={imgUrl + client.profilePic}
                              alt="profile"
                              onError={handleImageError}
                            />
                            {!client.profilePic || imageErr ? (
                              <p className="short-name">
                                {" "}
                                {getInitials(
                                  client.firstName,
                                  client.lastName
                                )}{" "}
                              </p>
                            ) : (
                              ""
                            )}
                            <div className="textareas">
                              {item.message &&
                              (item.message.includes(".jpg") ||
                                item.message.includes(".jpeg") ||
                                item.message.includes(".png")) ? (
                                <>
                                  <a
                                    href={imgUrl + item.message}
                                    className="chat-attachment-click"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <img
                                      className="chat-attachment-image"
                                      src={imgUrl + item.message}
                                      alt="attachment"
                                    />
                                  </a>
                                </>
                              ) : item.message &&
                                /\.(pdf)$/i.test(item.message) ? (
                                <div className="chats-text">
                                  <a
                                    className="chat-attachment-click"
                                    href={imgUrl + item.message}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    
                                  >
                                    <PictureAsPdfIcon />
                                  </a>
                                </div>
                              ) : (
                                <>
                                  <div className="chats-text">
                                    <Linkify componentDecorator={linkDecorator}>
                                      {item.message}
                                    </Linkify>
                                  </div>
                                </>
                              )}
                              <div className="time-hour">
                                <span>
                                  {getFormattedDateTime(item.dateTime)}
                                </span>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="reply-chat">
                            {item.message &&
                            (item.message.includes(".jpg") ||
                              item.message.includes(".jpeg") ||
                              item.message.includes(".png")) ? (
                              <>
                                <a
                                  href={imgUrl + item.message}
                                  className="chat-attachment-click"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <img
                                    className="chat-attachment-image"
                                    src={imgUrl + item.message}
                                    alt="attachment"
                                  />
                                </a>
                              </>
                            ) : item.message &&
                              /\.(pdf)$/i.test(item.message) ? (
                              <div className="chats-text">
                                <a
                                  href={imgUrl + item.message}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <PictureAsPdfIcon />
                                </a>
                              </div>
                            ) : (
                              <>
                                <div className="response-reply">
                                  <Linkify componentDecorator={linkDecorator}>
                                    {item.message}
                                  </Linkify>
                                </div>
                              </>
                            )}
                            <div className="time-hour">
                              <span>{getFormattedDateTime(item.dateTime)}</span>
                            </div>
                            {isLatestMessage && isSending && (
                              <div className="response-reply pending">
                                <span className="blinking-dot"> </span>
                              </div>
                            )}
                          </div>
                        )}
                      </>
                    );
                  })
                ) : (
                  <div className="loader-container">
                    <CircularProgress size={50} />
                  </div>
                )}
                <div ref={lastMessageRef} />
              </div>
              <form
                className="form"
                onSubmit={(e) => {
                  e.preventDefault();
                  sendMessageBtn(e);
                }}
              >
                <div className="footer-chat">
                  {selectedAttachmentPreview && (
                    <div>
                      {(attachmentType === "image/png" ||
                      attachmentType === "image/jpg" ||
                      attachmentType === "image/jpeg" ) && (
                        <div
                          className={
                            theme === "Dark"
                              ? "attachment-preview dark"
                              : "attachment-preview light"
                          }
                        >
                          <div className="opacity-background"></div>
                          <img
                            src={selectedAttachmentPreview}
                            alt="attachment-preview"
                            className="preview-image"
                          />
                          <Button
                            variant="outlined"
                            className="btn-cross"
                            size="small"
                            color="secondary"
                            onClick={() => {
                              setSelectedAttachmentPreview(null);
                              setAttachmentCount(0);
                              setIsDisabled(false);
                              setAttachmentType(null);
                            }}
                          >
                            <CancelIcon />
                          </Button>
                        </div>
                      ) }
                      {attachmentType === "application/pdf" &&(
                        <div
                          className={
                            theme === "Dark"
                              ? "attachment-preview-pdf dark"
                              : "attachment-preview-pdf light"
                          }
                        >
                          <div className="opacity-background"></div>
                          <PictureAsPdfIcon sx={{ fontSize: 60 }} />
                          <p>{filename}</p>
                          <Button
                            variant="outlined"
                            className="btn-cross"
                            size="small"
                            color="secondary"
                            onClick={() => {
                              setSelectedAttachmentPreview(null);
                              setAttachmentCount(0);
                              setIsDisabled(false);
                              setAttachmentType(null);
                            }}
                          >
                            <CancelIcon />
                          </Button>
                        </div>
                      )}
                    </div>
                  )}

                  <div className="w-100full">
                    <input
                      type="text"
                      value={inpMessage}
                      onChange={handleInputMessage}
                      className={`write-message ${
                        isDisabled
                          ? "disabled-class disabled-placeholder"
                          : "enabled-class"
                      }`}
                      placeholder={
                        isDisabled
                          ? "Send Attachment"
                          : "Type your message here"
                      }
                      disabled={isDisabled}
                    />
                  </div>
                  <div>
                    <Button
                      htmlFor="file-upload"
                      size="small"
                      color="primary"
                      component="label"
                      className="upload-icon-chat"
                    >
                      <img
                        className="attach-icon"
                        src={Attachment}
                        alt="icon"
                      />
                    </Button>
                    <input
                      id="file-upload"
                      type="file"
                      name="file"
                      onChange={handleAttachmentUpload}
                      accept="image/*, application/pdf"
                      style={{ display: "none" }}
                      multiple={false}
                      onClick={(e) => {
                        e.target.value = null;
                      }}
                    />
                  </div>
                  <div>
                    <button
                      onClick={sendMessageBtn}
                      className={`plane-send ${isSending ? "disabled" : ""}`}
                      disabled={isSending}
                    >
                      {isAttachment ? (
                        <CircularProgress size={20} color="inherit" />
                      ) : (
                        <img src={SendPlane} alt="icon" />
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </Grid>
        ) : (
          <div className="set-welcome">
            <h2 className="heading-chect-middle">
              Welcome! <br />
              <span className="please-select-vendor">
                Please select your client
              </span>
            </h2>
          </div>
        )}
      </Grid>
    </>
  );
}

export default VendorChatBoard;
