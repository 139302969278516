import Pusher from "pusher-js";

let pusherInstance = null;

export const initializePusher = () => {
  if (!pusherInstance) {
    const pusherKey = process.env.REACT_APP_PUSHER_KEY;
    const pusherCluster = process.env.REACT_APP_PUSHER_CLUSTER;

    if (!pusherKey || !pusherCluster) {
      throw new Error("Pusher key or cluster not found in environment variables");
    }

    pusherInstance = new Pusher(pusherKey, {
      cluster: pusherCluster,
    });
    // console.log(`Pusher Key : ${process.env.REACT_APP_PUSHER_KEY}`)
  }

  return pusherInstance;
};

export const getPusherInstance = () => {
  if (!pusherInstance) {
    throw new Error(
      "Pusher instance not initialized. Call initializePusher first."
    );
  }

  return pusherInstance;
};