import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Modal, Box, Card, Grid, TextField, FormControl, Button,
  Checkbox, FormControlLabel, MenuItem, Select, InputLabel, Switch,
  Typography, Radio, RadioGroup,
  FormHelperText,
  CircularProgress
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@material-ui/icons/Delete';
import { useSnackbar } from 'notistack';
import '../addVendorByClient/addVendorByClient.css'
import PostRequest from '../apiConnections/postRequest';
import GetRequest from '../apiConnections/getRequest';
import { useNavigate } from 'react-router-dom';
// import MultipleValueTextInput from '../common/MultipleValueTextInput';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  overflowY: 'scroll',
  height: '90%'
};

export default function AddVendorByClient({ open, handleClose, domain, jsonAvailable }) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [errors, setErrors] = useState({});
  const [availableDocument, setAvailableDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    domain: domain,
    logo: '',
    name: '',
    industries: '',
    numberOfEmployees: '',
    linkedin: '',
    country: '',
    address: '',
    description: '',
    contactInformation: [{ firstName: '', lastName: '', email: '', mobile: '', isPrimary: false }],
    acceptableRisk: '',
    customTags: '',
    contractDeadline: '',
    // status: '',
    documents: [],
    dataSensitivity: [],
    accessLevel: [],
    dependencyLevel: [],
  });

  const dependencyLevelArr = [
    "Low (Easily Replaceable)",
    "Moderate (Some Impact if Replaced)",
    "High (Significant Impact if Replaced)",
    "Critical (Not Easily Replaceable)",
  ]
  useEffect(() => {
    getCompaniesDetails();
    getDocuments();
  }, [])

  const getCompaniesDetails = async () => {
    try {
      const token = "MS45gpP0";
      let logo = null;
      axios.get(`https://api.thecompaniesapi.com/v1/companies/${domain}?token=${token}`).then(res => {
        const data = res.data;
        logo = data.logo;
        if (!logo) {
          logo = `https://logo.clearbit.com/genesisplatform.co/hyundai.com/${domain}`;
        }
        if (data.domainName) {
          setFormData((prev) => ({
            ...prev,
            logo: logo || '',
            name: data.name || '',
            industries: data.industryMain || '',
            numberOfEmployees: data.totalEmployees || '',
            linkedin: data.socialNetworks?.linkedin || '',
            country: data.country?.name || '',
            description: data.description || '',
            address: data.city?.name || '',
          }));
        }
      }).catch(err => {
        console.log(err)
      })
    } catch (error) {
      console.error("Error fetching vendor data:", error);
    }
  }
  const getDocuments = async () => {
    // setShowLoader(true);
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await GetRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_DOCUMENT_TYPE}`,
          {}
        )
          .then((response) => {
            setAvailableDocuments(response.data.data);
          })
          .catch((err) => {
            enqueueSnackbar(err.message, { variant: 'error' });

          })
      } else {
        // setShowLoader(false);
      }
    }
    catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
      // setShowLoader(false);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
    setErrors((prev) => ({
      ...prev,
      [name]: ''
    }));

  };
  const handleContactChange = (index, e) => {
    const { name, value } = e.target;
    const contacts = [...formData.contactInformation];
    contacts[index][name] = value;
    setFormData((prev) => ({
      ...prev,
      contactInformation: contacts
    }));
    setErrors((prev) => ({
      ...prev,
      [`contact-${index}-${name}`]: ''
    }));
  };

  const handleAddContact = () => {
    setFormData((prev) => ({
      ...prev,
      contactInformation: [...prev.contactInformation, { firstName: '', lastName: '', email: '', mobile: '', isPrimary: false }]
    }));
  };
  const handleDeleteContact = (index) => {
    const updatedContacts = formData.contactInformation.filter((_, i) => i !== index);
    setFormData({ ...formData, contactInformation: updatedContacts });
  };
  const handleDeletedOC = (index) => {
    const updatedDOC = formData.documents.filter((_, i) => i !== index);
    setFormData({ ...formData, documents: updatedDOC });
  };
  const theme = localStorage.getItem("theme");

  const formSubmit = async (e) => {
    e.preventDefault();
  }

  const handleSave = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (validateFields()) {
      console.log(formData.documents)
      let payload = new FormData();
      const files = [];
      const documents = [];
      for (let item of formData.documents) {
        if (item.documentFile && item.isUpload) payload.append("documentFiles", item.documentFile)
        documents.push({
          documentTypeId: item.documentTypeId,
          documentType: item.documentType,
          isRequest: item.isRequest,
          isUpload: item.isUpload,
          documentName: item.documentFile ? item.documentFile?.name : null
        })
      }
      let getCustomTags = formData.customTags ? formData.customTags?.split(",") : [];
      let getIndustries = formData.industries ? formData.industries?.split(",") : [];

      payload.append("domain", domain)
      payload.append("clientEmail", localStorage.getItem("userEmail"))
      payload.append("name", formData.name)
      payload.append("logo", formData.logo || null)
      payload.append("industries", JSON.stringify(getIndustries))
      payload.append("numberOfEmployees", formData.numberOfEmployees)
      payload.append("linkedIn", formData.linkedin)
      payload.append("country", formData.country)
      payload.append("address", formData.address)
      payload.append("description", formData.description)
      payload.append("contactInformation", JSON.stringify(formData.contactInformation))
      payload.append("relationships", JSON.stringify({
        dataSensitivity: formData.dataSensitivity,
        accessLevel: formData.accessLevel,
        dependencyLevel: formData.dependencyLevel
      }))
      payload.append("acceptableRisk", formData.acceptableRisk)
      payload.append("customTags", JSON.stringify(getCustomTags))
      payload.append("contractDeadline", formData.contractDeadline)
      // payload.append("status", formData.status)
      payload.append("documents", JSON.stringify(documents))

      // Call your API here with 'apiData'
      console.table('Data to be sent to API:', payload);
      try {
        const refreshToken = await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
          {},
          {},
          "refreshToken"
        );
        if (refreshToken) {
          await PostRequest(
            `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ADD_VENDOR_DETAILS}`,
            payload
          )
            .then((resChart) => {
              console.log(resChart)
              enqueueSnackbar('Vendor added successfully', { variant: 'success' });
              // setShowLoader(false);
              // setFormData({
              //   title: '',
              //   description: '',
              //   sections: [{ title: "", questions: [] }],
              // });
              setTimeout(() => {
                handleClose()
                if (jsonAvailable) {
                  navigate(`/dashboard/vendorDetails/${domain}`);
                } else navigate(`/dashboard/vendors`);
              }, 2000)
              setLoading(false);
            })
            .catch((errChart) => {
              enqueueSnackbar(errChart.message, { variant: 'error' });
              setLoading(false)
            })
        } else {
          setLoading(false);
        }
      }
      catch (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
        setLoading(false);
      }
    } else {
      enqueueSnackbar("Please provide valid details", { variant: 'error' });
      setLoading(false);
    }
  };

  const getCurrentDate = () => {
    let currentDate = new Date();
    let year = currentDate.getFullYear();
    let month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    let day = currentDate.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  const handleCheckboxChange = (event, key) => {
    const { value, checked } = event.target;
    setFormData((prevData) => {
      const newValues = checked
        ? [...prevData[key], value]
        : prevData[key].filter((item) => item !== value);
      return {
        ...prevData,
        [key]: newValues,
      };
    });
  };

  const validateFields = () => {
    const newErrors = {};

    // if (!formData.bussinessName) newErrors.bussinessName = 'Business Name is required';
    // if (!formData.country) newErrors.country = 'Country is required';

    // if (!formData.status) newErrors.status = '*please select status';
    if (!formData.acceptableRisk) newErrors.acceptableRisk = '*please select Acceptable Risk';
    // Validate contacts
    formData.contactInformation.reduce((acc, contact, index) => {
      if (!contact.firstName) {
        acc[`contact-${index}-firstName`] = '*Contact First Name is required';
      }
      if (!contact.lastName) {
        acc[`contact-${index}-lastName`] = '*Contact Last Name is required';
      }
      if (!contact.email) {
        acc[`contact-${index}-email`] = '*Contact Email is required';
      }
      return acc;
    }, newErrors);


    // Validate documents
    formData.documents.forEach((document, index) => {
      if (!document.documentType) {
        newErrors[`document-${index}-type`] = '*Please select a document type';
      }
      if (document.isUpload && !document.documentFile) {
        newErrors[`document-${index}-file`] = '*Please upload a document file';
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const handlePrimaryChange = (index) => {
    const updatedContacts = formData.contactInformation.map((contact, i) => ({
      ...contact,
      isPrimary: i === index, // Only the selected contact will be primary
    }));

    setFormData((prev) => ({
      ...prev,
      contactInformation: updatedContacts,
    }));
  };


  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={
        theme === "Dark"
          ? "dark-attack-modal modal-popup"
          : "light-attack-modal modal-popup"
      }
    >
      <Box sx={style} className="black-bg add-vendor">
        <h3 className="heading-main mb-28" style={{ color: 'white' }}>Add New Vendor</h3>
        <Card sx={{ p: 2, m: 0, width: "100%" }} className="attackpart">
          <Box>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12}>
                <ClearIcon
                  className="cross-icon"
                  onClick={handleClose}
                />
                <FormControl sx={{ width: "100%" }}>
                  <form onSubmit={formSubmit}>
                    <Box>
                      <Grid container spacing={3}>
                        {/* Profile Information */}
                        <Grid item xs={12}>
                          <h3>Profile Information</h3>
                          <Box sx={{ mb: 2 }}>
                            <img
                              src={formData.logo}
                              alt="Company Logo"
                              style={{ width: 100, height: 100, objectFit: 'contain' }}
                            />
                          </Box>
                          <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                              <TextField
                                name="name"
                                label="Business Name"
                                value={formData.name}
                                onChange={handleChange}
                                onBlur={handleChange}
                                required
                                fullWidth
                                maxLength={50}
                                sx={{ mb: 2 }}
                                disabled
                              // error={!!errors.bussinessName}
                              // helperText={errors.bussinessName}
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <TextField
                                name="industries"
                                label="Industries"
                                value={formData.industries}
                                onChange={handleChange}

                                fullWidth
                                sx={{ mb: 2 }}
                                disabled
                              />
                            </Grid>
                          </Grid>
                          <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                              <TextField
                                name="numberOfEmployees"
                                label="Number of Employees"
                                value={formData.numberOfEmployees}
                                onChange={handleChange}

                                type="string"
                                fullWidth
                                sx={{ mb: 2 }}
                                disabled
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <TextField
                                name="linkedin"
                                label="LinkedIn Profile"
                                value={formData.linkedin}
                                onChange={handleChange}

                                fullWidth
                                sx={{ mb: 2 }}
                                disabled
                              />
                            </Grid>
                          </Grid>
                          <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                              <TextField
                                name="country"
                                label="Country"
                                value={formData.country}
                                onChange={handleChange}
                                onBlur={handleChange}
                                required
                                fullWidth
                                sx={{ mb: 2 }}
                                disabled
                              // error={!!errors.country}
                              // helperText={errors.country}
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <TextField
                                name="address"
                                label="Address"
                                value={formData.address}
                                onChange={handleChange}

                                fullWidth
                                maxLength={250}
                                sx={{ mb: 2 }}
                                disabled
                              />
                            </Grid>
                          </Grid>
                          <TextField
                            name="description"
                            label="Description"
                            value={formData.description}
                            onChange={handleChange}
                            fullWidth
                            maxLength={500}
                            multiline
                            rows={2}
                            sx={{
                              mb: 2,
                              '& .MuiInputBase-root': {
                                padding: '12px',
                                height: '100px',
                              },
                              '& .MuiInputBase-input': {
                                height: '75px !important',
                                padding: '10px',
                              }
                            }}
                            disabled
                          />
                        </Grid>

                        {/* Other Information */}
                        <Grid item xs={12}>
                          <h3>Other Information</h3>
                          <Grid container spacing={3}>
                            <Grid item xs={12} md={4}>
                              <FormControl fullWidth sx={{ mb: 2 }}>
                                <InputLabel id="acceptable-risk-label" shrink={formData.acceptableRisk !== ""}
                                  sx={{
                                    '&.Mui-focused': {
                                      color: document.documentType ? 'primary.main' : 'rgba(255, 255, 255, 0.7)',
                                    },
                                  }}>Select Acceptable Risk</InputLabel>
                                <Select
                                  labelId="acceptable-risk-label"
                                  id="acceptable-risk"
                                  name="acceptableRisk"
                                  value={formData.acceptableRisk}
                                  onChange={handleChange}
                                  onBlur={handleChange}
                                  required
                                  label="Acceptable Risk"
                                  error={!!errors.acceptableRisk}
                                  helperText={errors.acceptableRisk}
                                >
                                  <MenuItem value="" disabled>Acceptable Risk</MenuItem>
                                  <MenuItem value="0-25">0-25%</MenuItem>
                                  <MenuItem value="25-50">25-50%</MenuItem>
                                  <MenuItem value="50-75">50-75%</MenuItem>
                                  <MenuItem value="75-100">75-100%</MenuItem>
                                </Select>
                                {!!errors.acceptableRisk && (
                                  <FormHelperText sx={{ color: 'red' }}>{errors.acceptableRisk}</FormHelperText>
                                )}
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <TextField
                                name="contractDeadline"
                                InputLabelProps={{ shrink: true, required: true }}
                                type="date"
                                value={formData.contractDeadline || ""}
                                title="Select Date"
                                label="Contract Deadline"
                                inputProps={{ min: getCurrentDate() }}
                                error={!!errors.contractDeadline}
                                helperText={errors.contractDeadline}
                                onChange={(e) => setFormData({ ...formData, contractDeadline: e.target.value })}
                                sx={{
                                  '& input[type="date"]::-webkit-calendar-picker-indicator': {
                                    filter: 'invert(60%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(90%) contrast(90%)', // Custom gray color
                                  },
                                  '& input[type="date"]::-webkit-calendar-picker-indicator:hover': {
                                    filter: 'invert(60%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%)', // Slightly different on hover
                                  },
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <TextField
                                name="customTags"
                                label="Custom Tags"
                                value={formData.customTags}
                                onChange={handleChange}
                                fullWidth
                                sx={{ mb: 2 }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>

                        {/* Relation ship */}
                        <Grid className="rel-div" item xs={12}>
                          <h3 >Relationship</h3>
                          <Grid className='relation' container spacing={3}>
                            <Grid item xs={12} md={4} lg={4}>
                              <h3> Data Sensitivity</h3>
                              <p>What kind of data does the vendor hold?</p>
                              <FormControlLabel control={<Checkbox defaultChecked={formData.dataSensitivity.includes("Non-sensitive")} value="Non-sensitive" onChange={(e) => handleCheckboxChange(e, 'dataSensitivity')} />}
                                label="Non-sensitive" /><br />
                              <FormControlLabel control={<Checkbox defaultChecked={formData.dataSensitivity.includes("Internal business data")} value="Internal business data" onChange={(e) => handleCheckboxChange(e, 'dataSensitivity')} />}
                                label="Internal business data" /><br />
                              <FormControlLabel control={<Checkbox defaultChecked={formData.dataSensitivity.includes("Personal data (PII)")} value="Personal data (PII)" onChange={(e) => handleCheckboxChange(e, 'dataSensitivity')} />}
                                label="Personal data (PII)" /><br />
                              <FormControlLabel control={<Checkbox defaultChecked={formData.dataSensitivity.includes("Customer data")} value="Customer data" onChange={(e) => handleCheckboxChange(e, 'dataSensitivity')} />}
                                label="Customer data" /><br />
                              <FormControlLabel control={<Checkbox defaultChecked={formData.dataSensitivity.includes("Financial data")} value="Financial data" onChange={(e) => handleCheckboxChange(e, 'dataSensitivity')} />}
                                label="Financial data" /><br />
                              <FormControlLabel control={<Checkbox defaultChecked={formData.dataSensitivity.includes("Intellectual property")} value="Intellectual property" onChange={(e) => handleCheckboxChange(e, 'dataSensitivity')} />}
                                label="Intellectual property" /><br />
                              <FormControlLabel control={<Checkbox defaultChecked={formData.dataSensitivity.includes("Health data")} value="Health data" onChange={(e) => handleCheckboxChange(e, 'dataSensitivity')} />}
                                label="Health data" /><br />

                              <FormControlLabel control={<Checkbox defaultChecked={formData.dataSensitivity.includes("Legal data")} value="Legal data" onChange={(e) => handleCheckboxChange(e, 'dataSensitivity')} />}
                                label="Legal data" /><br />
                              <FormControlLabel control={<Checkbox defaultChecked={formData.dataSensitivity.includes("Confidential business plans")} value="Confidential business plans" onChange={(e) => handleCheckboxChange(e, 'dataSensitivity')} />}
                                label="Confidential business plans" /><br />
                            </Grid>

                            <Grid item xs={12} md={4} lg={4}>
                              <h3> Access level</h3>
                              <p>What kind of access does the vendor hold?</p>
                              <FormControlLabel control={<Checkbox defaultChecked={formData.accessLevel.includes("No access")} value="No access" onChange={(e) => handleCheckboxChange(e, 'accessLevel')} />}
                                label="No access" /><br />
                              <FormControlLabel control={<Checkbox defaultChecked={formData.accessLevel.includes("Limited access")} value="Limited access" onChange={(e) => handleCheckboxChange(e, 'accessLevel')} />}
                                label="Limited access" /><br />
                              <FormControlLabel control={<Checkbox defaultChecked={formData.accessLevel.includes("Read-Write access")} value="Read-Write access" onChange={(e) => handleCheckboxChange(e, 'accessLevel')} />}
                                label="Read-Write access" /><br />
                              <FormControlLabel control={<Checkbox defaultChecked={formData.accessLevel.includes("Operational access")} value="Operational access" onChange={(e) => handleCheckboxChange(e, 'accessLevel')} />}
                                label="Operational access" /><br />
                              <FormControlLabel control={<Checkbox defaultChecked={formData.accessLevel.includes("Full access")} value="Full access" onChange={(e) => handleCheckboxChange(e, 'accessLevel')} />}
                                label="Full access" /><br />
                            </Grid>

                            <Grid item xs={12} md={4} lg={4}>
                              <h3> Regulatory compliance</h3>
                              <p>Is the vendor compliant with compliance?</p>
                              {dependencyLevelArr.map((item) => (
                                <>
                                  <FormControlLabel control={<Checkbox defaultChecked={formData.dependencyLevel.includes(item)} value={item} onChange={(e) => handleCheckboxChange(e, 'dependencyLevel')} />}
                                    label={item} /><br />
                                </>
                              ))}
                            </Grid>
                          </Grid>
                        </Grid>
                        {/* Contact Information */}
                        <Grid item xs={12}>
                          <h3>Contact Information</h3>
                          {formData.contactInformation.map((contact, index) => (
                            <Box key={index}>
                              <Box display="flex" justifyContent="space-between" position="relative" alignItems="center" mb={1}>
                                <h4>Contact {index + 1}</h4>
                                {index > 0 && (
                                  <DeleteIcon className="cross-icon" onClick={() => handleDeleteContact(index)} />
                                )}
                              </Box>
                              <Grid container spacing={3}>
                                <Grid item xs={12} md={6}>
                                  <TextField
                                    name="firstName"
                                    label="First Name"
                                    value={contact.firstName}
                                    onChange={(e) => handleContactChange(index, e)}
                                    required
                                    maxLength={50}
                                    fullWidth
                                    sx={{ mb: 2 }}
                                    error={!!errors[`contact-${index}-firstName`]}
                                    helperText={errors[`contact-${index}-firstName`]}
                                  />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <TextField
                                    name="lastName"
                                    label="Last Name"
                                    value={contact.lastName}
                                    onChange={(e) => handleContactChange(index, e)}
                                    required
                                    maxLength={50}
                                    fullWidth
                                    sx={{ mb: 2 }}
                                    error={!!errors[`contact-${index}-lastName`]}  // dynamic error key
                                    helperText={errors[`contact-${index}-lastName`]}
                                  />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <TextField
                                    name="email"
                                    label="Email"
                                    value={contact.email}
                                    onChange={(e) => handleContactChange(index, e)}
                                    maxLength={30}
                                    required
                                    fullWidth
                                    sx={{ mb: 2 }}
                                    error={!!errors[`contact-${index}-email`]}  // dynamic error key
                                    helperText={errors[`contact-${index}-email`]}
                                  />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <TextField
                                    name="mobile"
                                    label="Mobile"
                                    value={contact.mobile}
                                    maxLength={15}
                                    onChange={(e) => handleContactChange(index, e)}
                                    fullWidth
                                    sx={{ mb: 2 }}
                                  />
                                </Grid>
                              </Grid>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name="isPrimary"
                                    checked={contact.isPrimary}
                                    onChange={(e) => handlePrimaryChange(index)}
                                  />
                                }
                                label="Primary Contact"
                              />
                              {index === formData.contactInformation.length - 1 && (
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  size="small"
                                  onClick={handleAddContact}
                                >
                                  Add Contact
                                </Button>
                              )}
                            </Box>
                          ))}
                        </Grid>

                        {/* Document upload */}
                        <Grid item xs={12}>
                          <h3>Document Information</h3>
                          {formData.documents.map((document, index) => {
                            // Get available document types by filtering out the selected ones
                            const availableDocumentTypes = availableDocument.filter((docType) =>
                              !formData.documents.some((doc, i) => doc.documentType === docType.name && i !== index)
                            );

                            return (
                              <Grid container spacing={2} key={index} alignItems="center">
                                <Grid item xs={12} md={4}>
                                  <FormControl fullWidth sx={{ mb: 2 }} error={!!errors[`document-${index}-type`]}>
                                    <InputLabel id={`doc-type-label-${index}`} shrink={document.documentType !== ""}
                                      sx={{
                                        '&.Mui-focused': {
                                          color: document.documentType ? 'primary.main' : 'rgba(255, 255, 255, 0.7)',
                                        },
                                      }}
                                    >Select Document Type</InputLabel>
                                    <Select
                                      labelId={`doc-type-label-${index}`}
                                      id={`doc-type-${index}`}
                                      name="documentType"
                                      value={document.documentType || ""}
                                      onChange={(e) => {
                                        const selectedDocumentType = availableDocument.find(docType => docType.name === e.target.value);
                                        const updatedDocuments = [...formData.documents];
                                        updatedDocuments[index].documentType = selectedDocumentType.name;
                                        updatedDocuments[index].documentTypeId = selectedDocumentType._id;
                                        setFormData((prev) => ({ ...prev, documents: updatedDocuments }));
                                      }}
                                      required
                                      label="Select Document Type"
                                      inputProps={{ 'aria-label': 'Select Document Type' }}
                                    >
                                      <MenuItem value="" disabled>Select Document Type</MenuItem>
                                      {availableDocumentTypes.map((docType) => (
                                        <MenuItem key={docType._id} value={docType.name}>
                                          {docType.name}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                    {!!errors[`document-${index}-type`] && (
                                      <FormHelperText>{errors[`document-${index}-type`]}</FormHelperText>
                                    )}
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} md={3} sx={{ display: 'flex', alignItems: 'center', fontSize: '5px !important' }}>
                                  <FormControl component="fieldset">
                                    <RadioGroup
                                      row
                                      name={`documentUploadRequest-${index}`}
                                      value={document.isUpload ? 'upload' : 'request'}
                                      onChange={(e) => {
                                        const updatedDocuments = [...formData.documents];
                                        updatedDocuments[index].isUpload = e.target.value === 'upload';
                                        setFormData((prev) => ({ ...prev, documents: updatedDocuments }));
                                      }}
                                    >
                                      <FormControlLabel
                                        value="upload"
                                        control={<Radio />}
                                        label="Upload Document"
                                      />
                                      <FormControlLabel
                                        value="request"
                                        control={<Radio />}
                                        label="Request Document"
                                      />
                                    </RadioGroup>
                                  </FormControl>
                                </Grid>
                                {document.isUpload && (
                                  <Grid item xs={12} md={4}>
                                    <TextField
                                      type="file"
                                      name="documentFile"
                                      onChange={(e) => {
                                        const updatedDocuments = [...formData.documents];
                                        updatedDocuments[index].documentFile = e.target.files[0];
                                        setFormData((prev) => ({ ...prev, documents: updatedDocuments }));
                                      }}
                                      fullWidth
                                      error={!!errors[`document-${index}-file`]}
                                      helperText={errors[`document-${index}-file`]}
                                    />
                                  </Grid>
                                )}
                                <Grid item xs={12} md={1}>
                                  {(
                                    <DeleteIcon className="delete-icon" onClick={() => handleDeletedOC(index)} />
                                  )}
                                </Grid>
                              </Grid>
                            );
                          })}

                          <Button
                            variant="outlined"
                            color="primary"
                            size="small"
                            onClick={() => {
                              const updatedDocuments = [...formData.documents, { documentType: '', isUpload: false, documentFile: null }];
                              setFormData((prev) => ({ ...prev, documents: updatedDocuments }));
                            }}
                            sx={{ mt: 2 }}
                          >
                            {formData.documents.length === 0 ? "Add Document" : "Add Another Document"}
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                    <Button
                      className="btn-right"
                      size="small"
                      variant="contained"
                      color="primary"
                      component="button"
                      type="submit"
                      onClick={handleSave}
                    >
                      Save
                      {loading ? (
                        <CircularProgress size={12} sx={{ marginLeft: "15px" }} />
                      ) : null}
                    </Button>
                  </form>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Box>
    </Modal>
  );
}
