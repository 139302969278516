import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { faker } from "@faker-js/faker";
import MailSend from "../assets/mailsend.svg";

// @mui
import { useTheme } from "@mui/material/styles";
import {
  Grid,
  Container,
  Typography,
  Card,
  CardHeader,
  Stack,
  Box,
  TextField,
  Alert,
  Button,
  IconButton,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Label from "../components/label";
import { sentenceCase } from "change-case";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PostRequest from "../components/apiConnections/postRequest";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Collapse from "@mui/material/Collapse";
import { SnackbarProvider, useSnackbar } from "notistack";
import CircularProgressBar from "./CircularProgressBar.js";
import CircularProgress from "./CircularProgress.js";
import moment from "moment";
// components
import Iconify from "../components/iconify";
import ModalDialog from "./ModalDialog.js";
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
  AppOverallScore,
  AppRiskScore,
} from "../sections/@dashboard/app";
import Loader from "../Loader";
import "./css/Vendors.css";
import { useDispatch } from "react-redux";
import { changeEcosystemTab } from "../redux/activeTabSlice";
// ----------------------------------------------------------------------
export default function SecurityControlPage() {
  const theme = useTheme();
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const location = useLocation()
  const data = useParams();
  const [open, setOpen] = React.useState(false);
  const [assessmentId, setAssessmentId] = useState(data.id);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { enqueueSnackbar } = useSnackbar();
  const [showLoader, setshowLoader] = useState(false);
  const [score, setScore] = useState(0);
  const userEmail = window.localStorage.getItem("userEmail");
  const [securityControl, setSecurityControl] = useState([]);


  useEffect(() => {
    getQuestionnaire();
  }, []);



  const ModalContent = () => {
    const style = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 600,
      bgcolor: "background.paper",
      boxShadow: 24,
      textAlign: "center",
      borderRadius: "15px",
      p: 4,
    };
    return (
      <Box sx={style}>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <img src={MailSend} alt="icon" />
          <h2 className="send-assessments">Send Assessment to the vendor?</h2>
        </Typography>
        <Grid item xs={12} md={12} lg={12} className="center-vendor">
          <Button
            variant="contained"
            className="click-to-begin m-l model-btns cancel-btn"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            className="click-to-begin model-btns"
            onClick={handleClose}
          >
            Send
          </Button>
        </Grid>
      </Box>
    );
  };

  const getQuestionnaire = async () => {
    setshowLoader(true);
    const payload = {
      assessment_id: assessmentId,
    };
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        const endpoint = process.env.REACT_APP_GET_QUESTIONNAIRE_BY_VENDOR;
        await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}`,
          payload
        )
          .then((res) => {
            let response = res.data.data;
            Object.entries(response).map((key, val) => {
              if (key[0] === "data") {
                let mainArr = [];
                Object.entries(key[1]).map((k, v) => {
                  let arrObj = {};
                  const lengthQues = k[1].question.length;
                  arrObj['category'] = k[1].category;
                  arrObj['questionsLength'] = lengthQues;
                  if (response.setType == "custom" || response.setType == "customAdmin") {
                    let count = 0;
                    let score = 0;
                    k[1].question.map((item, i) => {
                      if (item.que?.optionType == "MCQ") {
                        count = count + item.que?.options?.length * 5
                        item.que.options?.forEach((option, j) => {
                          if (item.ans.includes(`${j + 1}`)) {
                            score = score + option?.score
                          }
                        })
                      } else {
                        count = count + 5;
                        item.que?.options?.forEach((option, j) => {
                          if (item.ans == option.title) {
                            score = score + option?.score
                          }
                        })
                      }
                    })
                    arrObj['percent'] = ((score / count) * 100).toFixed(0);
                  } else {
                    let count = 0;
                    k[1].question.map((item, index) => {
                      if (item.ans === "Yes") {
                        count++;
                      }
                    })
                    arrObj['correctAns'] = count;
                    arrObj['percent'] = ((count / lengthQues) * 100).toFixed(0);
                  }
                  mainArr.push(arrObj);
                })
                setSecurityControl(mainArr);
              }
            })
            setshowLoader(false);
          })
          .catch((err) => {
            enqueueSnackbar("Details not find.", {
              variant: "error",
            });
            setshowLoader(false);
          });
      } else {
        window.location.href = "/";
      }
    } catch (error) {
      enqueueSnackbar("Details not find.", { variant: "error" });
      setshowLoader(false);
    }
  };



  return (
    <>
      <Helmet>
        <title> Security Control | Genesis Platform</title>
      </Helmet>
      <Container maxWidth={false}>
        <Typography variant="h4" sx={{ mb: 5 }}>
          <span className="heading-main">Security Control</span>
        </Typography>
        <Box>
          <div className="Security-control">
            <Grid style={{ marginBottom: "5px", textAlign: "right" }} sx={{ pb: 2 }}>
                <Button variant="contained" className="click-to-begin" onClick={()=>{dispatch(changeEcosystemTab("4"));navigate(`../${location?.state}`)}}>Back </Button>
            </Grid>
            <Grid container spacing={3}>
              {securityControl.map((item, index) => (
                <Grid item xs={12} md={4} lg={4} className="flex-align" key={index}>
                  <CircularProgress
                    percent={item.percent}
                    title={item.category}
                  />
                </Grid>
              ))}
            </Grid>
          </div>
        </Box>

        <ModalDialog
          handleClose={handleClose}
          open={open}
          contentData={ModalContent}
        />
      </Container>
    </>
  );
}
