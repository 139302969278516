import React, {useState, useEffect} from "react";
import "./css/VendorInformation.css";
import { Helmet } from "react-helmet-async";
import FormGroup from "@mui/material/FormGroup";
import PermContactCalendarOutlinedIcon from '@mui/icons-material/PermContactCalendarOutlined';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { useNavigate, useParams,Link } from "react-router-dom";
import PostRequest from "../components/apiConnections/postRequest";
import { getLocalStoarge } from "../components/common/allFunctions";

import {
  Grid,
  Container,
  TextField,
  Button  
} from "@mui/material";
import Loader from "../Loader";


export default function VendorEditPage() {
  const { enqueueSnackbar } = useSnackbar();
  const data = useParams();
  const navigate = useNavigate();
  const [showLoader, setshowLoader] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [updatedDate, setUpdatedDate] = useState('');
  const [userEmail, setUserEmail] = useState(getLocalStoarge("userEmail"));
  const [assessmentId, setAssessmentId] = useState(data.id);
  const [vendorUrl, setVendorUrl]= useState(data.url);
  const [complianceData, setComplianceData] = useState({});

  

  useEffect(()=>{
    getAssessment();    
  }, [])


  const getAssessment = async () => {
    setshowLoader(true);
    try {
      var rgx = /^[a-z|0-9]*\.?[a-z|0-9]*$/;
      const assessmentPayload = {
        createdBy: userEmail,
        url:vendorUrl
      };
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_ASSESSMENT_BY_USER}`,
          assessmentPayload
        )
          .then((resAssessment) => {
            const obj = resAssessment.data.data;
            setComplianceData(obj);
            Object.entries(obj).map((key, val)=>{
                if(key[1]._id === assessmentId)
                {
                    setFirstName(key[1].firstName);
                    setLastName(key[1].lastName);
                    setEmail(key[1].email);
                }
            })          
            setshowLoader(false);
          })
          .catch((err) => {
            // no data
            enqueueSnackbar("Assessment not find", { variant: "error" });
            setshowLoader(false);
            return false;
          });
      }
    } catch (error) {
      // no data
      enqueueSnackbar("Requested Url not find", { variant: "error" });
      setshowLoader(false);
      return false;
    }
  };




  const handleValid = (e) => {  
    const { name, value } = e.target;
    if (name === "firstName") {
      setFirstName(value);
    }
    if (name === "lastName") {
      setLastName(value);
    }
    if (name === "email") {
      setEmail(value);
    } 
    if (name === "updatedDate") {
        setUpdatedDate(value);
      }    
};  


  const do_submit = async (e) => {
    setshowLoader(true);
    e.preventDefault();
    if(firstName === '' || firstName === undefined || firstName === null || firstName.length < 3 || !(/^[A-Za-z\s]*$/.test(firstName)) )
    {
      enqueueSnackbar('Please enter FirstName (minimum 3 character).', { variant : 'error' }); 
      setshowLoader(false);
      return;
    }
    if(lastName === '' || lastName === undefined || lastName === null || lastName.length < 3 || !(/^[A-Za-z\s]*$/.test(lastName)) )
    {
      enqueueSnackbar('Please enter LastName (minimum 3 character).', { variant : 'error' }); 
      setshowLoader(false);
      return;

    }
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) { 
      enqueueSnackbar('Please enter valid email address.', { variant : 'error' }); 
      setshowLoader(false);
      return;

    }
    if(updatedDate === '' || updatedDate === undefined || updatedDate === null )
    {
      enqueueSnackbar('Please enter date.', { variant : 'error' }); 
      setshowLoader(false);
      return;

    }
      
      let payload = {
        firstName,
        lastName,
        email,
        assessmentId,
        updatedDate      
      };
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_EDIT_ASSESSMENT}`,
          payload
        )
          .then((resLink) => {
            enqueueSnackbar(resLink.data.message, { variant: "success" });
            setshowLoader(false);
            setFirstName('');
            setLastName('');
            setEmail('');
            setUpdatedDate('');
            navigate("../vendors", { replace: true });

          })
          .catch((errLink) => {
            enqueueSnackbar(errLink.message, { variant: "error" });
            setshowLoader(false);
          });
      }
   };


    return (
      <>
      <Loader show={showLoader}/>   

        <Helmet>
          <title> Dashboard | Genesis Platform</title>
        </Helmet>
        <Container maxWidth={false}>
        <div className="vendor-information">
            <h3 class="vendor-information-head"><span>1</span> Vendor Information</h3>
             <h3 className="relation">
            <PermContactCalendarOutlinedIcon></PermContactCalendarOutlinedIcon>Vendor's Contact Person</h3>
            <form onSubmit={(e)=>{do_submit(e)}} >
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6} lg={6}>
                          <TextField
                          name="firstName"
                          value={firstName}  
                        //   placeholder="First Name"
                          label ="First Name"            
                          onChange={(event) => handleValid(event)}
                        />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                        <TextField
                          name="lastName"
                        //   placeholder="Last Name"
                          value={lastName}  
                          onChange={(event) => handleValid(event)}
                          label ="Last Name"                     

                        />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                        <TextField
                          name="email"
                        //   placeholder="Email"
                          value={email}  
                          onChange={(event) => handleValid(event)}
                          label ="Email"                     

                        />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                        <TextField
                              name="updatedDate"
                              InputLabelProps={{ shrink: true, required: true }}
                              // placeholder= "deadline"
                              type="date"
                              value={updatedDate || ""}  
                              title="Select Date"
                              label ="Update Date"                    
                              onChange={(event) => handleValid(event)}
                         />
                        </Grid>
                    </Grid>
                    <FormGroup>
                      <Grid container spacing={3}>              
                        <Grid item xs={12} md={12} lg={12}>
                        {/* <div className="line-hr"></div>   */}
                        </Grid>           
                        <Grid item xs={12} md={12} lg={12} className="btn-right ">
                            <Link to={`../vendors`}>
                            <Button variant="contained" className="click-to-begin m-l cancel-btn">Back </Button>
                            </Link>
                            <Button type="submit" variant="contained" className="click-to-begin">
                                Update
                            </Button>
                          
                        </Grid>
                    </Grid>
                    </FormGroup>
                  </form>
           </div>
        </Container>
      </>
    );
  }

