import { configureStore } from "@reduxjs/toolkit";
import themeReducer from "./themeSlice";
import unreadMessagesReducer  from "./unreadMessagesSlice";
import activeTabReducer from "./activeTabSlice";
const store = configureStore({
  reducer: {
    theme: themeReducer,
    unreadMessages: unreadMessagesReducer,
    activeTab: activeTabReducer
  },
});

export default store;