import { TextareaAutosize } from "@mui/base";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import PreviewIcon from "@mui/icons-material/Preview";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Upload from "../assets/Vectorplus.svg";
import PostRequest from "../components/apiConnections/postRequest";
import Loader from "../Loader";
import "./css/questionnaire.css";
import ModalDialog from "./ModalDialog.js";

import {
  Box,
  Button,
  Checkbox,
  Container,
  Grid,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <>
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    </>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function QuestionnaireSubmitted({ theme }) {
  const [value, setValue] = React.useState(0);
  const data = useParams();
  const [vendorUrl, setVendorUrl] = useState(data.url);
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [showLoader, setshowLoader] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  // const [assessmentData, setAssessmentData] = useState(window.localStorage.getItem("assessmentData"));
  const [questions, setQuestions] = useState([]);
  const [queData, setQueData] = useState(
    JSON.parse(window.localStorage.getItem("assessmentData"))
  );
  const [assessmentId, setAssessmentId] = useState(data.id);
  const [questionnaire, setQuestionnaire] = useState({});
  const [submitted, setSubmitted] = useState(0);
  const [answered, setAnswered] = useState(0);
  const [deadline, setDeadline] = useState("");
  const [checkDeadlineOver, setCheckDeadlineOver] = useState();
  const [selectCategory, setSelectCategory] = useState(0);
  const [answersToBeSubmit, setAnswersToBeSubmit] = useState();
  const [ques, setQues] = useState({});
  const [questionnaireSet, setQuestionnaireSet] = React.useState("");

  useEffect(() => {
    getQuestionnaire();
  }, []);
  useEffect(() => {
    return () => {
      do_saved(0);
    };
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleImageChange = async (e, index) => {
    e.preventDefault();
    let files = e.target.files[0];
    if (files) {
      let dataImage = new FormData();
      dataImage.append("evidence", e.target.files[0]);
      const checkImage = e.target.files[0];
      const fileExtension = checkImage.name.replace(/^.*\./, "");
      const fileSize = Math.round(checkImage.size / 1024);
      if (
        fileExtension === "png" ||
        fileExtension === "PNG" ||
        fileExtension === "jpg" ||
        fileExtension === "JPG" ||
        fileExtension === "JPEG" ||
        fileExtension === "jpeg" ||
        fileExtension === "pdf" ||
        fileExtension === "PDF"
      ) {
        if (fileSize <= 5000) {
          try {
            const refreshToken = await PostRequest(
              `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
              {},
              {},
              "refreshToken"
            );
            if (refreshToken) {
              const endPoint = process.env.REACT_APP_UPLOAD_EVIDENCE;
              const uploadPicture = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endPoint}`,
                dataImage,
                "ImageUpload"
              );
              if (uploadPicture && uploadPicture.status === 200) {
                if (uploadPicture.data.code === 200) {
                  // let answersData = answersToBeSubmit;
                  // const category = questions[value]?.category;
                  // answersData[category][index].fileName = uploadPicture.data.data.filename;

                  const fileNewName = uploadPicture.data.data.filename;
                  let answersData = answersToBeSubmit;
                  const category = questions[value]?.category;
                  answersData[category][index].fileName = fileNewName;
                  setAnswersToBeSubmit(answersData);
                  // enqueueSnackbar("File Uploaded", { variant : 'success' });
                  setshowLoader(false);
                  do_saved(0);
                } else {
                  enqueueSnackbar("Error in upload file.", {
                    variant: "error",
                  });
                  setshowLoader(false);
                }
              } else {
                enqueueSnackbar("Error in upload file.", { variant: "error" });
                setshowLoader(false);
              }
            }
          } catch (e) {
            enqueueSnackbar("Error in upload image.", { variant: "error" });
            setshowLoader(false);
          }
        } else {
          enqueueSnackbar("Please upload less than 2 mb file.", {
            variant: "error",
          });
          setshowLoader(false);
        }
      }
    }
  };

  const readRadioButton = async (event, index) => {
    const ans = event.target.value;
    let answersData = answersToBeSubmit;
    const category = questions[value]?.category;
    answersData[category][index].ans = ans;
    setAnswersToBeSubmit(answersData);
    await auto_saved();
    if (answered === 0) {
      await getQuestionnaire();
    }
  };
  const questionnaireAnswerConfig = async (event, index) => {
    const ans = event.target.value;
    let answersData = answersToBeSubmit;
    const category = questions[value]?.category;
    if (answersData[category][index].ans == "")
      answersData[category][index].ans = [ans];
    else {
      let answerArr = [];
      if (answersData[category][index].ans?.includes(ans)) {
        answerArr = answersData[category][index].ans?.filter((d) => d != ans);
        answersData[category][index].ans = answerArr;
      } else {
        answersData[category][index].ans.push(ans);
      }
    }
    setAnswersToBeSubmit({ ...answersData });
    await auto_saved();
    if (answered === 0) {
      await getQuestionnaire();
    }
  };
  const readRemark = async (event, index) => {
    const ans = event.target.value;
    let answersData = answersToBeSubmit;
    const category = questions[value]?.category;
    answersData[category][index].remark = ans;
    setAnswersToBeSubmit(answersData);
    await auto_saved();
  };
  // const fileUpload = (event, index) => {
  //   const fileName = event.target.value;
  //   let answersData = answersToBeSubmit;
  //   const category = questions[value]?.category;
  //   answersData[category][index].fileName = fileName;
  //   setAnswersToBeSubmit(answersData);
  // };

  const auto_saved = async () => {
    let payloadQue = {
      ansObj: [
        {
          assessmentId: assessmentId,
          set: ques.set,
          questionnaires: answersToBeSubmit,
        },
      ],
      type: 0,
    };
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        const resultData = await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SAVE_QUESTIONNAIRE_WITH_ANSWER}`,
          payloadQue
        );
        if (resultData && resultData.status === 200) {
          // getQuestionnaire();
        }
      } else {
        setshowLoader(false);
        window.location.href = "/";
      }
    } catch (error) { }
  };

  const do_saved = async (type) => {
    setshowLoader(true);
    // alert("do saved")
    console.log("do saved");
    if (
      ques.set === "" ||
      ques.set === undefined ||
      answersToBeSubmit === "" ||
      answersToBeSubmit === null ||
      answersToBeSubmit === undefined
    ) {
      // enqueueSnackbar("Answer closed.", { variant: "error" });
      setshowLoader(false);
      return false;
    }
    let payloadQue = {
      ansObj: [
        {
          assessmentId: assessmentId,
          set: ques.set,
          questionnaires: answersToBeSubmit,
        },
      ],
      type: type,
    };
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        const resultData = await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SAVE_QUESTIONNAIRE_WITH_ANSWER}`,
          payloadQue
        );
        if (resultData && resultData.status === 200) {          
          enqueueSnackbar(resultData.data?.message, {
            variant: "success",
          });
          setshowLoader(false);
          getQuestionnaire();
          setTimeout(() => {
            if (type === 1) navigate("/vendor/vendorAssessment", { replace: true });
          }, 1000)
        } else {
          enqueueSnackbar("Answer not submitted.", { variant: "error" });
          setshowLoader(false);
        }
      } else {
        setshowLoader(false);
        window.location.href = "/";
      }
    } catch (error) {
      enqueueSnackbar("Answer not submitted.", { variant: "error" });
      setshowLoader(false);
    }
  };

  const getQuestionnaire = async () => {
    setshowLoader(true);
    const payload = {
      assessment_id: assessmentId,
    };
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        const endpoint = process.env.REACT_APP_GET_QUESTIONNAIRE_BY_VENDOR;
        await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}`,
          payload
        )
          .then((res) => {
            let response = res.data.data;
            setQuestions(response.data);
            setQues(response);
            setAnswered(response.answered);
            setSubmitted(response.submitted);
            let currentTime = Math.round(Date.now() / 1000);
            setCheckDeadlineOver(Math.sign(response.deadline - currentTime));
            let finalObject = {};
            if (parseInt(response.answered) === 0) {
              response.data.map((item, index) => {
                let ansarray = [];
                item.question.map((itemque, indexque) => {
                  let ans = "";
                  if (itemque.optionType == "MCQ") ans = [];
                  let objecttoAdd = {
                    que: itemque,
                    ans: ans,
                    remark: "",
                    fileName: "",
                  };
                  ansarray.push(objecttoAdd);
                  finalObject[item.category] = ansarray;
                });
                setAnswersToBeSubmit(finalObject);
              });
            } else {
              response.data.map((item, index) => {
                let ansarray = [];
                item.question.map((itemque, indexque) => {
                  ansarray.push(itemque);
                  finalObject[item.category] = ansarray;
                });
                setAnswersToBeSubmit(finalObject);
              });
            }
            // setTimeout(()=>{
            setshowLoader(false);
            // }, 1000)
          })
          .catch((err) => {
            enqueueSnackbar("Questionnaire details not find.", {
              variant: "error",
            });
            setshowLoader(false);
          });
      } else {
        window.location.href = "/";
      }
    } catch (error) {
      enqueueSnackbar("Questionnaire details not find.", { variant: "error" });
      setshowLoader(false);
    }
  };

  const ModalContent = () => {
    return "Save Assessment";
  };

  return (
    <>
      <Loader show={showLoader} />
      <Container maxWidth={false}>
        <Typography variant="h4">
          <span className="heading-main">Questionnaire</span>
          {checkDeadlineOver === -1 ? (
            <span style={{ color: "red" }}> (Target Date Has Expired!)</span>
          ) : null}
        </Typography>
        <div className="tabs-bg">
          <Link to={`../vendorAssessment`} style={{ float: "right" }}>
            <Button
              variant="contained"
              className="click-to-begin m-l-0 cancel-btn"
            >
              Back
            </Button>
          </Link>
          <Box sx={{ flexGrow: 1, display: "flex" }}>
            {questions?.map((element, index, array) => {
              <Tab label={"test"} {...a11yProps(`tab11`)} />;
            })}
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={value}
              onChange={handleChange}
              aria-label="Vertical tabs example"
              sx={{ borderRight: 1, borderColor: "divider" }}
            >
              {questions?.map((tab, index) => (
                <Tab
                  key={tab.category.toString() + index}
                  label={tab.category}
                  {...a11yProps(index)}
                />
              ))}
            </Tabs>
            {questions?.map((tab, index) => (
              <TabPanel value={value} index={index} className="tabpanel-full">
                <div className="question-part">
                  <div className="inline-parts">
                    <h3>{ques.set}</h3>
                  </div>
                  {answered === 1
                    ? tab.question?.map((key, i) => (
                      <>
                        {key.que?.text ? (
                          <div>
                            <p>{key.que.text}</p>
                            {key.que?.optionType == "MCQ" ? (
                              <div>
                                <FormControl>
                                  {key.que?.options?.map((option, j) => {
                                    return (
                                      <FormControlLabel
                                        style={{ fontSize: "14px" }}
                                        value={j + 1}
                                        control={
                                          <Checkbox
                                            checked={key.ans?.includes(
                                              `${j + 1}`
                                            )}
                                          />
                                        }
                                        label={option.title}
                                        onChange={(e) =>
                                          questionnaireAnswerConfig(e, i)
                                        }
                                        disabled={
                                          submitted ||
                                            checkDeadlineOver === -1
                                            ? true
                                            : false
                                        }
                                      />
                                    );
                                  })}
                                </FormControl>
                              </div>
                            ) : (
                              <div>
                                <FormControl>
                                  <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    defaultValue={key.ans}
                                  >
                                    {key.que?.options?.map((option, j) => {
                                      return (
                                        <FormControlLabel
                                          value={option.title}
                                          control={<Radio />}
                                          label={option.title}
                                          onChange={(e) =>
                                            readRadioButton(e, i)
                                          }
                                          disabled={
                                            submitted ||
                                              checkDeadlineOver === -1
                                              ? true
                                              : false
                                          }
                                        />
                                      );
                                    })}
                                  </RadioGroup>
                                </FormControl>
                              </div>
                            )}
                          </div>
                        ) : (
                          <div>
                            <p>{key.que}</p>
                            <FormControl>
                              <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                defaultValue={key.ans}
                              >
                                <FormControlLabel
                                  value="Yes"
                                  control={<Radio />}
                                  label="Yes"
                                  onChange={(e) => readRadioButton(e, i)}
                                  disabled={
                                    submitted || checkDeadlineOver === -1
                                      ? true
                                      : false
                                  }
                                />
                                <FormControlLabel
                                  value="No"
                                  control={<Radio />}
                                  label="No"
                                  onChange={(e) => readRadioButton(e, i)}
                                  disabled={
                                    submitted || checkDeadlineOver === -1
                                      ? true
                                      : false
                                  }
                                />
                                <FormControlLabel
                                  value="N/A"
                                  control={<Radio />}
                                  label="N/A"
                                  onChange={(e) => readRadioButton(e, i)}
                                  disabled={
                                    submitted || checkDeadlineOver === -1
                                      ? true
                                      : false
                                  }
                                />
                              </RadioGroup>
                            </FormControl>
                          </div>
                        )}

                        <p className="view-file">
                          Write here
                          {!submitted ? (
                            key?.fileName !== "" &&
                              key?.fileName !== undefined ? (
                              <a
                                href={
                                  process.env.REACT_APP_BACKEND_BASE_URL +
                                  "/evidence/" +
                                  key.fileName
                                }
                                target="_blank"
                                className="set-view"
                              >
                                <PreviewIcon />{" "}
                              </a>
                            ) : checkDeadlineOver === -1 ? null : (
                              <Button
                                htmlFor={`file-upload${index + "_" + i}`}
                                size="small"
                                variant="contained"
                                color="primary"
                                component="label"
                                className="pin-upload"
                                title="Select File"
                              >
                                <img src={Upload} alt="icon" />{" "}
                                <AttachFileIcon />
                              </Button>
                            )
                          ) : key?.fileName !== "" &&
                            key?.fileName !== undefined &&
                            key?.fileName ? (
                            <a
                              href={
                                process.env.REACT_APP_BACKEND_BASE_URL +
                                "/evidence/" +
                                key.fileName
                              }
                              target="_blank"
                              className="set-view"
                            >
                              <PreviewIcon />{" "}
                            </a>
                          ) : null}
                          <input
                            id={`file-upload${index + "_" + i}`}
                            name="fileName[]"
                            type="file"
                            accept="image/jpg, image/png, image/PNG, image/jpeg, image/JPG, image/JPEG, application/pdf, application/PDF"
                            onChange={(e) => handleImageChange(e, i)}
                            style={{ display: "none" }}
                          />
                        </p>
                        <TextareaAutosize
                          aria-label="Write here"
                          minRows={3}
                          className="w100-textarea"
                          defaultValue={key.remark || ""}
                          readOnly={submitted || checkDeadlineOver === -1}
                          onChange={(e) => readRemark(e, i)}
                        />
                      </>
                    ))
                    : tab.question?.map((key, i) => (
                      <>
                        {ques.setType == "custom" ||
                          ques.setType == "customAdmin" ? (
                          <div>
                            <p>{key.text}</p>
                            {key.optionType == "MCQ" ? (
                              <div>
                                <FormControl>
                                  {key.options?.map((option, j) => {
                                    return (
                                      <FormControlLabel
                                        value={j + 1}
                                        control={<Checkbox />}
                                        label={option.title}
                                        onChange={(e) =>
                                          questionnaireAnswerConfig(e, i)
                                        }
                                        disabled={
                                          submitted ||
                                            checkDeadlineOver === -1
                                            ? true
                                            : false
                                        }
                                      />
                                    );
                                  })}
                                </FormControl>
                              </div>
                            ) : (
                              <div>
                                <FormControl>
                                  <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                  >
                                    {key.options?.map((option, j) => {
                                      return (
                                        <FormControlLabel
                                          value={option.title}
                                          control={<Radio />}
                                          label={option.title}
                                          onChange={(e) =>
                                            readRadioButton(e, i)
                                          }
                                          disabled={
                                            submitted ||
                                              checkDeadlineOver === -1
                                              ? true
                                              : false
                                          }
                                        />
                                      );
                                    })}
                                  </RadioGroup>
                                </FormControl>
                              </div>
                            )}
                          </div>
                        ) : (
                          <div>
                            <p>{key}</p>
                            <FormControl>
                              <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                              >
                                <FormControlLabel
                                  value="Yes"
                                  control={<Radio />}
                                  label="Yes"
                                  onChange={(e) => readRadioButton(e, i)}
                                  disabled={
                                    submitted || checkDeadlineOver === -1
                                      ? true
                                      : false
                                  }
                                />
                                <FormControlLabel
                                  value="No"
                                  control={<Radio />}
                                  label="No"
                                  onChange={(e) => readRadioButton(e, i)}
                                  disabled={
                                    submitted || checkDeadlineOver === -1
                                      ? true
                                      : false
                                  }
                                />
                                <FormControlLabel
                                  value="N/A"
                                  control={<Radio />}
                                  label="N/A"
                                  onChange={(e) => readRadioButton(e, i)}
                                  disabled={
                                    submitted || checkDeadlineOver === -1
                                      ? true
                                      : false
                                  }
                                />
                              </RadioGroup>
                            </FormControl>
                          </div>
                        )}

                        <p className="view-file">
                          Write here
                          {!submitted ? (
                            (key?.fileName !== "" &&
                              key?.fileName !== undefined) ||
                              (answersToBeSubmit[tab?.category][index]
                                ?.fileName !== "" &&
                                answersToBeSubmit[tab?.category][index]
                                  ?.fileName !== undefined) ? (
                              <a
                                href={
                                  process.env.REACT_APP_BACKEND_BASE_URL +
                                  "/evidence/" +
                                  key.fileName
                                }
                                target="_blank"
                                className="set-view"
                              >
                                <PreviewIcon />
                              </a>
                            ) : (
                              <Button
                                htmlFor={`file-upload${index + "_" + i}`}
                                size="small"
                                variant="contained"
                                color="primary"
                                component="label"
                                className="pin-upload"
                                title="Select File"
                              >
                                <img src={Upload} alt="icon" />{" "}
                                <AttachFileIcon />
                              </Button>
                            )
                          ) : (key?.fileName !== "" &&
                            key?.fileName !== undefined) ||
                            (answersToBeSubmit[tab?.category][index]
                              ?.fileName !== "" &&
                              answersToBeSubmit[tab?.category][index]
                                ?.fileName !== undefined) ? (
                            <a
                              href={
                                process.env.REACT_APP_BACKEND_BASE_URL +
                                "/evidence/" +
                                key.fileName
                              }
                              target="_blank"
                              className="set-view"
                            >
                              <PreviewIcon />
                            </a>
                          ) : null}
                          {/* <div>
                               <Button htmlFor={`file-upload${index + '_' + i}`} size="small" variant="contained" color="primary" component="label" className="upload-new-plus" title="Select File">
                                <img src={Upload} alt="icon" /> <AttachFileIcon/>
                                </Button>
                              </div> */}
                          <input
                            id={`file-upload${index + "_" + i}`}
                            name="fileName[]"
                            type="file"
                            accept="image/jpg, image/png, image/PNG, image/jpeg, image/JPG, image/JPEG, image/pdf, image/PDF"
                            onChange={(e) => handleImageChange(e, i)}
                            style={{ display: "none" }}
                          />
                        </p>
                        <TextareaAutosize
                          aria-label="Write here"
                          minRows={3}
                          className="w100-textarea"
                          defaultValue={key.remark || ""}
                          readOnly={submitted}
                          onChange={(e) => readRemark(e, i)}
                        />
                      </>
                    ))}

                  {/* {
              tab.question.map((key, val)=>(
                <>
                <p>{key}</p>
                <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value="Yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="No"
                    control={<Radio />}
                    label="No"
                  />
                  <FormControlLabel
                    value="N/A"
                    control={<Radio />}
                    label="N/A"
                  />
                </RadioGroup>
              </FormControl>
              <p>Write here</p>
              <TextareaAutosize
                aria-label="Write here"
                minRows={3}
                className="w100-textarea"
              />
              </>
              ))
            } */}
                </div>
              </TabPanel>
            ))}
          </Box>
          <Grid item xs={12} md={12} lg={12} className="btn-right">
            {!submitted && !(checkDeadlineOver === -1) ? (
              <>
                <Button
                  variant="contained"
                  className="click-to-begin m-l cancel-btn"
                  onClick={() => do_saved(0)}
                >
                  Save
                </Button>
                <Button
                  variant="contained"
                  className="click-to-begin"
                  onClick={() => do_saved(1)}
                >
                  Submit
                </Button>
              </>
            ) : null}
          </Grid>
        </div>
        <ModalDialog
          handleClose={handleClose}
          open={open}
          onSubmit={() => do_saved(1)}
          contentData={ModalContent}
        />
      </Container>
    </>
  );
}
