import { createSlice } from '@reduxjs/toolkit';

const unreadMessagesSlice = createSlice({
  name: 'unreadMessages',
  initialState: {},
  reducers: {
    incrementUnreadCount: (state, action) => {
      const { receiverId } = action.payload;
      state[receiverId] = (state[receiverId] || 0) + 1;
    },
    resetUnreadCount: (state, action) => {
      const { receiverId } = action.payload;
      state[receiverId] = 0;
    },
  },
});

export const { incrementUnreadCount, resetUnreadCount } = unreadMessagesSlice.actions;
export default unreadMessagesSlice.reducer;
