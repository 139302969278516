import React from 'react'
import { Grid, Container, Typography ,Card, CardHeader,Stack,Box,TextField} from '@mui/material';
import { Helmet } from "react-helmet-async";
import ImageProfile from '../assets/Perfocal_Male_Dating_Profile_Smile_Man.jpg';
import './css/chat.css';

function Chat() {
  return (
      <>
       <Helmet>
         <title> Dashboard | Genesis Platform</title>
        </Helmet>
        <Typography variant="h4" sx={{ mb: 5 }}>
         <span className='heading-main'>chat app</span> 
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3} lg={2}>
          <div className="left-profilepart">
           <div className="cover-bgs">
            <div className="username">
             <img src={ImageProfile} alt='image'/>
               <p>Vincent Porter</p>
                </div>
                 <div className="position-relative">
                  <i className="fa fa-search position-absolute"></i>
                   <input className="form-control color-place" type="search" placeholder="Search..."/>
                   </div>
                   </div>
                   <div className="bottom-chat">
                   <h4>Recent chats <i className="fas fa-chevron-down"></i></h4>
                    <div className="scroll-chats">
                     <div className="recentchat">
                      <div className="d-flex align-items-center justify-content-between">
                       <p><img src={ImageProfile} alt='image'/>Aiden Chavez <br/><span>3 min ago</span>
                       </p>
                      <span className="time">11:40 PM</span>
                     </div>
                    </div>
                     <div className="recentchat">
                      <div className="d-flex align-items-center justify-content-between">
                       <p><img src={ImageProfile} alt='image'/>Aiden Chavez <br/><span>3 min ago</span>
                       </p>
                      <span className="time">11:40 PM</span>
                     </div>
                    </div>

                    <div className="recentchat active-chat">
                      <div className="d-flex align-items-center justify-content-between">
                       <p><img src={ImageProfile} alt='image'/>Aiden Chavez <br/><span>3 min ago</span>
                       </p>
                      <span className="time">11:40 PM</span>
                     </div>
                    </div>

                     <div className="recentchat">
                       <div className="d-flex align-items-center justify-content-between">
                        <p><img src={ImageProfile} alt='image'/>Aiden Chavez <br/><span>3 min ago</span>
                       </p>
                       <span className="time">11:40 PM</span>
                      </div>
                     </div>

                      <div className="recentchat">
                        <div className="d-flex align-items-center justify-content-between">
                         <p> <img src={ImageProfile} alt='image'/>Aiden Chavez <br/><span>3 min ago</span>
                         </p>
                        <span className="time">11:40 PM</span>
                       </div>
                     </div>

                     <div className="recentchat">
                      <div className="d-flex align-items-center justify-content-between">
                       <p> <img src={ImageProfile} alt='image'/>Aiden Chavez <br/><span>3 min ago</span>
                       </p>
                      <span className="time">11:40 PM</span>
                     </div>
                    </div>

                     <div className="recentchat">
                      <div className="d-flex align-items-center justify-content-between">
                       <p> <img src={ImageProfile} alt='image'/>Aiden Chavez <br/><span>3 min ago</span>
                       </p>
                      <span className="time">11:40 PM</span>
                     </div>
                    </div>

                     <div className="recentchat">
                      <div className="d-flex align-items-center justify-content-between">
                       <p> <img src={ImageProfile} alt='image'/>Aiden Chavez <br/><span>3 min ago</span>
                       </p>
                      <span className="time">11:40 PM</span>
                     </div>
                    </div>

                     <div className="recentchat">
                      <div className="d-flex align-items-center justify-content-between">
                       <p> <img src={ImageProfile} alt='image'/>Aiden Chavez <br/><span>3 min ago</span>
                       </p>
                      <span className="time">11:40 PM</span>
                     </div>
                    </div>
                   </div>
                </div>
              </div>
        </Grid>
        <Grid item xs={12} md={9} lg={10}>
        <div className="full-chat-screen">
               <div className="recentchat border-bottom">
                  <div className="d-flex align-items-center justify-content-between">
                     <p><img src={ImageProfile} alt='image'/>
                      <strong>Aiden Chavez</strong> <br/><span>Quotes text</span></p>
                      <span className="time"><i className="fas fa-ellipsis-h"></i></span>
                   </div>
                 </div>

               <div className="scroll-chats scroll-chats-right">
                 <div className="chats-box ">
                 <img src={ImageProfile} alt='image'/>
                   <div className="textareas">
                   <div className="chats-text">Hi, how are you ?</div>
                   <div className="chats-text">What are you doing tonight ? Want to go take a Coffee ?</div>
                   <span>5hours</span>
                  </div>
                </div> 

                <div className="chats-box">
                <img src={ImageProfile} alt='image'/>
                   <div className="textareas">
                   <div className="chats-text">Hi, how are you ?</div>
                   <div className="chats-text">What are you doing tonight ? Want to go take a Coffee ?</div>
                   <span>5hours</span>
                  </div>
               </div>         
               <div className="chats-box">
                 <img src={ImageProfile} alt='image'/>
                   <div className="textareas">
                   <div className="chats-text">Hi, how are you ?</div>
                   <div className="chats-text">What are you doing tonight ? Want to go take a Coffee ?</div>
                   <span>5hours</span>
                  </div>
                </div>  

                <div className="chats-box">
                <img src={ImageProfile} alt='image'/>
                   <div className="textareas">
                   <div className="chats-text">Hi, how are you ?</div>
                   <div className="chats-text">What are you doing tonight ? Want to go take a Coffee ?</div>
                   <span>5hours</span>
                  </div>
               </div>  

                <div className="chats-box">
                  <img src={ImageProfile} alt='image'/>
                   <div className="textareas">
                   <div className="chats-text">Hi, how are you ?</div>
                   <div className="chats-text">What are you doing tonight ? Want to go take a Coffee ?</div>
                   <span>5hours</span>
                  </div>
              </div>
                <div className="chats-box">
                   <img src={ImageProfile} alt='image'/>
                   <div className="textareas">
                   <div className="chats-text">Hi, how are you ?</div>
                   <div className="chats-text">What are you doing tonight ? Want to go take a Coffee ?</div>
                   <span>5hours</span>
                  </div>
               </div> 

                <div className="reply-chat">
                  <div className="response-reply">Hey Megan ! It's been a while 😃</div>
                    <div className="response-reply">When can we meet ?</div>
                     <span>5hours</span>
                  </div>
                 </div>
                <div className="footer-chat">
                 <input type="text" className="write-message" placeholder="Type your message here"/>
                    <div className="upload-btn-wrapper">
                      <i className="fas fa-paperclip"></i>
                      <input type="file" name="myfile" />
                     </div>
                   <i className="fas fa-paper-plane send-plane"></i>
                 </div>
                </div>
           </Grid>
        </Grid>
      </>
   )
}

export default Chat