import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useLocation, useParams } from "react-router-dom";
import "./css/remediations.css";
import Drawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";

// @mui
import { useTheme } from "@mui/material/styles";
import {
  Grid,
  Container,
  Typography,
  Card,
  Chip,
  CardHeader,
  Box,
  IconButton,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Label from "../components/label";
import PostRequest from "../components/apiConnections/postRequest";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { SnackbarProvider, useSnackbar } from "notistack";
import {
  AppRiskScore,
} from "../sections/@dashboard/app";
import Loader from "../Loader";
import "./css/Vendors.css";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

// ----------------------------------------------------------------------
export default function Remediation() {
  const theme = useTheme();
  const data = useParams();
  const location = useLocation();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [domainName, setDomainName] = useState("");
  const [dns, setDns] = useState("");
  const [breaches, setBreaches] = useState("");
  const [encryption, setEncryption] = useState("");
  const [misconfiguration, setMisconfigration] = useState("");
  const [patching, setPatching] = useState("");
  const [totalScore, setTotalScore] = useState("");
  const [dnsScore, setDnsScore] = useState("");
  const [breachesScore, setBreachesScore] = useState("");
  const [encryptionScore, setEncryptionScore] = useState("");
  const [misconfigrationScore, setMisconfigrationScore] = useState("");
  const [patchingScore, setPatchingScore] = useState("");
  const [riskScore, setRiskScore] = useState("");
  const [issuesListData, setIssuesListData] = useState({});
  const [patchingRowsData, setPatchingRowsData] = useState([]);
  const [logo, setLogo] = useState("/assets/images/avatars/user.png");
  const { enqueueSnackbar } = useSnackbar();
  const [showLoader, setshowLoader] = useState(false);
  const userEmail = window.localStorage.getItem("userEmail");
  const [vendorUrl, setVendorUrl] = useState(data.url);
  const [activeIssue, setActiveIssue] = useState({});
  const [sumScore, setSumScore] = useState(0)
  const [gaugeScore, setGaugeScore] = useState(0)
  useEffect(() => {
    callAPI()
  }, []);
  const callAPI = async () => {
    await getVendorDetails();
  }
  const getVendorDetails = async () => {
    setshowLoader(true);
    try {
      var rgx = /^[a-z|0-9]*\.?[a-z|0-9]*$/;
      const vendorPayload = {
        vendor: vendorUrl,
      };
      let issuesRows = [];
      let patchingRows = [];
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REQUEST_SINGLE_VENDOR_DETAILS}`,
          vendorPayload
        )
          .then((res) => {
            setDomainName(res.data.data?.vendorData?.vendorInfo.domain || "");
            setRiskScore(
              res.data.data?.vendorData?.vendorInfo?.score[0]?.Overall_score
            );
            getAssessment(res.data.data?.vendorData?.vendorInfo?.score[0]?.Overall_score);
            setDns(res.data.data?.vendorData?.vendorInfo?.count[0].Dns);
            setBreaches(
              res.data.data?.vendorData?.vendorInfo?.count[0].Breaches
            );
            setEncryption(
              res.data.data?.vendorData?.vendorInfo?.count[0].Encryption
            );
            setMisconfigration(
              res.data.data?.vendorData?.vendorInfo?.count[0].Misconfiguration
            );
            const vendorInfo = res.data.data?.vendorData?.vendorInfo;
            const dataMatch = [
              "breaches",
              "dns",
              "encryption",
              "misconfiguration",
              "patching",
            ];
            let totalLow = 0;
            let totalMedium = 0;
            let totalHigh = 0;
            let totalCritical = 0;
            let patchingissues = 0;

            Object.entries(vendorInfo).map((key, valuevendorInfo) => {
              if (dataMatch.includes(key[0])) {
                const dataArr = key[1];
                dataArr.map((value) => {
                  if (key[0] === "patching") {
                    if (
                      value.Issue === null ||
                      value.Issue === "" ||
                      value.Issue === "null"
                    ) {
                      totalLow = totalLow + 0;
                      totalMedium = totalMedium + 0;
                      totalHigh = totalHigh + 0;
                      totalCritical = totalCritical + 0;
                      patchingissues = patchingissues + 0;
                    } else {
                      const arrPatch = value.cve;
                      arrPatch.map((ele) => {
                        patchingRows.push({
                          ...issuespatchingList(
                            !ele.CVE ? "-" : ele.CVE,
                            ele.Description || ele.Desc,
                            String(ele?.Severity)
                              .replace(/<[^>]*>/g, "")
                              .replaceAll('"', "")
                              .trim()
                              .toLowerCase(),
                            "View",
                            ele,
                            ele.Score
                          ),
                          affectedUrl: value.Host,
                        });
                        patchingissues++;
                        totalLow =
                          String(ele?.Severity)
                            .replace(/<[^>]*>/g, "")
                            .replaceAll('"', "")
                            .trim()
                            .toLowerCase() === "low"
                            ? totalLow + 1
                            : totalLow;
                        totalMedium =
                          String(ele?.Severity)
                            .replace(/<[^>]*>/g, "")
                            .replaceAll('"', "")
                            .trim()
                            .toLowerCase() === "medium"
                            ? totalMedium + 1
                            : totalMedium;
                        totalHigh =
                          String(ele?.Severity)
                            .replace(/<[^>]*>/g, "")
                            .replaceAll('"', "")
                            .trim()
                            .toLowerCase() === "high"
                            ? totalHigh + 1
                            : totalHigh;
                        totalCritical =
                          String(ele?.Severity)
                            .replace(/<[^>]*>/g, "")
                            .replaceAll('"', "")
                            .trim()
                            .toLowerCase() === "critical"
                            ? totalCritical + 1
                            : totalCritical;
                      });
                    }
                  } else if (key[0] === "dns") {
                    if (
                      value.Issue === null ||
                      value.Issue === "" ||
                      value.Issue === "null"
                    ) {
                      totalLow = totalLow + 0;
                      totalMedium = totalMedium + 0;
                      totalHigh = totalHigh + 0;
                      totalCritical = totalCritical + 0;
                    } else {
                      totalLow =
                        String(value?.Severity)
                          .replace(/<[^>]*>/g, "")
                          .replaceAll('"', "")
                          .trim()
                          .toLowerCase() === "low"
                          ? totalLow + 1
                          : totalLow;
                      totalMedium =
                        String(value?.Severity)
                          .replace(/<[^>]*>/g, "")
                          .replaceAll('"', "")
                          .trim()
                          .toLowerCase() === "medium"
                          ? totalMedium + 1
                          : totalMedium;
                      totalHigh =
                        String(value?.Severity)
                          .replace(/<[^>]*>/g, "")
                          .replaceAll('"', "")
                          .trim()
                          .toLowerCase() === "high"
                          ? totalHigh + 1
                          : totalHigh;
                      totalCritical =
                        String(value?.Severity)
                          .replace(/<[^>]*>/g, "")
                          .replaceAll('"', "")
                          .trim()
                          .toLowerCase() === "critical"
                          ? totalCritical + 1
                          : totalCritical;
                      issuesRows.push(
                        issuesList(
                          !value.Issue ? "-" : value.Issue,
                          key[0],
                          String(value?.Severity)
                            .replace(/<[^>]*>/g, "")
                            .replaceAll('"', "")
                            .trim()
                            .toLowerCase(),
                          "View",
                          value,
                          value?.Reference,
                          [value?.Host] || []
                        )
                      );
                    }
                  } else {
                    if (
                      value.Issue === null ||
                      value.Issue === "" ||
                      value.Issue === "null"
                    ) {
                      totalLow = totalLow + 0;
                      totalMedium = totalMedium + 0;
                      totalHigh = totalHigh + 0;
                      totalCritical = totalCritical + 0;
                    } else {
                      totalLow =
                        String(value?.Severity)
                          .replace(/<[^>]*>/g, "")
                          .replaceAll('"', "")
                          .trim()
                          .toLowerCase() === "low"
                          ? totalLow + 1
                          : totalLow;
                      totalMedium =
                        String(value?.Severity)
                          .replace(/<[^>]*>/g, "")
                          .replaceAll('"', "")
                          .trim()
                          .toLowerCase() === "medium"
                          ? totalMedium + 1
                          : totalMedium;
                      totalHigh =
                        String(value?.Severity)
                          .replace(/<[^>]*>/g, "")
                          .replaceAll('"', "")
                          .trim()
                          .toLowerCase() === "high"
                          ? totalHigh + 1
                          : totalHigh;
                      totalCritical =
                        String(value?.Severity)
                          .replace(/<[^>]*>/g, "")
                          .replaceAll('"', "")
                          .trim()
                          .toLowerCase() === "critical"
                          ? totalCritical + 1
                          : totalCritical;
                      issuesRows.push(
                        issuesList(
                          !value.Issue ? "-" : value.Issue,
                          key[0],
                          String(value?.Severity)
                            .replace(/<[^>]*>/g, "")
                            .replaceAll('"', "")
                            .trim()
                            .toLowerCase(),
                          "View",
                          value,
                          value?.Reference,
                          value?.URL || []
                        )
                      );
                    }
                  }
                });
              }
              if (key[0] === "count") {
                setTotalScore(key[1][0].Overall_count);
              }
            });
            setPatchingRowsData(patchingRows);
            setIssuesListData(issuesRows);

            // let countScore = 0;
            // countScore = Number(totalLow + totalMedium + totalHigh + totalCritical);
            // setTotalScore(countScore > 0 ? countScore : 0);
            // setPatching(patchingissues);
            setPatching(res.data.data?.vendorInfo?.count[0].Patching);

            // Score
            setDnsScore(res.data.data?.vendorInfo?.score[0].Dns);
            setBreachesScore(res.data.data?.vendorInfo?.score[0].Breaches);
            setEncryptionScore(res.data.data?.vendorInfo?.score[0].Encryption);
            setMisconfigrationScore(
              res.data.data?.vendorInfo?.score[0].Misconfiguration
            );
            setPatchingScore(res.data.data?.vendorInfo?.score[0].Patching);
            // setshowLoader(false);
          })
          .catch((err) => {
            // no data
            enqueueSnackbar("Requested Url not find", { variant: "error" });
            setshowLoader(false);
            return false;
          });
      }
    } catch (error) {
      // no data
      enqueueSnackbar("Requested Url not find", { variant: "error" });
      setshowLoader(false);
      return false;
    }
  };

  const getAssessment = async (overAllScore) => {
    setshowLoader(true);
    try {
      var rgx = /^[a-z|0-9]*\.?[a-z|0-9]*$/;

      const assessmentPayload = {
        email: localStorage.getItem("userEmail")
      };
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_ASSESSMENT_BY_VENDOR}`,
          assessmentPayload
        )
          .then((resAssessment) => {
            const obj = resAssessment.data.data;
            if (Array.isArray(obj) && obj.length !== 0) {

              let sum = 0;
              let scoredAssessment = obj.filter((data) => typeof data.score === "number" && data.score > 0)
              scoredAssessment?.map((objKey, objVal) => {
                if (objKey.createrEmail === location.state?.createrEmail) sum = sum + objKey.score
              })
              setSumScore(sum ? Math.round(sum / scoredAssessment.length) : 0);
              let score = sum ? Math.round(sum / scoredAssessment.length) : 0;
              setGaugeScore(score > 0 ? Math.round((overAllScore + score) / 2)
                : Math.round(overAllScore))
            } else {
              setSumScore(0);
              setGaugeScore(overAllScore)
            }
            setshowLoader(false);
          })
          .catch((err) => {
            // no data
            setGaugeScore(overAllScore)
            enqueueSnackbar("Assessment not find", { variant: "error" });
            setshowLoader(false);
            return false;
          });
      }
    } catch (error) {
      // no data
      setGaugeScore(overAllScore)
      enqueueSnackbar("Requested Url not find", { variant: "error" });
      setshowLoader(false);
      return false;
    }
  };

  function createData(category, issue, grade, score) {
    return { category, issue, grade, score };
  }
  const rows = [
    createData("Breaches", breaches, "-", breachesScore),
    createData("DNS", dns, "-", dnsScore),
    createData("Encryption", encryption, "-", encryptionScore),
    createData("Misconfiguration", misconfiguration, "-", misconfigrationScore),
    createData("Patching", patching, "-", patchingScore),
    // createData("Human",2,'A', 6.2),
    // createData("Brand",2,'A', 4),
  ];

  function issuesList(name, category, severity, view, ele, refs, urls) {
    return { name, category, severity, view, ele, refs, urls };
  }
  function issuespatchingList(name, category, severity, view, ele, score) {
    return { name, category, severity, view, ele, score };
  }

  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const ExpandableTableRow = ({
    children,
    refs,
    urls,
    cve,
    ele,
    expandComponent,
    ...otherProps
  }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    return (
      <>
        <TableRow {...otherProps}>
          <TableCell padding="checkbox">
            <IconButton onClick={() => setIsExpanded(!isExpanded)}>
              {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          {children}
        </TableRow>
        {isExpanded && (
          <TableRow>
            <TableCell colSpan="4" padding="checkbox">
              <TableContainer
                sx={{ p: 1 }}
                style={{ width: "93%", overflow: "hidden" }}
                className="vendordetail"
              >
                <TableRow>
                  <TableCell padding="checkbox" />
                  {expandComponent}

                  {ele?.Email ? (
                    <TableRow
                      sx={{
                        "& td": {
                          borderBottom: "1px solid rgba(81, 81, 81, 1)",
                        },
                      }}
                    >
                      <TableCell colSpan="5">
                        <b>Affected Emails</b>
                        <p>{ele?.Email}</p>
                      </TableCell>
                    </TableRow>
                  ) : null}

                  {ele?.Source ? (
                    <TableRow sx={{ "& td": { border: 0 } }}>
                      <TableCell colSpan="5">
                        <b>Source</b>
                      </TableCell>
                    </TableRow>
                  ) : null}

                  {ele?.Source && typeof ele?.Source == "object" ? (
                    ele?.Source.map((source, index) => (
                      <TableRow sx={{ "& td": { borderTop: 0 } }}>
                        <TableCell
                          colSpan="1"
                          sx={{ "& td": { borderBottom: 0, borderTop: 0 } }}
                        >
                          #{index + 1}
                        </TableCell>
                        <TableCell colSpan="4">{source}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow
                      sx={{ "& td": { border: 0 } }}
                    >
                      <TableCell>{ele?.Source}</TableCell>
                    </TableRow>
                  )}

                  {refs?.length > 0 ? (
                    <TableRow
                      sx={{
                        "& td": {
                          border: 0,
                        },
                      }}
                    >
                      <TableCell colSpan="5">
                        <b>References</b>
                      </TableCell>
                    </TableRow>
                  ) : null}

                  {refs?.map((url, index) => (
                    <TableRow sx={{ "& td": { border: 0 } }}>
                      <TableCell colSpan="1">#{index + 1}</TableCell>
                      <TableCell colSpan="4">
                        <a href={url} target="_blank" rel="noreferrer">
                          {url}
                        </a>
                      </TableCell>
                    </TableRow>
                  ))}

                  {urls?.length > 0 ? (
                    <TableRow
                      style={{ border: 0 }}
                    >
                      <TableCell colSpan="5" style={{ borderBottom: 0 }}>
                        <b>Affected URLs</b>
                      </TableCell>
                    </TableRow>
                  ) : null}
                  {Array.isArray(urls) ? urls?.map((url, index) =>
                    index + 1 === urls?.length ? (
                      <TableRow sx={{ "& td": { border: 0 } }}>
                        <TableCell colSpan="1">#{index + 1}</TableCell>

                        <TableCell colSpan="4">
                          <a href={url} target="_blank" rel="noreferrer">
                            {url}
                          </a>
                        </TableCell>
                      </TableRow>
                    ) : (
                      <TableRow sx={{ "& td": { border: 0 } }}>
                        <TableCell colSpan="1">#{index + 1}</TableCell>

                        <TableCell colSpan="4">
                          <a href={url} target="_blank" rel="noreferrer">
                            {url}
                          </a>
                        </TableCell>
                      </TableRow>
                    )
                  ) : null}
                </TableRow>
              </TableContainer>
            </TableCell>
          </TableRow>
        )}
      </>
    );
  };

  const ExpandableTableRowPatching = ({
    children,
    cve,
    expandComponent,
    ...otherProps
  }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    return (
      <>
        <TableRow {...otherProps} hover>
          {children}
        </TableRow>
        {isExpanded && (
          <TableRow sx={{ "& td": { borderBottom: 0 } }}>
            <TableCell padding="checkbox" />
            {expandComponent}
          </TableRow>
        )}
        {/* {isExpanded && (
          <TableRow sx={{ "& td": { borderBottom: 0 } }}>
            <TableCell padding="checkbox" />
            {expandComponent}
          </TableRow>
        )} */}
      </>
    );
  };
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open, event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  const getLinkColor = () => {
    return theme.palette.mode === "dark" ? "white" : "black";
  };
  const linkDynamicStyle = {
    color: getLinkColor(),
    textDecoration: "underline",
  };
  const list = (anchor, data) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 700,
        overflowX: "hidden",
      }}
      role="presentation"
      onKeyDown={(event) => toggleDrawer(anchor, false, event)}
    >
      {console.log(data)}
      <CloseIcon
        className="space-cross"
        onClick={(event) => toggleDrawer(anchor, false, event)}
      />
      <div className="tab-anchor-flex">
        <div>
          <h4>Name</h4>
          <p>{data.name}</p>
        </div>
        <div>
          <h4>Severity</h4>
          <Label
            color={
              data.severity === "low"
                ? "success"
                : data.severity === "medium"
                  ? "warning"
                  : data.severity === "high"
                    ? "default"
                    : data.severity === "critical"
                      ? "error"
                      : "secondary"
            }
          >
            {data.severity}
          </Label>
        </div>
        <div>
          <h4>Score</h4>
          <p>{data.score}</p>
        </div>
      </div>
      <div className="in-dataspace">
        <Box>
          <Typography>CPE</Typography>
          <Box>{data?.ele?.CPE}</Box>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Typography>CWE</Typography>
          <Box>{data?.ele?.CWE}</Box>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Typography>Description</Typography>
          <Box>{data.category}</Box>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Typography>Technology</Typography>
          <Box>{data.ele?.Technology}</Box>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Typography>Affected URL</Typography>
          <Box>
            {data.affectedUrl ? (
              <a
                href={
                  data.affectedUrl.startsWith("https://")
                    ? data.affectedUrl
                    : `https://${data.affectedUrl}`
                }
                target="_blank"
                rel="noreferrer"
                style={linkDynamicStyle}
              >
                <Chip
                  label={data.affectedUrl}
                  color="error"
                  sx={{
                    "&:hover": {
                      cursor: "pointer", // Change cursor on hover
                    },
                  }}
                />
              </a>
            ) : (
              "---"
            )}
          </Box>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Typography>Remediation</Typography>
          <Box>{data.ele?.Remediation}</Box>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Typography>Advisory Links</Typography>
          <Box>
            {data.ele?.Links?.map((link, i) => (
              <Box key={i}>
                <a
                  href={link}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={linkDynamicStyle}
                >
                  {link}
                </a>
              </Box>
            ))}
          </Box>
        </Box>
      </div>
    </Box>
  );
  return (
    <>
      <Loader show={showLoader} />
      <Helmet>
        <title> Dashboard | Genesis Platform</title>
      </Helmet>
      {/* {alertData.showalert ? (
        <Alert sx={{ mb: 2 }} severity={alertData.alerttype}>
          {alertData.message}
        </Alert>
      ) : null} */}
      <Container maxWidth={false}>
        <Typography variant="h4" sx={{ mb: 5 }}>
          <span className="heading-main">Remediation</span>
        </Typography>

        <Grid container spacing={1} pb={2} className="display-flex">
          <Grid item xs={4} sm={2} md={1} lg={1} columns={0}
            columnSpacing={1}>
            <img
              src={logo}
              // { process.env.REACT_APP_BACKEND_BASE_URL +
              //                   process.env.REACT_APP_STATIC_PATH +
              //                   profilePicURL
              //                 }

              alt="login"
              style={{
                width: "70px",
                height: "70px",
                backgroundColor: "white",
                borderRadius: "5px",
                boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.05)",
              }}
            />
          </Grid>
          <Grid item xs={6} sm={7} md={9} lg={9} columns={0}
            columnSpacing={1}>
            <div className="domain-part">
              <h3 className="domain-name">{domainName}</h3>
              {/* <p className="add-top">{address}</p> */}
            </div>
          </Grid>
          <Grid item xs={12} sm={3} md={2} lg={2} sx={{ mt: 0 }}>
            {/* <Button
                          variant="contained"
                          className="addnew"
                          startIcon={<Iconify icon="eva:plus-fill" />}
                          onClick={(e) => {
                            addLinked(e);
                          }} 
                        >
                          Add New
                        </Button> */}
            <p className="cisco-link"><a href={`${window.location.protocol}//${domainName}`} target="_blank"><OpenInNewIcon /></a>  </p>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <div className="remediation-table">
              <h3 className="security-text">Remediation Plan</h3>
              <p>The security assessment action plan presented in this report represents a non-intrusive review of the vendor's systems and
                processes, which aims to identify potential security risks and vulnerabilities. It includes a prioritized list of recommended
                actions that the vendor can implement to address those risks while minimizing any impact on their day-to-day operations or business activities.</p>
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} md={4} lg={4} className="flx-property">
            <Card className="manage-middle">
              <CardHeader title={"Total CVE"} subheader={""} />
              <Typography variant="h1">
                <span className="total-score">{patchingRowsData.length}</span>
              </Typography>
            </Card>
          </Grid>

          <Grid item xs={12} md={4} lg={4} className="flx-property">
            <AppRiskScore
              title="Risk score"
              chartData={
                [
                  // { label: 'Low', value: 4344 },
                  // { label: 'Medium', value: 5435 },
                  // { label: 'High', value: 1443 },
                ]
              }
              riskScore={gaugeScore}
            />

          </Grid>

          <Grid item xs={12} md={4} lg={4} className="flx-property">
            <Card className="manage-middle">
              <CardHeader title={"Total Issues"} subheader={""} />
              <Typography variant="h1">
                <span className="total-score">{totalScore}</span>
              </Typography>
            </Card>
          </Grid>
        </Grid>

        <div className="remediation-table">
          <h3 className="security-text">CVE Details</h3>
          <TableContainer
            component={Paper}
            sx={{ p: 1 }}
            className="vendordetail"
          >
            <Table sx={{ minWidth: 100 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {/* <TableCell>#</TableCell> */}
                  <TableCell align="left" width="40%">Name</TableCell>
                  <TableCell align="left" width="40%">
                    Technology
                  </TableCell>
                  <TableCell align="left" width="20%">Severity</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>

                {(!Array.isArray(patchingRowsData) || !patchingRowsData?.length) ? (
                  <TableCell
                    component="th"
                    colSpan={3}
                    align="center"
                    height="10px"
                    style={{ padding: "70px" }}
                  >
                    No Data Found
                  </TableCell>
                ) : (
                  Array.isArray(patchingRowsData) && patchingRowsData?.map((issueRow, i) => (
                    <ExpandableTableRowPatching key={i + 1}>
                      {["right"].map((anchor, j) => (
                        <React.Fragment key={j}>
                          <TableCell
                            component="th"
                            scope="row"
                            align="left"
                            width="40%"
                            className="anchor-click"
                            onClick={(event) => {
                              toggleDrawer(anchor, true, event);
                              setActiveIssue(issueRow);
                            }}
                          >
                            {issueRow.name}
                          </TableCell>
                        </React.Fragment>
                      ))}

                      {["right"].map((anchor, j) => (
                        <React.Fragment key={j}>
                          <TableCell
                            align="left"
                            width="40%"
                            className="anchor-click"
                            onClick={(event) => {
                              toggleDrawer(anchor, true, event);
                              setActiveIssue(issueRow);
                            }}
                          >
                            {issueRow.ele.Technology &&
                              issueRow.ele.Technology !== " " &&
                              issueRow.ele.Technology !== "  " &&
                              issueRow.ele.Technology !== ""
                              ? issueRow.ele.Technology
                              : "---------"}
                          </TableCell>
                        </React.Fragment>
                      ))}
                      {["right"].map((anchor, j) => (
                        <React.Fragment key={j}>
                          <TableCell
                            align="left"
                            width="20%"
                            className="anchor-click"
                            onClick={(event) => {
                              toggleDrawer(anchor, true, event);
                              setActiveIssue(issueRow);
                            }}
                          >
                            <Label
                              color={
                                issueRow.severity === "low"
                                  ? "success"
                                  : issueRow.severity === "medium"
                                    ? "warning"
                                    : issueRow.severity === "high"
                                      ? "default"
                                      : issueRow.severity === "critical"
                                        ? "error"
                                        : "secondary"
                              }
                            >
                              {issueRow.severity}
                            </Label>
                          </TableCell>
                        </React.Fragment>
                      ))}
                    </ExpandableTableRowPatching>
                  ))
                )
                }
              </TableBody>
            </Table>
          </TableContainer>
        </div>

        <div className="remediation-table">
          <h3 className="security-text">Issue</h3>
          <TableContainer
            component={Paper}
            sx={{ p: 1 }}
            className="vendordetail"
          >
            <Table sx={{ minWidth: 100 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell sx={{ maxWidth: 80 }} width="35%">Name</TableCell>
                  <TableCell align="left" width="40%">Category</TableCell>
                  <TableCell align="left" width="20%">Severity</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {

                  (issuesListData?.length > 0) ? (issuesListData?.map((issueRow) => (
                    <ExpandableTableRow
                      key={issueRow.name}
                      refs={issueRow.refs}
                      ele={issueRow.ele}
                      urls={issueRow.urls}
                      expandComponent={
                        <>
                          <TableRow sx={{ "& td": { border: 0 } }}>
                            <TableCell colSpan="5">
                              <b>Name</b>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell colSpan="5">
                              {issueRow.ele?.Issue}
                            </TableCell>
                          </TableRow>
                          {issueRow.ele?.Data ? (
                            <>
                              <TableRow sx={{ "& td": { border: 0 } }}>
                                <TableCell colSpan="5">
                                  <b>Data</b>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell colSpan="5">
                                  {issueRow.ele?.Data}
                                </TableCell>
                              </TableRow>
                            </>
                          ) : null}
                          <TableRow sx={{ "& td": { border: 0 } }}>
                            <TableCell colSpan="5">
                              <b>Description</b>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell colSpan="5">
                              {issueRow.ele?.Description}
                            </TableCell>
                          </TableRow>
                          <TableRow sx={{ "& td": { border: 0 } }}>
                            <TableCell colSpan="5">
                              <b>Remediation</b>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell colSpan="5">
                              {issueRow.ele?.Remediation}
                            </TableCell>
                          </TableRow>
                        </>
                      }
                    >
                      <TableCell component="th" scope="row">
                        {issueRow.name}
                      </TableCell>
                      <TableCell align="left">
                        {issueRow.category}
                      </TableCell>
                      <TableCell align="left">
                        <Label
                          color={
                            issueRow.severity === "low"
                              ? "success"
                              : issueRow.severity === "medium"
                                ? "warning"
                                : issueRow.severity === "high"
                                  ? "default"
                                  : issueRow.severity === "critical"
                                    ? "error"
                                    : "secondary"
                          }
                        >
                          {issueRow.severity}
                        </Label>
                      </TableCell>
                    </ExpandableTableRow>
                  ))) : null

                }
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <Drawer
          sx={{ transition: "none", boxShadow: "none" }}
          anchor={"right"}
          open={state["right"]}
          onBackdropClick={() => {
            setState({ ...state, right: false });
          }}
        >
          {list("right", activeIssue)}
        </Drawer>
      </Container>
    </>
  );
}
