import PropTypes from "prop-types";
import ReactApexChart from "react-apexcharts";
// @mui
import {
  Box,
  Card,
  CardHeader,
  FormControl,
  InputLabel,
  MenuItem,
  NativeSelect,
  Select,
} from "@mui/material";
// components
import { useChart } from "../../../components/chart";
import CircularProgress from '@mui/material/CircularProgress';
// ----------------------------------------------------------------------

AppWebsiteVisits.propTypes = {
  loading: PropTypes.bool,
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartData: PropTypes.array.isRequired,
  chartLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default function AppWebsiteVisits({
  loading,
  title,
  subheader,
  chartLabels,
  chartData,
  totalIssuesChange,
  option,
  ...other
}) {
  const chartOptions = useChart({
    plotOptions: { bar: { columnWidth: "16%" } },
    fill: { type: chartData.map((i) => i.fill), colors: ["#4A10A4"] },
    colors: ["#4A10A4"],
    labels: chartLabels,
    // xaxis: { type: "datetime" },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          if (typeof y !== "undefined") {
            return `${y.toFixed(0)} issues`;
          }
          return y;
        },
      },
    },
  });

  return (
    <Card {...other}>
      <div className="flex-total-issues">
        <CardHeader
          className="w-100-issue"
          title={title}
          subheader={subheader}
        />
        <div className="total_issues_select_wrap">
          {/* <select
            className="total_issues_select"
            onChange={totalIssuesChange}
          >
            <option value={30}>Last Month</option>
            <option value={90}>Last 3 Months</option>
            <option value={180}>Last 6 Months</option>
            <option value={365} selected="true">
              Last Year
            </option>
          </select> */}
          <FormControl sx={{ m: 1.5 }} style={{ width: "150px" }}>
            <NativeSelect
              className="total_issues_select"
              defaultValue={option}
              onChange={totalIssuesChange}
            >
              <option value={30}>Last Month</option >
              <option value={90}>Last 3 Months</option >
              <option value={180}>Last 6 Months</option >
              <option value={365}>Last Year</option >
            </NativeSelect>
          </FormControl>
        </div>
      </div>
      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "295px" }}>
            <CircularProgress />
          </Box>
        ) : (
          <ReactApexChart
            type="line"
            series={chartData}
            options={chartOptions}
            height={280}
          />
        )}
      </Box>
    </Card>
  );
}
