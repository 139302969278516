import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useLocation, useParams } from "react-router-dom";
import { faker } from "@faker-js/faker";

// @mui
import { useTheme } from "@mui/material/styles";
import {
  Grid,
  Container,
  Typography,
  Card,
  CardHeader,
  Stack,
  Box,
  TextField,
  Alert,
  Button,
  IconButton,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Label from "../components/label";
import { sentenceCase } from "change-case";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PostRequest from "../components/apiConnections/postRequest";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Collapse from "@mui/material/Collapse";
import { SnackbarProvider, useSnackbar } from "notistack";
import CircularProgressBar from "./CircularProgressBar.js";
import CircularProgress from "./CircularProgress.js";
import LinearProgress from "@mui/material/LinearProgress";
import moment from "moment";
// components
import Iconify from "../components/iconify";
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
  AppOverallScore,
  AppRiskScore,
} from "../sections/@dashboard/app";
import Loader from "../Loader";
import "./css/Vendors.css";

// ----------------------------------------------------------------------
export default function AttackSurface() {
  const theme = useTheme();
  const data = useParams();
  const [open, setOpen] = React.useState(false);
  const [low, setLow] = useState(0);
  const [domainName, setDomainName] = useState("");
  const [hosting, setHosting] = useState("");
  const [address, setAddress] = useState("");
  const [companyImage, setCompanyImage] = useState("dummy.png");
  const [dns, setDns] = useState("");
  const [breaches, setBreaches] = useState("");
  const [encryption, setEncryption] = useState("");
  const [misconfiguration, setMisconfigration] = useState("");
  const [patching, setPatching] = useState("");
  const [totalScore, setTotalScore] = useState("");
  const [dnsScore, setDnsScore] = useState("");
  const [breachesScore, setBreachesScore] = useState("");
  const [encryptionScore, setEncryptionScore] = useState("");
  const [misconfigrationScore, setMisconfigrationScore] = useState("");
  const [patchingScore, setPatchingScore] = useState("");
  const [riskScore, setRiskScore] = useState("");
  const [alertData, setAlertData] = useState({});
  const [issuesListData, setIssuesListData] = useState({});
  const [patchingRowsData, setPatchingRowsData] = useState({});
  const [roleId, setroleId] = useState(
    parseInt(window.localStorage.getItem("roleId"))
  );
  const [totalLow, setTotalLow] = useState(0);
  const [totalMedium, setTotalMedium] = useState(0);
  const [totalHigh, setTotalHigh] = useState(0);
  const [totalCritical, setTotalCritical] = useState(0);
  const [logo, setLogo] = useState("/assets/images/avatars/user.png");
  const { enqueueSnackbar } = useSnackbar();
  const [userRoleId, setUserRoleId] = useState(localStorage.getItem("roleId"));
  const [showLoader, setshowLoader] = useState(false);
  const [urlLinked, setUrlLinked] = useState(0);
  const [complianceData, setComplianceData] = useState({});
  const [score, setScore] = useState(0);
  const [graphDates, setGraphDates] = useState([]);
  const [graphValues, setGraphValues] = useState([]);
  const userEmail = window.localStorage.getItem("userEmail");

  useEffect(() => {
    getVendorDetails();
    getAssessment();
  }, []);

  const getVendorDetails = async () => {
    setshowLoader(true);
    try {
      var rgx = /^[a-z|0-9]*\.?[a-z|0-9]*$/;
      const vendorPayload = {
        vendor: "etisalat.ae",
      };
      let issuesRows = [];
      let patchingRows = [];
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REQUEST_SINGLE_VENDOR_DETAILS}`,
          vendorPayload
        )
          .then((res) => {
            setUrlLinked(res.data.data?.urlLink);
            setDomainName(res.data.data?.vendorData?.vendorInfo.domain || "");
            setRiskScore(
              res.data.data?.vendorData?.vendorInfo?.score[0]?.Overall_score
            );
            setAddress(res.data.data?.vendorData?.vendorInfo?.ip);
            setHosting(res.data.data?.vendorData?.vendorInfo?.hosting);
            setLogo(
              res.data.data?.vendorData?.vendorInfo?.logo ||
                "/assets/images/avatars/user.png"
            );
            // setDns(res.data.data?.vendorInfo?.dns.length);
            // setBreaches(res.data.data?.vendorInfo?.breaches.length);
            // setEncryption(res.data.data?.vendorInfo?.encryption.length);
            // setMisconfigration(res.data.data?.vendorInfo?.misconfiguration.length);
            setDns(res.data.data?.vendorData?.vendorInfo?.count[0].Dns);
            setBreaches(
              res.data.data?.vendorData?.vendorInfo?.count[0].Breaches
            );
            setEncryption(
              res.data.data?.vendorData?.vendorInfo?.count[0].Encryption
            );
            setMisconfigration(
              res.data.data?.vendorData?.vendorInfo?.count[0].Misconfiguration
            );
            const vendorInfo = res.data.data?.vendorData?.vendorInfo;
            const dataMatch = [
              "breaches",
              "dns",
              "encryption",
              "misconfiguration",
              "patching",
            ];
            let totalLow = 0;
            let totalMedium = 0;
            let totalHigh = 0;
            let totalCritical = 0;
            let patchingissues = 0;

            Object.entries(vendorInfo).map((key, valuevendorInfo) => {
              if (dataMatch.includes(key[0])) {
                const dataArr = key[1];
                dataArr.map((value) => {
                  if (key[0] === "patching") {
                    if (
                      value.Issue === null ||
                      value.Issue === "" ||
                      value.Issue === "null"
                    ) {
                      totalLow = totalLow + 0;
                      totalMedium = totalMedium + 0;
                      totalHigh = totalHigh + 0;
                      totalCritical = totalCritical + 0;
                      patchingissues = patchingissues + 0;
                    } else {
                      const arrPatch = value.cve;
                      arrPatch.map((ele) => {
                        patchingRows.push(
                          issuespatchingList(
                            !ele.CVE ? "-" : ele.CVE,
                            ele.Description,
                            String(ele?.Severity)
                              .replace(/<[^>]*>/g, "")
                              .replaceAll('"', "")
                              .trim()
                              .toLowerCase(),
                            "View",
                            ele,
                            value
                          )
                        );
                        patchingissues++;
                        totalLow =
                          String(ele?.Severity)
                            .replace(/<[^>]*>/g, "")
                            .replaceAll('"', "")
                            .trim()
                            .toLowerCase() === "low"
                            ? totalLow + 1
                            : totalLow;
                        totalMedium =
                          String(ele?.Severity)
                            .replace(/<[^>]*>/g, "")
                            .replaceAll('"', "")
                            .trim()
                            .toLowerCase() === "medium"
                            ? totalMedium + 1
                            : totalMedium;
                        totalHigh =
                          String(ele?.Severity)
                            .replace(/<[^>]*>/g, "")
                            .replaceAll('"', "")
                            .trim()
                            .toLowerCase() === "high"
                            ? totalHigh + 1
                            : totalHigh;
                        totalCritical =
                          String(ele?.Severity)
                            .replace(/<[^>]*>/g, "")
                            .replaceAll('"', "")
                            .trim()
                            .toLowerCase() === "critical"
                            ? totalCritical + 1
                            : totalCritical;
                      });
                    }
                  } else if (key[0] === "dns") {
                    if (
                      value.Issue === null ||
                      value.Issue === "" ||
                      value.Issue === "null"
                    ) {
                      totalLow = totalLow + 0;
                      totalMedium = totalMedium + 0;
                      totalHigh = totalHigh + 0;
                      totalCritical = totalCritical + 0;
                    } else {
                      totalLow =
                        String(value?.Severity)
                          .replace(/<[^>]*>/g, "")
                          .replaceAll('"', "")
                          .trim()
                          .toLowerCase() === "low"
                          ? totalLow + 1
                          : totalLow;
                      totalMedium =
                        String(value?.Severity)
                          .replace(/<[^>]*>/g, "")
                          .replaceAll('"', "")
                          .trim()
                          .toLowerCase() === "medium"
                          ? totalMedium + 1
                          : totalMedium;
                      totalHigh =
                        String(value?.Severity)
                          .replace(/<[^>]*>/g, "")
                          .replaceAll('"', "")
                          .trim()
                          .toLowerCase() === "high"
                          ? totalHigh + 1
                          : totalHigh;
                      totalCritical =
                        String(value?.Severity)
                          .replace(/<[^>]*>/g, "")
                          .replaceAll('"', "")
                          .trim()
                          .toLowerCase() === "critical"
                          ? totalCritical + 1
                          : totalCritical;
                      issuesRows.push(
                        issuesList(
                          !value.Issue ? "-" : value.Issue,
                          key[0],
                          String(value?.Severity)
                            .replace(/<[^>]*>/g, "")
                            .replaceAll('"', "")
                            .trim()
                            .toLowerCase(),
                          "View",
                          value,
                          value?.Reference,
                          [value?.Host] || []
                        )
                      );
                    }
                  } else {
                    if (
                      value.Issue === null ||
                      value.Issue === "" ||
                      value.Issue === "null"
                    ) {
                      totalLow = totalLow + 0;
                      totalMedium = totalMedium + 0;
                      totalHigh = totalHigh + 0;
                      totalCritical = totalCritical + 0;
                    } else {
                      totalLow =
                        String(value?.Severity)
                          .replace(/<[^>]*>/g, "")
                          .replaceAll('"', "")
                          .trim()
                          .toLowerCase() === "low"
                          ? totalLow + 1
                          : totalLow;
                      totalMedium =
                        String(value?.Severity)
                          .replace(/<[^>]*>/g, "")
                          .replaceAll('"', "")
                          .trim()
                          .toLowerCase() === "medium"
                          ? totalMedium + 1
                          : totalMedium;
                      totalHigh =
                        String(value?.Severity)
                          .replace(/<[^>]*>/g, "")
                          .replaceAll('"', "")
                          .trim()
                          .toLowerCase() === "high"
                          ? totalHigh + 1
                          : totalHigh;
                      totalCritical =
                        String(value?.Severity)
                          .replace(/<[^>]*>/g, "")
                          .replaceAll('"', "")
                          .trim()
                          .toLowerCase() === "critical"
                          ? totalCritical + 1
                          : totalCritical;
                      issuesRows.push(
                        issuesList(
                          !value.Issue ? "-" : value.Issue,
                          key[0],
                          String(value?.Severity)
                            .replace(/<[^>]*>/g, "")
                            .replaceAll('"', "")
                            .trim()
                            .toLowerCase(),
                          "View",
                          value,
                          value?.Reference,
                          value?.URL || []
                        )
                      );
                    }
                  }
                });
              }
              if (key[0] === "count") {
                setTotalScore(key[1][0].Overall_count);
              }
            });
            setPatchingRowsData(patchingRows);
            setIssuesListData(issuesRows);
            setTotalLow(totalLow);
            setTotalMedium(totalMedium);
            setTotalHigh(totalHigh);
            setTotalCritical(totalCritical);
            // let countScore = 0;
            // countScore = Number(totalLow + totalMedium + totalHigh + totalCritical);
            // setTotalScore(countScore > 0 ? countScore : 0);
            // setPatching(patchingissues);
            setPatching(res.data.data?.vendorInfo?.count[0].Patching);

            // Score
            setDnsScore(res.data.data?.vendorInfo?.score[0].Dns);
            setBreachesScore(res.data.data?.vendorInfo?.score[0].Breaches);
            setEncryptionScore(res.data.data?.vendorInfo?.score[0].Encryption);
            setMisconfigrationScore(
              res.data.data?.vendorInfo?.score[0].Misconfiguration
            );
            setPatchingScore(res.data.data?.vendorInfo?.score[0].Patching);
            setshowLoader(false);
          })
          .catch((err) => {
            // no data
            enqueueSnackbar("Requested Url not find", { variant: "error" });
            setshowLoader(false);
            return false;
          });
      }
    } catch (error) {
      // no data
      enqueueSnackbar("Requested Url not find", { variant: "error" });
      setshowLoader(false);
      return false;
    }
  };

  const getAssessment = async () => {
    setshowLoader(true);
    try {
      var rgx = /^[a-z|0-9]*\.?[a-z|0-9]*$/;
      const assessmentPayload = {
        createdBy: "hemant.uchchasare@5exceptions.com",
      };
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_ASSESSMENT_BY_USER}`,
          assessmentPayload
        )
          .then((resAssessment) => {
            const obj = resAssessment.data.data;
            setComplianceData(obj);
            setshowLoader(false);
          })
          .catch((err) => {
            // no data
            enqueueSnackbar("Assessment not find", { variant: "error" });
            setshowLoader(false);
            return false;
          });
      }
    } catch (error) {
      // no data
      enqueueSnackbar("Requested Url not find", { variant: "error" });
      setshowLoader(false);
      return false;
    }
  };

  function createData(category, issue, grade, score) {
    return { category, issue, grade, score };
  }
  const rows = [
    createData("Breaches", breaches, "-", breachesScore),
    createData("DNS", dns, "-", dnsScore),
    createData("Encryption", encryption, "-", encryptionScore),
    createData("Misconfiguration", misconfiguration, "-", misconfigrationScore),
    createData("Patching", patching, "-", patchingScore),
    // createData("Human",2,'A', 6.2),
    // createData("Brand",2,'A', 4),
  ];

  function issuesList(name, category, severity, view, ele, refs, urls) {
    return { name, category, severity, view, ele, refs, urls };
  }
  function issuespatchingList(name, category, severity, view, ele, cve) {
    return { name, category, severity, view, ele, cve };
  }

  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const ExpandableTableRow = ({
    children,
    refs,
    urls,
    cve,
    ele,
    expandComponent,
    ...otherProps
  }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    return (
      <>
        <TableRow {...otherProps}>
          <TableCell padding="checkbox">
            <IconButton onClick={() => setIsExpanded(!isExpanded)}>
              {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          {children}
        </TableRow>
        {isExpanded && (
          <TableRow>
            <TableCell padding="checkbox" />
            {expandComponent}

            {ele?.Email?.length > 0 ? (
              <TableRow sx={{ "& td": { borderBottom: 0 } }}>
                <TableCell colSpan="5">
                  <b>Affected Emails</b>
                </TableCell>
              </TableRow>
            ) : null}
            {typeof ele?.Email == "object" &&
              ele?.Email.map((url, index) => (
                <TableRow sx={{ "& td": { border: 0 } }}>
                  <TableCell colSpan="1">#{index + 1}</TableCell>

                  <TableCell colSpan="2">
                    <a href={url} target="_blank">
                      {url}
                    </a>
                  </TableCell>
                  <TableCell colSpan="2">{ele.Source}</TableCell>
                </TableRow>
              ))}

            {refs.length > 0 ? (
              <TableRow sx={{ "& td": { borderBottom: 0 } }}>
                <TableCell colSpan="5">
                  <b>References</b>
                </TableCell>
              </TableRow>
            ) : null}

            {refs.map((url, index) => (
              <TableRow sx={{ "& td": { border: 0 } }}>
                <TableCell colSpan="1">#{index + 1}</TableCell>

                <TableCell colSpan="4">
                  <a href={url} target="_blank">
                    {url}
                  </a>
                </TableCell>
              </TableRow>
            ))}
            {urls.length > 0 ? (
              <TableRow sx={{ "& td": { borderBottom: 0 } }}>
                <TableCell colSpan="5">
                  <b>Affected URLs</b>
                </TableCell>
              </TableRow>
            ) : null}
            {urls.map((url, index) =>
              index + 1 == urls.length ? (
                <TableRow>
                  <TableCell colSpan="1">#{index + 1}</TableCell>

                  <TableCell colSpan="4">
                    <a href={url} target="_blank">
                      {url}
                    </a>
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow sx={{ "& td": { border: 0 } }}>
                  <TableCell colSpan="1">#{index + 1}</TableCell>

                  <TableCell colSpan="4">
                    <a href={url} target="_blank">
                      {url}
                    </a>
                  </TableCell>
                </TableRow>
              )
            )}
          </TableRow>
        )}
      </>
    );
  };

  const ExpandableTableRowPatching = ({
    children,
    cve,
    expandComponent,
    ...otherProps
  }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    return (
      <>
        <TableRow {...otherProps}>
          <TableCell padding="checkbox">
            <IconButton onClick={() => setIsExpanded(!isExpanded)}>
              {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          {children}
        </TableRow>
        {isExpanded && (
          <TableRow sx={{ "& td": { borderBottom: 0 } }}>
            <TableCell padding="checkbox" />
            {expandComponent}
            {/* {cve.map((url, index) => (  
              
                            <TableRow>
                              <TableCell colSpan="1">Reference {index+1}</TableCell>                
                              <TableCell colSpan="4">
                              <a href={url} target="_blank">  
                                {url}  
                              </a> 
                              </TableCell>                
                            </TableRow>
                          ))} */}
              </TableRow>
           )}
       </>
    );
  };
  return (
    <>
      <Helmet>
        <title> Dashboard | Genesis Platform</title>
      </Helmet>
      {/* {alertData.showalert ? (
        <Alert sx={{ mb: 2 }} severity={alertData.alerttype}>
          {alertData.message}
        </Alert>
      ) : null} */}
      <Container maxWidth={false}>
        <Typography variant="h4" sx={{ mb: 5 }}>
          {/* <span className='heading-main'>Vendor's Details</span> */}
        </Typography>
        {showLoader === true ? (
          <Loader show={showLoader} />
        ) : domainName !== "" && domainName ? (
          <Box
            sx={{ width: "100%", typography: "body1" }}
            className="ipad-space"
          >
            <TabContext value={value}>
              <Box
                sx={{ borderBottom: 1, borderColor: "divider" }}
                className="tabspart"
              >
                <TabList
                  onChange={handleChange}
                  variant="scrollable"
                  allowScrollButtonsMobile
                  aria-label="lab API tabs example"
                >
                  <Tab label="Attack Surface" value="1" />
                  <Tab label="Assets" value="2" />
                  {/* <Tab label="CVE" value="3" />
                  <Tab label="Compliance" value="4" />
                  <Tab label="Security Control" value="5" /> */}
                </TabList>
              </Box>
              <TabPanel value="1">
                <Grid container spacing={1} pb={2}>
                  <Grid item xs={4} sm={2} md={1} lg={1}>
                    <img src={logo}
                      //{process.env.REACT_APP_BACKEND_BASE_URL +
                      //                   process.env.REACT_APP_STATIC_PATH +
                      //                   profilePicURL
                      //}
                      alt="login"
                      style={{
                        width: "70px",
                        height: "70px",
                        backgroundColor: "white",
                        borderRadius: "5px",
                        boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.05)",
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} sm={7} md={9} lg={9}>
                    <div className="domain-part">
                      <h3 className="domain-name">{domainName}</h3>
                      <p className="add-top">{address}</p>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={3} md={2} lg={2} sx={{ mt: 5 }}>
                    <Button variant="contained" className="addnew export-btn">
                      Export
                    </Button>
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={12} md={4} lg={4} className="flx-property fit-height">
                    <AppOverallScore
                      title="Issue over time"
                      style={{}}
                      riskScore="0"
                    />
                  </Grid>
                  <Grid item xs={12} md={8} lg={8} className="flx-property">
                    <div className="SubDomains">
                     <h3 className="attack-tittle">Security Grade </h3>
                      <Grid container spacing={2} className="fit-tomid">
                        <Grid item xs={12} md={6} lg={6} className="alin-mid">
                          <CircularProgress percent={50} />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                          <div className="Total-SubDomains">
                            <span>20</span>
                            <p> Total SubDomains</p>
                          </div>
                          <div className="Total-SubDomains">
                            <span>20</span>
                            <p> Total IPv4</p>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <AppWebsiteVisits
                      title="Total Issues"
                      // subheader="Last 30 Days"
                      chartLabels={graphDates}
                      chartData={[
                        {
                          name: "Total Issues",
                          type: "area",
                          fill: "gradient",
                          data: graphValues,
                        },
                      ]}
                    />
                  </Grid>

                  <Grid item xs={12} md={6} lg={6} className="flx-property">
                    <div className="issue-severity">
                      <h3 className="attack-tittle">Issue Severity </h3>
                      <div className="alin-mids">
                        <Stack
                          sx={{ width: "80%", color: "grey.500" }}
                          spacing={2}
                          className="linearprocess"
                        >
                          <p className="m0">Critical</p>
                          <LinearProgress
                            color="secondary"
                            variant="determinate"
                            value={50}
                          />
                          <p>High</p>
                          <LinearProgress
                            color="success"
                            variant="determinate"
                            value={80}
                          />
                          <p>Medium</p>
                          <LinearProgress
                            color="inherit"
                            variant="determinate"
                            value={60}
                          />
                        </Stack>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6} className="flx-property">
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6} lg={6}>
                        <div className="issues-box">
                          <div>
                            <h3>20</h3>
                            <p>Issues</p>
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={6} lg={6}>
                        <div className="issues-box">
                          <div>
                            <h3>20</h3>
                            <p>CVE</p>
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={6} lg={6}>
                        <div className="issues-box">
                          <div>
                            <h3>20</h3>
                            <p>Open Ports</p>
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={6} lg={6}>
                        <div className="issues-box">
                          <div>
                            <h3>20</h3>
                            <p>Breaches</p>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <h2 className="security-text Security-Monitor">
                     Security Monitor
                    </h2>
                  </Grid>
                  <Grid item xs={12} md={4} lg={4} className="flex-align">
                    <CircularProgress percent={83} title={`DNS`} />
                  </Grid>
                  <Grid item xs={12} md={4} lg={4} className="flex-align">
                    <CircularProgress percent={83} title={`Misconfiguration`} />
                  </Grid>
                  <Grid item xs={12} md={4} lg={4} className="flex-align">
                    <CircularProgress percent={76} title={`Encryption`} />
                  </Grid>
                  <Grid item xs={12} md={4} lg={4} className="flex-align">
                    <CircularProgress percent={83} title={`Breach`} />
                  </Grid>
                  <Grid item xs={12} md={4} lg={4} className="flex-align">
                    <CircularProgress percent={83} title={`Network`} />
                  </Grid>
                  <Grid item xs={12} md={4} lg={4} className="flex-align">
                    <CircularProgress percent={83} title={`Leak`} />
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value="2">
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12} lg={12} pl={0} className="font-p">
                    <h2>Attack Surface</h2>
                    <p>
                      <strong>Host - </strong> {domainName}
                      <br></br>
                      <strong>IP Address - </strong> {address}
                    </p>
                    <TableContainer
                      component={Paper}
                      sx={{ p: 1 }}
                      className="vendordetail"
                    >
                      <Table sx={{ minWidth: 100 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell sx={{ maxWidth: 80 }}>Name</TableCell>
                            <TableCell align="left">Category</TableCell>
                            <TableCell align="left">Severity</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {issuesListData.map((issueRow) => (
                            <ExpandableTableRow
                              key={issueRow.name}
                              refs={issueRow.refs}
                              ele={issueRow.ele}
                              urls={issueRow.urls}
                              expandComponent={
                                <>
                                  <TableRow sx={{ "& td": { border: 0 } }}>
                                    <TableCell colSpan="5">
                                      <b>Name</b>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell colSpan="5">
                                      {issueRow.ele?.Issue}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow sx={{ "& td": { border: 0 } }}>
                                    <TableCell colSpan="5">
                                      <b>Description</b>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell colSpan="5">
                                      {issueRow.ele?.Description}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow sx={{ "& td": { border: 0 } }}>
                                    <TableCell colSpan="5">
                                      <b>Remediation</b>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell colSpan="5">
                                      {issueRow.ele?.Remediation}
                                    </TableCell>
                                  </TableRow>
                                </>
                              }
                            >
                              <TableCell component="th" scope="row">
                                {issueRow.name}
                              </TableCell>
                              <TableCell align="left">
                                {issueRow.category}
                              </TableCell>
                              <TableCell align="left">
                                <Label
                                  color={
                                    issueRow.severity === "low"
                                      ? "success"
                                      : issueRow.severity === "medium"
                                      ? "primary"
                                      : issueRow.severity === "high"
                                      ? "warning"
                                      : "error"
                                  }
                                >
                                  {issueRow.severity}
                                </Label>
                              </TableCell>
                            </ExpandableTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </TabPanel>
            </TabContext>
          </Box>
        ) : (
          <Box sx={{ display: "flex" }}>
            <Grid>
              <Card item xs={12} md={6} lg={6} sx={{ p: 5 }}>
                <h4>No Record for this url</h4>
              </Card>
            </Grid>
          </Box>
        )}
      </Container>
    </>
  );
}
