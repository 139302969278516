import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation, useParams } from "react-router-dom";
import { faker } from "@faker-js/faker";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
// @mui
import { useTheme } from "@mui/material/styles";
import {
  Grid,
  Container,
  Typography,
  Card,
  CardHeader,
  Stack,
  Box,
  TextField,
  Alert,
  Button,
  IconButton,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Label from "../components/label";
import { sentenceCase } from "change-case";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PostRequest from "../components/apiConnections/postRequest";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Collapse from "@mui/material/Collapse";
import { SnackbarProvider, useSnackbar } from "notistack";
import CircularProgressBar from "./CircularProgressBar.js";
import CircularProgress from "./CircularProgress.js";
import CircularProgressSimple from "./CircularProgressSimple.js";
import CircularProgressDividedIcon from "./CircularProgressDividedIcon.js";
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import LinearProgress from "@mui/material/LinearProgress";
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import moment from "moment";
// import CircularProgress from '@mui/material/CircularProgress';
// components
import Iconify from "../components/iconify";
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
  AppOverallScore,
  AppRiskScore,
  AppOverallAttackScore,
} from "../sections/@dashboard/app";
import Loader from "../Loader";
import "./css/Attack.css";
import "./css/Vendors.css";
import SubDomain from "../assets/subdomain.svg";
import ipv4 from "../assets/www.svg";
import jwt_decode from "jwt-decode";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import DoneIcon from "@mui/icons-material/Done";
import ShowChartIcon from '@mui/icons-material/ShowChart';
import { Route, Link, BrowserRouter } from "react-router-dom";

// ----------------------------------------------------------------------
export default function ExportDetail() {
  let theme = useTheme();
  const data = useParams();
  const [open, setOpen] = React.useState(false);
  const [low, setLow] = useState(0);
  const [domainName, setDomainName] = useState("");
  const [uploadTime, setUploadTime] = useState("");
  const [hosting, setHosting] = useState("");
  const [address, setAddress] = useState("");
  const [companyImage, setCompanyImage] = useState("dummy.png");
  const [dns, setDns] = useState("");
  const [breaches, setBreaches] = useState("");
  const [encryption, setEncryption] = useState("");
  const [misconfiguration, setMisconfigration] = useState("");
  const [patching, setPatching] = useState("");
  const [totalScore, setTotalScore] = useState("");
  const [dnsScore, setDnsScore] = useState("");
  const [breachesScore, setBreachesScore] = useState("");
  const [encryptionScore, setEncryptionScore] = useState("");
  const [misconfigrationScore, setMisconfigrationScore] = useState("");
  const [patchingScore, setPatchingScore] = useState("");
  const [riskScore, setRiskScore] = useState("");
  const [alertData, setAlertData] = useState({});
  const [issuesListData, setIssuesListData] = useState({});
  const [patchingRowsData, setPatchingRowsData] = useState({});
  const [roleId, setroleId] = useState(
    parseInt(window.localStorage.getItem("roleId"))
  );
  const [totalLow, setTotalLow] = useState(0);
  const [totalMedium, setTotalMedium] = useState(0);
  const [totalHigh, setTotalHigh] = useState(0);
  const [totalCritical, setTotalCritical] = useState(0);
  const [logo, setLogo] = useState("/assets/images/avatars/user.png");
  const { enqueueSnackbar } = useSnackbar();
  const [userRoleId, setUserRoleId] = useState(localStorage.getItem("roleId"));
  const [showLoader, setshowLoader] = useState(false);
  const [domainIssues, setDomainIssues] = useState("");
  const [attackInfoData, setattackInfoData] = useState("");
  const [attackScoreInfo, setAttackScoreInfo] = useState("");
  const [attackSeverityInfo, setAttackSeverityInfo] = useState("");
  const [attackCountInfo, setAttackCountInfoInfo] = useState("");
  const [securityGrade, setSecurityGrade] = useState(0);
  const [securityGradeTitle, setSecurityGradeTitle] = useState("");

  useEffect(() => {
    getVendorDetails();
    setTimeout(() => {
      pdfCreate();
    }, 1500);
  }, []);

  let token = !localStorage.getItem("R1")
    ? localStorage.getItem("R2")
    : localStorage.getItem("R1");
  let tokenData = JSON.parse(token).accessToken;
  let userData = jwt_decode(tokenData);
  let clientName = userData.firstName + " " + userData.lastName;
  const getVendorDetails = async () => {
    setshowLoader(true);
    try {
      var rgx = /^[a-z|0-9]*\.?[a-z|0-9]*$/;
      const vendorPayload = {
        vendor: data.url,
        roleId: roleId,
      };
      let issuesRows = [];
      let patchingRows = [];
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_SINGLE_ATTACK_DETAIL}`,
          vendorPayload
        )
          .then((res) => {
            setattackInfoData(res.data.data.attackInfo);
            setDomainName(res.data.data?.attackInfo.domain || "");
            setUploadTime(res.data.data?.attackInfo.time);
            setDomainIssues(res.data.data?.attackInfo.Issues);
            setAttackScoreInfo(res.data.data?.attackInfo.score);
            setAttackSeverityInfo(res.data.data?.attackInfo.severity);
            setAttackCountInfoInfo(res.data.data?.attackInfo.count[0]);
            setSecurityGrade(res.data.data.attackInfo.score[0].Overall_score);
            setSecurityGradeTitle(res.data.data.attackInfo.score[0].Grade);
            setshowLoader(false);
          })
          .catch((err) => {
            // no data
            enqueueSnackbar("Requested Url not find", { variant: "error" });
            setshowLoader(false);
            return false;
          });

        let endpoint = "attack-details-day-wise";
        const daysPayload = {
          selectedDays: 30,
          domainName: data.url,
        };
        PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}`,
          daysPayload
        )
          .then((res) => {
            let date = [];
            let issuesData = [];
            res.data.data.map((item, i) => {
              let element = item.attackInfo;

              date.push(
                moment(new Date(element.time * 1000)).format("MM/DD/YYYY")
              );
              issuesData.push(element.count.total_issue);
            });
            setGraphDates(date);
            setGraphValues(issuesData);
          })
          .catch((err) => {
            // no data
            enqueueSnackbar("Requested Url not find", { variant: "error" });
            setshowLoader(false);
            return false;
          });
      }
    } catch (error) {
      // no data
      enqueueSnackbar("Requested Url not find", { variant: "error" });
      setshowLoader(false);
      return false;
    }
  };

  const [value, setValue] = React.useState("1");
  const [graphDates, setGraphDates] = useState(["02/28/2023"]);
  const [graphValues, setGraphValues] = useState([36]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const pdfCreate = () => {
    html2canvas(document.querySelector("#capture")).then((canvas) => {
      document.body.appendChild(canvas);
      const imgData = canvas.toDataURL("image/png");
      const imgWidth = 195;
      const pageHeight = 290;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      const doc = new jsPDF("pt", "mm");
      let position = 0;
      doc.addImage(imgData, "PNG", 5, 0, imgWidth, imgHeight );
      heightLeft -= pageHeight;
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, "PNG", 5, position, imgWidth, imgHeight );
        heightLeft -= pageHeight;
      }
      doc.save("genesis.pdf");
      window.close();
    });
  };

  return (
    <>
      <Helmet>
        <title> Dashboard | Genesis Platform</title>
      </Helmet>
      <Container maxWidth={false} sx={{ p: 5 }} className="bgprint">
        <Typography variant="h4" sx={{ mb: 5 }}>
          <span className="heading-main">Assets</span>
        </Typography>
        <Grid
          item
          xs={6}
          sm={3}
          md={3}
          lg={3}
          sx={{ mt: 2 }}
          className="btn-right"
        >
          <Button
            variant="contained"
            className="addnew send-assessmen"
            onClick={() => pdfCreate()}
          >
            Download
          </Button>
        </Grid>
        {showLoader === true ? (
          <Loader show={showLoader} />
        ) : domainName !== "" && domainName ? (
          <div id="capture">
            <Grid item xs={12} md={12} lg={12} sx={{ p: "5px" }}>
              <h6
                className="print-text security-Monitor"
                style={{ padding: "5px" }}
              >
                Name : {clientName}
              </h6>
              <h6
                className="print-text Security-Monitor "
                style={{ padding: "5px" }}
              >
              
                Date : {moment(new Date(uploadTime * 1000)).format("MMMM Do YYYY")}
              </h6>
              <h6
                className="print-text Security-Monitor "
                style={{ padding: "5px" }}
              >
                Domain : {domainName}
              </h6>
            </Grid>
            <Grid item xs={12} md={12} lg={12} sx={{ mb: "15px" }}>
              <div className="line-hr"></div>
            </Grid>
            <Grid item xs={12} md={12} lg={12} >
              <h1 className="print-text heading-main">
                Detail Report for Attack Surface
              </h1>
              <h3 className="mt-24 security-posture">Security Posture</h3>
            </Grid>
            <Grid container spacing={3}>
              <Grid
                item
                xs={12}
                md={4}
                lg={4}
                className="flx-property AttackScore export-ex"
              >
                <AppOverallAttackScore
                  title="Issue over time"
                  style={{}}
                  riskScore="10"
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={8}
                lg={8}
                className="flx-property AttackScore mid-align-g"
              >
                <CircularProgressSimple
                  percent={
                    securityGradeTitle == "D"
                      ? 22
                      : securityGradeTitle == "C"
                      ? 44
                      : securityGradeTitle == "B"
                      ? 70
                      : 100
                  }
                  title={securityGradeTitle}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <h2 className="mt-24 security-posture">Security Monitor</h2>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4} lg={4} className="flex-align">
                <CircularProgressDividedIcon
                  percent={attackScoreInfo[0].Dns}
                  title={`DNS`}
                />
              </Grid>

              <Grid item xs={12} md={4} lg={4} className="flex-align">
                <CircularProgressDividedIcon
                  percent={attackScoreInfo[0].Application}
                  title={`Misconfiguration`}
                />
              </Grid>

              <Grid item xs={12} md={4} lg={4} className="flex-align">
                <CircularProgressDividedIcon
                  percent={attackScoreInfo[0].Encryption}
                  title={`Encryption`}
                />
              </Grid>

              <Grid item xs={12} md={4} lg={4} className="flex-align">
                <CircularProgressDividedIcon
                  percent={attackScoreInfo[0].Breaches}
                  title={`Breach`}
                />
              </Grid>

              <Grid item xs={12} md={4} lg={4} className="flex-align">
                <CircularProgressDividedIcon
                  percent={attackScoreInfo[0].Network}
                  title={`Network`}
                />
              </Grid>

              <Grid item xs={12} md={4} lg={4} className="flex-align">
                <CircularProgressDividedIcon
                  percent={attackScoreInfo[0].Leak}
                  title={`Leak`}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <h2 className="mt-24 security-posture" style={{ padding: "5px" }}>
                Details
              </h2>
            </Grid>
            <Grid item xs={12} md={6} lg={6} className="flx-property">
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={6}>
                  <div className="issues-box padding-issues">
                    <div>
                      <h3>{attackCountInfo.total_issue}</h3>
                      <p>Issues</p>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <div className="issues-box">
                    <div>
                      <h3>{attackCountInfo.total_cve}</h3>
                      <p>CVE</p>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <div className="issues-box padding-issues">
                    <div>
                      <h3>{attackCountInfo.total_ip}</h3>
                      <p>Open Ports</p>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <div className="issues-box padding-issues">
                    <div>
                      <h3>{attackCountInfo.total_breaches}</h3>
                      <p>Breaches</p>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <div className="issues-box padding-issues">
                    <div>
                      <h3>{attackCountInfo.total_subdomains}</h3>
                      <p> Total SubDomains</p>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            
            <Grid>
              {/* evidence start */}
              {Object.entries(domainIssues[0].Issue).map((key, val) => (
                <div  style={{border:'2px solid black', marginTop:'15px', padding: '15px', borderRadius: '15px'}}>
                <Grid container spacing={2}>
                  {/* <div className="line-hr"></div> */}
                  <Grid item xs={3} md={3} lg={3} className="affecteds-part">
                    <h3>Affected Assets</h3>
                    <span className="target-color">{domainIssues[0].Target}</span>
                  </Grid>
                  <Grid item xs={3} md={3} lg={3} className="affecteds-part">
                    <h3>Company</h3>
                    <span className="target-color">{data.url}</span>
                  </Grid>
                  <Grid item xs={3} md={3} lg={3} className="affecteds-part">
                    <h3></h3>
                    {key[1].Severity === "CRITICAL" ? (
                      <span className="alert-Critical">
                        <WarningAmberIcon fontSize="small"/>
                        {key[1].Severity}
                      </span>
                    ) :
                    key[1].Severity === "HIGH" ? (
                      <span className="alert-Critical high-color">
                         <ShowChartIcon fontSize="small"/>
                        {key[1].Severity}
                      </span>
                    ) :
                    key[1].Severity === "MEDIUM" ? (
                      <span className="alert-Critical medium-color">
                        <TrackChangesIcon fontSize="small"/>
                        {key[1].Severity}
                      </span>
                    )               
                      : (
                      <span className="alert-Critical success-critical">
                        <ThumbDownOffAltIcon IconfontSize="small" />
                        {key[1].Severity}
                      </span>
                    )}
                  </Grid>

                  <Grid item xs={12} className="issueskey">
                    <Grid item xs={12}>
                      <h4>{`Issue ${val + 1} : ` + key[1].Name}</h4>
                    </Grid>
                    <Grid item xs={12}>
                      <h4>Description</h4>
                      <p class="issues-peraa">{key[1].Info}</p>
                    </Grid>
                    <Grid item xs={12}>
                      <h4>Reference</h4>
                      {key[1].Reference.map((item, index) => (
                        <p className="dark-change-bg">
                          <a
                            href={`${key[1].Reference[index]}`}
                            style={{
                              textDecoration: "none",
                              color:"#242526",
                              backgroundColor: "#fff",
                              padding: "5px 10px",
                              borderRadius: "5px",
                              border:"1px solid",
                              borderColor:"rgb(225 224 224)",
                              display:"block",
                              marginBottom:"3px",
                              width:"fit-content",
                            }}
                            target="_blank"
                          >
                            {key[1].Reference[index]}
                          </a>
                        </p>
                      ))}
                    </Grid>
                    <Grid item xs={12}>
                      <h4>Evidence</h4>
                      <pre data-lang="scss" className="prettyprint">
                        <code>{key[1].Evidence.join("\n")} </code>
                      </pre>
                    </Grid>
                  </Grid>
                </Grid>
                </div>

              ))}
            </Grid>
          </div>
        ) : null}
      </Container>
    </>
  );
}
