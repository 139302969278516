import React from 'react'
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';



function CircularProgressWithLabel(props) {
    return (
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress className='circularprogress' size="9rem" style={{ zIndex:"9", color: ((props.value>=0) && (props.value<33)) ? '#d32f2f' : ((props.value>=33) && (props.value<66)) ? '#1976d2' : ((props.value>=66)  && (props.value<101)) ? 'rgb(46, 125, 50)' : 'white'}} variant="determinate" {...props} />
        <CircularProgress className="bottom-circle" size="9rem" variant="determinate" value={100} style={{color: '#f1f1f1'}} />
        <Box
        sx={{
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        }}>
          <Typography variant="caption" component="div" color="text.secondary" className='security-text'>
            {`${Math.round(props.value)}/100`}
          </Typography>
        </Box>
      </Box>
    );
  }


  CircularProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate variant.
     * Value between 0 and 100.
     * @default 0
     */
    value: PropTypes.number.isRequired,
  };


export default function CircularProgressBarDividedIcon({percent}) {
    const [progress, setProgress] = React.useState(percent);

  // React.useEffect(() => {
  //   const timer = setInterval(() => {
  //     setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
  //   }, 800);
  //   return () => {
  //     clearInterval(timer);
  //   };
  // }, []);

  return <CircularProgressWithLabel value={progress} />;
}